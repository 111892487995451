import { css } from '@emotion/css'

// Breakpoints
//
// 600 / 900 / 1280 / 1800
//
// inspired by this article:
// https://www.freecodecamp.org/news/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862/

const smallMobile = `@media (max-width: 399px)`;
const mobileOnly = `@media (max-width: 599px)`;
const tabletPortraitUp = `@media (min-width: 600px)`;
const tabletLandscapeDown = `@media (max-width: 900px)`;
const tabletLandscapeUp = `@media (min-width: 900px)`;
const desktopUp = `@media (min-width: 1280px)`;
const desktopDown = `@media (max-width: 1279px)`;
const bigDesktopUp = `@media (min-width: 1800px)`;

// TODO: [v2][breakpoints] - those are temporary due to a mistake during design;
// we should remove them as soon as possible - do not use those!
const max_900 = `@media screen and (max-width: 900px)`;
const specific_320 = `@media screen and (max-width: 320px)`;
const specific_350 = `@media screen and (max-width: 350px)`;
const specific_1100 = `@media screen and (max-width: 1100px)`;
const specific_1110 = `@media screen and (max-width: 1110px)`;
const specific_1400 = `@media screen and (min-width: 1400px)`;

export {
  smallMobile,
  mobileOnly,
  tabletPortraitUp,
  tabletLandscapeUp,
  tabletLandscapeDown,
  desktopUp,
  desktopDown,
  bigDesktopUp,

  max_900,
  specific_320,
  specific_350,
  specific_1100,
  specific_1110,
  specific_1400,
}

// Usage:
css`
     color: green;
     ${mobileOnly} {
       color: gray;
     }
     ${tabletPortraitUp} {
       color: hotpink;
     }
   `