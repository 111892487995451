import { useIsLogged } from '@v2/store/rtk.user.selectors';
import { useEffect, useState } from 'react';
import { getMyIp } from '../helpers/ip-address';
import { getByKey, removeByKey } from '@v2/utils/localstorage';

const StackAdaptPixes = () => {
    const location = (window as any).location;
    const query = new URLSearchParams(location.search);
    const path = location?.pathname;
    const isLogged = useIsLogged();
    const [isNewOrder, setIsNewOrder] = useState(true);
    let doc = document;

    const setCookie = (name: any, value: any) => {
        var expiry = new Date(new Date().getTime() + 3600000 * 24 * 14);
        doc.cookie = name + "=" + (value || "") + "; expires=" + expiry.toUTCString() + "; path=/";
    }

    const getCookie = (name: any) => {
        var res = doc.cookie.split("; ").find((row: any) => row.startsWith(name + "="));
        return res && res.length > 1 ? res.split("=").slice(1).join("=") : "";
    }

    const isNewVisit = (!getCookie("tn-pixel-userId") && !isLogged) ? true : false;

    const getEventName = (path: string) => {
        if (path === '/' || path === '/' || path.startsWith('/?')) {
            return `homepage_view_${isNewVisit ? 'new' : 'returning'}`;
        } else if (path.startsWith('/checkout')) {
            return `checkout_page_view`;
        } else if (path.startsWith('/shop')) {
            return `shop_page_view`
        } else if (path.startsWith('/order-confirmation') && query.get('order_id')) {
            return `order_${isNewOrder ? 'new' : 'returning'}`;
        } else {
            return '';
        }
    }

    /* eslint-disable */
    const uuidv4 = () => {
        /* @ts-ignore */
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    const fireEvent = async (name: string) => {
            if(name) {
                if (path.startsWith('/cart') && isLogged) {
                    setIsNewOrder(false);
                }
    
                const ip = await getMyIp();
                let saleData = null as {event_item_id: string, event_sale_amount: string};
    
                if (name.startsWith('order_')) {
                    saleData = getByKey('pixel_data');
                    removeByKey('pixel_data');
                }
    
                let ref = getCookie("tn-pixel-ref");
                let uid = getCookie("tn-pixel-userId");
    
                if (!ref) {
                    ref = doc.referrer ? doc.referrer : "Direct";
                    setCookie("tn-pixel-ref", ref);
                }
    
                if (!uid) {
                    uid = uuidv4();
                    setCookie("tn-pixel-userId", uid);
                }
    
                const page_querystring = location.href.split('?');
                const now = new Date();
    
                const event = {
                    page: name,
                    customer_name: 'cerebelly',
                    event_datetime_utc: `${now.getUTCFullYear()}-${now.getUTCMonth() + 1}-${now.getUTCDate()} ${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}`,
                    event_name: name,
                    event_item_id: String(saleData?.event_item_id || ''),
                    event_sale_amount: Number(saleData?.event_sale_amount || '0'),
                    event_device_id: '',
                    user_id: uid,
                    session_id: '',
                    ip_address: ip,
                    x_forwarded_for: '',
                    page_url: location?.href || '',
                    page_host: location?.hostname || '',
                    page_querystring: (page_querystring && page_querystring[1]) ? page_querystring[1] : '',
                    referrer: ref || '',
                    user_agent: window?.navigator?.userAgent || '',
                }
                // window.saq('conv', process.env.STACK_ADAPT_PIXEL_KEY_CONV, event);
                window.saq('ts', process.env.STACK_ADAPT_PIXEL_KEY_TS, event);
            }
    }

    useEffect(() => {
        if (process.env.ENV !== 'production') {
            return;
        }
        fireEvent(getEventName(path));
    }, [path]);

    return (<></>);
}

export default StackAdaptPixes;

