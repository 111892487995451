import { useSelector } from 'react-redux';

import { ISettings, IUser } from '@v2/interfaces';
import { RootState } from '@v2/store';

export function useUser(): IUser {
    return useSelector((state: RootState) => state.user.user);
}

export function useIsLogged(): boolean {
    return useSelector((state: RootState) => {
        return !!state.user.jwt?.token && !!state.user.user
    });
}

export function useJWT(): string {
    return useSelector((state: RootState) => state.user.jwt?.token);
}

export function useUserSettings(): ISettings {
    return useSelector((state: RootState) => state.user.settings);
}