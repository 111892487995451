import { useSelector } from 'react-redux';
import { IModalSettings } from '@v2/components/common/presentational/modal/interfaces';
import { createSelector } from '@reduxjs/toolkit';

const shopModalsSelector = createSelector([(state: any) => state?.modals?.shop || [] as IModalSettings[]], (item) => item)

export function useShopModals(): IModalSettings[] {
    return useSelector(shopModalsSelector);
}

const modalsSelector = createSelector([(state: any) => state?.modals?.general || [] as IModalSettings[]], (item) => item)

export function useModalsSelector(): IModalSettings[] {
    return useSelector(modalsSelector);
}
