import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILoginPayload } from '@v2/components/common/presentational/header/modals/login';
import { ISettings, IUser } from '@v2/interfaces';

interface UserState {
    user: IUser | null;
    jwt: {
        token: string | null;
    },
    settings: ISettings | null;
}

const initialState: UserState = {
    user: null,
    jwt: null,
    settings: null
}

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state: UserState, action: PayloadAction<IUser>): void => {
            state.user = action.payload;
        },

        login: (state: UserState, action: PayloadAction<ILoginPayload>): void => {
            const payload = action.payload;

            state.user = payload.user;
            state.jwt = { token: payload.token };
            state.settings = payload.settings;
        },

        logout: (state: UserState, action: PayloadAction<void>): void => {
            state.user = null;
            state.jwt = null;
            state.settings = null;
        }
    },
});

const userReducer = slice.reducer;

export const { logout, login } = slice.actions;

export default userReducer;