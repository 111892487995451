import { INutrient } from '@interfaces';
import { apiGet } from '@utils/http';

export class NutrientPost {
    id: number;
    bigChemicalSymbolImage: string;
    title: string;
    slug: string;
    category: string;
    benefits: [];
    heroBackgroundColor: string;
    shortDescription: string;
    sort: number;

    static fromNutrientObject(json: { [key: string]: any }) {
        const post = new NutrientPost();

        post.id = json.id;
        post.title = json.title;
        post.bigChemicalSymbolImage = json.bigChemicalSymbolImage;
        post.slug = json.slug;
        post.category = json.category;
        post.benefits = json.benefits;
        post.heroBackgroundColor = json.heroBackgroundColor;
        post.shortDescription = json.shortDescription;
        post.sort = json.sort;

        return post;
    }
}

export default class NutrientService {
    static get(id: number): Promise<INutrient> {
        return apiGet(`nutrient/get?id=${id}`)
            .then((response: any) => response as INutrient);
    }

    static list(): Promise<INutrient[]> {
        return apiGet(`nutrient/list`)
            .then((response: any) => response as INutrient[]);
    }
}