import { LinkWithReferrer } from '@components/common/helpers/link';
import { useHistoryWithReferrer } from '@utils/history';
import { useBuildBundleModal } from '@components/common/presentational/modal/common';

export default function SurveyLink(
    { className, children, onClick }
    : { className?: string, children?: any, onClick?: Function }
) {
    const history = useHistoryWithReferrer();
    const [showBuilder] = useBuildBundleModal();

    const action = () => {
        if (onClick) {
            onClick();
        }

        if (history.location.pathname.includes('/shop/#builder')) {
            showBuilder();
        }
    }

    return (
        <LinkWithReferrer onClick={action} className={className} to={'/shop/#builder'}>
            {children}
        </LinkWithReferrer>
    );
}