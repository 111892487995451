import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable'

import styles from '@v2/components/pages/home/home.styles';
import ThemeImage from '@v2/utils/image';

export default function InTheNews() {
    const [quote, setQuote] = useState(0);
    const config = {
        delta: 10,
        // preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0,
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => quote < 2 ? setQuote(quote + 1) : null,
        onSwipedRight: () => quote > 0 ? setQuote(quote - 1) : null,
        ...config,
    });

    const content = [
        {
            text: `“We’ve become obsessed with Cerebelly’s chewy Smart Bars since they don’t crumble or get crushed, spill, or have to stay cold”`,
            url: `https://theeverymom.com/tips-for-flying-wtih-babies-and-toddlers/`,
            logo: `homepage3/in-the-news-logos/theeverymom.png`,
        },
        {
            text: `"Brands like Cerebelly are keeping nutrition a top priority by making vegetables the first ingredient"`,
            url: `https://www.inc.com/shama-hyder/what-do-beauty-baby-food-have-in-common-they-produce-a-ton-of-waste.html`,
            logo: `homepage3/in-the-news-logos/inc.jpg`,
        },
        {
            text: `“Setting a standard above the industry benchmark”`,
            url: `https://www.mothermag.com/brain-boosting-nutrients-for-kids/`,
            logo: `homepage3/in-the-news-logos/mother.png`,
        },
        {
            text: `“Impressed by both the nutrition stats and the ingredient combinations offered by the pouches” `,
            url: `https://www.healthline.com/nutrition/meal-delivery-for-kids#A-quick-look-at-the-best-meal-delivery-services-for-kids`,
            logo: `homepage3/in-the-news-logos/healthline.png`,
        },
        {
            text: `“The thinking behind Cerebelly is to enhance the food naturally and organically with 16 essential brainpower nutrients.”`,
            url: `https://www.entrepreneur.com/business-news/6-dynamic-changes-in-education-and-science/426656`,
            logo: `homepage3/in-the-news-logos/entrepreneur.png`,
        },
        {
            // eslint-disable-next-line
            text: `“By merging neuroscience with nutrition, working alongside science and culinary experts, and focusing on nutrient-packed foods, it’s no surprise that the young company is standing out against competitors”`,
            url: `https://www.baby-chick.com/cerebelly-baby-food-brand-was-scientifically-developed-to-foster-early-brainpower/`,
            logo: `homepage3/in-the-news-logos/babychick_200.jpeg`,
        },
        {
            text: `“Cerebelly makes healthier baby food”`,
            url: `https://www.forbes.com/sites/meimeifox/2021/03/09/cerebelly-makes-healthier-baby-food-that-is-free-from-heavy-metals/?sh=24fbf0f7187c`,
            logo: `homepage3/in-the-news-logos/Forbes_640x200.png`,
        },
        {
            text: `“No other baby food comes close”`,
            url: `https://babe.hatchcollection.com/infant-brain-development-cerebelly`,
            logo: `homepage3/in-the-news-logos/babe_hatch_200.png`,
        },
        /*
        1: {
            text: `“Cerebelly’s Peppa Pig pouches are perfect for baby who is starting to eat pureed foods”`,
            url: ``,
            logo: `homepage3/in-the-news-logos/Vogue_640x200.png`,
        },
        2: {
            text: `“The future of baby food is sleek, organic & rooted in science.”`,
            url: `https://www.refinery29.com/en-us/2019/09/8427387/cerebelly-organic-baby-food-trend`,
            logo: `homepage3/in-the-news-logos/Refinery29_640x200.png`,
        },
        5: {
            text: `“Cerebelly's savory flavors stand out compared with other baby food brands”`,
            url: ``,
            logo: `homepage3/in-the-news-logos/NewYork_640x200.png`,
        },
        */
    ];

    useEffect(() => {
        const slider = setTimeout(() => {
            setQuote(quote < 5 ? quote + 1 : 0);
        }, 8000);
        return () => {
          clearTimeout(slider);
        };
    }, [quote]);

    return (
        <div className="page-container">
            <div className="inner-container">
                <div {...swipeHandlers}>
                    <div className={styles.inTheNews}>
                        <p className="sub-heading">
                            {Object.values(content).map((row: any, index: number) =>
                                <a key={index}
                                    href={row.url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className={`e-logo-link forbes ${quote === index ? 'active animated animate__animated animate__fadeInUp-home' : ''}`}>
                                    <span className="e-quote">
                                        {row.text}
                                    </span>
                                </a>
                            )}
                        </p>
                        <div className="logo-wrapper">
                            {Object.values(content).map((row: any, index: number) =>
                                <span key={index} className={quote === index ? 'active' : ''}
                                    onClick={() => setQuote(index)}>
                                    <ThemeImage src={row.logo} alt={''} width={640} height={200} />
                                </span>
                            )}
                            <div className="dots">
                                {Object.values(content).map((row: any, index: number) =>
                                    <span key={index} className={`dot ${quote === index ? 'active' : ''}`} onClick={() => setQuote(index)}></span>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}