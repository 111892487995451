import { imgV2 as img } from '@styles/utils';
import { apiGet } from '@v2/utils/http';
import Parser from 'html-react-parser';

const icons = {
    "fork_and_spoon": `${img('homepage3/piaa/fork_and_spoon.png')}`,
    "flag": `${img('homepage3/piaa/flag.png')}`,
    "leaf": `${img('homepage3/piaa/leaf.png')}`
}

const mapCategoryIdToIcons: {[key: number]: string} = {
    102: icons['flag'],
    135: icons['leaf'],
    106: icons['fork_and_spoon'],
    104: icons['flag'],
    105: icons['leaf'],
    136: icons['fork_and_spoon'],
    131: icons['leaf'],
    107: icons['fork_and_spoon'],
    1: icons['flag'],
}

export class BlogPost {
    id: number;
    image: string;
    title: string;
    link: string;
    path: string;
    category: string;

    static fromPIAA(json: { [key: string]: any }) {
        const post = new BlogPost();

        post.id = json.id;
        post.title = json.title && json.title.rendered ? Parser(json.title.rendered).toString() : null;
        post.image = json.featured_image_src;
        post.link = json.link;
        post.path = json.link.replace('https://parentingisanart.com/', '');
        post.category = json.category;

        return post;
    }
}

export class BlogCategory {
    id: number;
    name: string;
    link: string;
    path: string;
    icon: string;
    count: number;

    static fromPIAA(json: { [key: string]: any }) {
        const category = new BlogCategory();

        category.id = json.id;
        category.name = Parser(json.name).toString();
        category.link = json.link;
        category.path = json.link.replace('https://parentingisanart.com/', '');
        category.icon = mapCategoryIdToIcons[json.id];
        category.count = json.count;

        return category;
    }
}

export default class BlogService {
    static categories(): Promise<BlogCategory[]> {
        return fetch(`https://parentingisanart.com/index.php?rest_route=/wp/v2/categories`)
                .then(response => response.json())
                .then(categories => categories.map((json: any) => BlogCategory.fromPIAA(json)));
    }

    static articlesForCategory(category: number, count: number = 2): Promise<BlogPost[]> {
        return fetch(`https://parentingisanart.com/index.php?rest_route=/wp/v2/posts&categories[]=${category}&per_page=${count}`)
                .then(response => response.json())
                .then(posts => posts.map((json: any) => BlogPost.fromPIAA(json)));
    }

    static articles(count: number = 2): Promise<BlogPost[]> {
        return fetch(`https://parentingisanart.com/index.php?rest_route=/wp/v2/posts&per_page=${count}`)
                .then(response => response.json())
                .then(posts => posts.map((json: any) => BlogPost.fromPIAA(json)));
    }

    static randomArticles(count: number = 2): Promise<BlogPost[]> {
        return fetch(`https://parentingisanart.com/index.php?rest_route=/wp/v2/posts`)
                .then(response => response.json())
                .then(posts => posts
                    .map((json: any) => {
                        json.sort = Math.random();
                        return json;
                    })
                    .sort((a: any, b: any) => a.sort - b.sort)
                    .slice(0, count)
                    .map((json: any) => BlogPost.fromPIAA(json))
                );
    }

    static async randomArticlesWithCategoryNutritionAndRecipes(count: number = 2): Promise<BlogPost[]> {
        // 105 - Nutrition
        // 107 - Recipes

        let nutritionArticles: BlogPost[] = (await BlogService.articlesForCategory(105, count)
            .then(posts => posts
                .map((json: any) => {
                    json.sort = Math.random();
                    json.category = 'Nutrition';
                    return json;
                })
                .sort((a: any, b: any) => a.sort - b.sort)
            )
        );

        let recipeArticles: BlogPost[] = (await BlogService.articlesForCategory(107, count)
            .then(posts => posts
                .map((json: any) => {
                    json.sort = Math.random();
                    json.category = 'Recipe';
                    return json;
                })
                .sort((a: any, b: any) => a.sort - b.sort)
            )
        );

        let orderedArray: BlogPost[] = [];

        nutritionArticles.forEach((v, i) => {
            orderedArray.push(nutritionArticles[i]);
            orderedArray.push(recipeArticles[i]);
        })

        return orderedArray;
    }

    static getHTML(url: string): any {
        return apiGet(`frontend/piaa?url=${url}`).then((response: any) => (response as any) as any)
    }
}