import { css } from '@emotion/css'
import colors from './colors';
import buttonsStyles from './buttons';
import { desktopUp, tabletLandscapeUp, mobileOnly, tabletPortraitUp, desktopDown } from '@styles/breakpoints';

import iconError from '@img/form/error.svg';
import iconSuccess from '@img/form/success.svg';
import iconCalendar from '@img/form/calendar.svg';
import iconSubscription from '@img/dashboard/subscription.svg';

export default {
    // this one defines styles for the wrapper that includes the menu & the whole
    // right content side. Throw in some defaults here
    wrapper: css({
        padding: '0px !important',
        display: 'flex',
        position: 'relative',
        flexDirection: 'row',
        flex: '0 0 250px',
        minHeight: '100%',
        fontSize: 16,
        lineHeight: '145%',
        letterSpacing: '0.01em',
        color: '#15206B',

        'a.disabled': {
            color: '#fafafa',
            textDecoration: 'none',
            cursor: 'not-allowed',
        },

        '&.dashboardLayout': {
            margin: '20px 40px 0 !important',

            '.pageContainer': {
                backgroundColor: colors.HOMEPAGE_REVIEWS_LIGHT_BLUE,
            }
        },
        '&.dashboardMobileLayout': {
            flexDirection: 'column',


            '.mainContainer:not(.my-board)': {
                backgroundColor: colors.HOMEPAGE_REVIEWS_LIGHT_BLUE,
            }
        },

        '.dashbaordMobile': {
            paddingTop: 50,
        },

        '.sidemenuContainer': {
            height: '100vh',
            borderRadius: '10px 10px 0 0',
            backgroundColor: colors.HOMEPAGE_REVIEWS_LIGHT_BLUE,
            transition: 'width 1s',
            zIndex: 2,
            width: '30%',
            overflow: 'auto',
            position: 'sticky',
            top: 80,
        },

        '@media screen and (min-width: 1440px)': {
            '&:before': {
                flex: '0 0 0',
            },
        },

        [mobileOnly]: {
            '&:before': {
                display: 'none',
            },

            '.sidemenuContainer': {
                width: '100vw',
                height: 'auto',
                backgroundColor: colors.HOMEPAGE_REVIEWS_LIGHT_BLUE,
                position: 'fixed',
                minHeight: 45,
                top: 50,
                zIndex: 2,
                overflow: 'hidden',
                '&:hover': {
                    width: '100vw',

                    '.menuItem': {
                        '.title': {
                            display: 'inline-block',
                        },

                        '.spacer': {
                            display: 'none',
                        },
                    },
                },
            },

            '&.dashboardLayout': {
                margin: '0 !important',
            },
        },
        '.mainContainer': {
            flex: '1 1 100%',
            display: 'flex',
            flexDirection: 'column',

            '.pageContainer': {
                flex: '1 1 100%',
                marginLeft: 24,
                borderRadius: '15px 15px 0 0',

                [mobileOnly]: {
                    margin: '0 auto',
                    borderRadius: 0,
                },

                '.pageContent': {
                    maxWidth: '948px',
                    margin: '50px auto',
                    minHeight: '50vh',

                    '.action-button-wrap': {
                        [mobileOnly]: {
                            display: 'none',
                        },
                        '&.mobile': {
                            display: 'block',
                            [tabletPortraitUp]: {
                                display: 'none',
                            }
                        }
                    }

                },
                [desktopUp]: {
                    '.pageDashboard': {
                        margin: '50px auto',
                    },
                },
            },

            '.footerContainer': {
                flex: '0 0 350px',
                paddingLeft: 24,

                [desktopDown]: {
                    padding: 0
                }
            },

            [desktopDown]: {
                margin: '0px 0px 0px 0px',
                '.pageContent': {
                    margin: '30px auto !important',
                },
            },

            [mobileOnly]: {
                margin: '0px 0px 0px 0px',
                '.pageContent': {
                    margin: '30px auto !important',
                },
            },

            '&.referral': {
                '.pageContainer': {
                    '.pageDashboard': {
                        margin: '0 auto !important',
                    }
                },

                [mobileOnly]: {
                    margin: '42px 0 0 0 !important',
                    '.pageContainer': {
                        '.pageDashboard': {
                            margin: '0 !important',
                            padding: '0 !important',
                        }
                    }
                }
            },

            '&.child-dashboard': {
                '.pageContainer': {
                    backgroundColor: colors.WHITE
                }
            }
        },
    }),

    customBuilder: css({
        padding: '0 !important',
        // display: 'block',
        // margin: 'auto',
        // maxWidth: '1200px !important',
        // color: 'inherit',
        // fontSize: 'initial',
        // fontWeight: 'initial',
        // letterSpacing: 'initial',
        // lineHeight: 'initial',
        // a: {
        //     textDecoration: 'none !important'
        // },
        // p: {
        //     marginTop: '1em',
        //     marginBottom: '1em',
        // }
    }),

    v2Global: css({
        fontWeight: 300,

        h1: {
            marginTop: 0,
            marginLeft: 0,
            fontWeight: 600,
            fontSize: 32,
            letterSpacing: '0.02em',
            lineHeight: '135%',
            color: '#15206B',
        },

        h2: {
            marginTop: 0,
            marginLeft: 0,
            fontWeight: 600,
            fontSize: 24,
            letterSpacing: '0.02em',
            lineHeight: '135%',
        },

        h3: {
            marginTop: 0,
            marginBottom: 0,
            fontWeight: 600,
            fontSize: 20,
            lineHeight: '135%',
            letterSpacing: '0.02em',
            color: '#15206B',
        },

        h4: {

        },

        h5: {
            marginTop: 0,
            marginBottom: 0,
            color: '#3856A7',
            fontWeight: 'normal',
            fontSize: 16,
            lineHeight: '145%',
            letterSpacing: '0.01em',
            textTransform: 'none',
        },

        hr: {
            border: '1px solid #EBEEF6',

            '&.thin': {
                margin: 0,
            },
        },

        p: {
            fontSize: '16px',
        },

        // there is text-bold from bootstrap, gotta remove
        '.bold': {
            fontWeight: 'bold',
        },
        '.text-bolder': {
            fontWeight: 800,
        },

        // there is alternative from bootstrap, gotta remove
        '.allcaps': {
            textTransform: 'uppercase',
        },

        // there is alternative from bootstrap, gotta remove
        '.capitalized': {
            textTransform: 'capitalize',
        },

        '.clickable': {
            cursor: 'pointer',
        },

        '.text-blue': {
            color: colors.BLUE + ' !important',
        },

        '.text-green': {
            color: colors.GREEN_BRIGHT + ' !important',
        },

        '.text-navy': {
            color: colors.NAVY + ' !important',
        },

        '.text-red': {
            color: colors.RED + ' !important',
        },

        '.text-bold': {
            fontWeight: 600,
        },

        '.text-strikethrough': {
            textDecoration: 'line-through !important',
        },

        '.font-14': {
            fontSize: 14,
        },

        '.msg-error': {
            width: '100%',
            color: colors.RED,
            fontSize: '15px',
            marginTop: '10px'
        },

        '.msg-success': {
            width: '100%',
            color: colors.GREEN_BRIGHT,
            fontSize: '15px',
        },

        '.noselect': {
            webkitTouchCallout: 'none',
            webkitUserSelect: 'none',
            khtmlUserSelect: 'none',
            mozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none',
        },

        'a.primary': {
            color: colors.NAVY,
            fontSize: 13,
            lineHeight: '20px',
            letterSpacing: '0.2em',
            textDecoration: 'none',
            textTransform: 'uppercase',
            position: 'relative',
            paddingBottom: '3px',
            display: 'inline-block',

            '&:before': {
                content: '""',
                position: 'absolute',
                width: 0,
                height: '2px',
                bottom: 0,
                left: '-0.1em',
                backgroundColor: colors.RED,
                transition: 'width 0.3s ease-in-out 0.5s',
            },

            '&:hover::before': {
                width: '100%',
            },

            '&:after': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '2px',
                bottom: 0,
                right: '0.1em',
                backgroundColor: colors.RED,
                transition: 'width 0.3s ease-in-out 0s',
            },

            '&:hover::after': {
                width: 0,
            },
        },

        fieldset: {
            border: 0,
            padding: 0,
            margin: 0,

            legend: {
                marginBottom: 22,
                fontWeight: 600,
                fontSize: '20px',
                lineHeight: '135%',
                letterSpacing: '0.02em',
                color: '#15206B',
            },

            '&.condensed': {
                label: {
                    paddingBottom: 0,
                },
            }
        },

        '.alert': {
            backgroundRepeat: 'no-repeat',
            paddingLeft: 32,
            marginTop: 15,
            fontWeight: 600,
            fontSize: 15,
            lineHeight: '135%',
            letterSpacing: '0.02em',

            '&.success': {
                color: colors.GREEN_BRIGHT,
                backgroundPositionY: -2,
                backgroundImage: `url(${iconSuccess})`,
            },

            '&.without-icon': {
                paddingLeft: 0,
                backgroundImage: 'none',
            },

            '&.error': {
                color: colors.RED_DARK,
                backgroundPositionY: -2,
                backgroundImage: `url(${iconError})`,
            },

            '&.nomargin': {
                marginTop: 0,
            },

            [mobileOnly]: {
                marginLeft: 15,
            },
        },

        '.box-icon': {
            width: 25,
            height: 25,
            color: colors.WHITE,
            borderRadius: 2,
            fontSize: 13,
            lineHeight: '115%',
            textAlign: 'center',
            letterSpacing: '0.01em',
            display: 'inline-block',
            paddingTop: 5,
            marginBottom: 5,

            [mobileOnly]: {
                marginBottom: 6,
            }
        },

        'button.button': buttonsStyles as any,
        'a.button': buttonsStyles as any,

        // DO NOT! I REPEAT - DO NOT use these styles for styling external libs inputs.
        // Style them entirely separately, please
        // Custom class "input-number" for inputs with tipe "number"
        'input[type=text], input[type=password], input[type=date], input[type=email], input[type=tel], .select__control, .input-number ': {
            display: 'block',
            marginTop: 4,
            padding: '12px 20px',
            minWidth: '100%',
            maxWidth: '100%',
            border: 'solid 2px rgba(56, 86, 167, 0.2)',
            borderRadius: 5,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '115%',
            letterSpacing: '0.01em',
            color: colors.BLUE,

            '&:hover': {
                borderColor: colors.BLUE_60,
            },

            '&:focus, :active': {
                borderColor: colors.BLUE,
            },

            '::placeholder': {
                color: colors.BLUE_LIGHT,
            },

            '&.error': {
                borderColor: colors.RED,
            },

            '&.width100': {
                minWidth: '100%',
            },

            '&.width75': {
                minWidth: '75%',
            },

            '&.width50': {
                minWidth: '50%',
            },
        },

        'input[type=date]': {
            textAlign: 'left',
        },

        'input[type=checkbox]': {
            display: 'inline-block',
            marginRight: 10,
        },

        'input[type=radio]': {
            position: 'absolute',
            margin: '5px 10px 5px 0px',
            width: '18px',
            height: '18px',
            opacity: 0,
        },

        'label.caption': {
            cursor: 'pointer',
            paddingLeft: 24,
            position: 'relative',

            '&:before': {
                content: `""`,
                position: 'absolute',
                left: 0,
                top: 2,
                width: 18,
                height: 18,
                border: `2px solid ${colors.BLUE}`,
                borderRadius: '100%',
                backgroundColor: colors.WHITE,
            },
        },

        'input[type=radio]:checked+label': {

            '&:after': {
                content: `""`,
                position: 'absolute',
                left: 4,
                top: 6,
                width: 10,
                height: 10,
                borderRadius: '100%',
                backgroundColor: colors.BLUE,
                animation: 'growing 0.3s ease-in-out',

                '@keyframes growing': {

                    'from': {
                        opacity: 0,
                        transform: 'scale3d(0.3, 0.3, 0.3)',
                    },

                    'to': {
                        opacity: 1,
                    },
                }
            }
        },

        '.input-phone': {
            '.fake-input': {
                height: 0,
                border: 'none',
                padding: '0px !important',
            },
        },

        '.react-tel-input': {
            '.flag-dropdown': {
                border: 'solid 2px rgba(56, 86, 167, 0.2)',

                '&:before': {
                    content: '"+1"',
                    position: 'absolute',
                    bottom: 17,
                    left: 10,
                    fontSize: 18,
                    color: '#838385',
                    backgroundColor: '#f5f5f5',
                    zIndex: 1
                },
            },

            '.selected-flag': {
                paddingLeft: 11,
            },

            '.form-control': {
                height: 'auto',
            }
        },

        /***TOGGLE CHECKBOX STYLE START***/
        '.toggle': {
            cursor: 'pointer',
            display: 'inline-block',
        },
        '.toggle-switch': {
            display: 'inline-block',
            background: '#ccc',
            borderRadius: '16px',
            width: '46px',
            height: '26px',
            position: 'relative',
            verticalAlign: 'middle',
            transition: 'background 0.25s',
            '&:after': {
                content: '""',
            },
            '&:before': {
                content: '""',
                display: 'block',
                background: 'linear-gradient(to bottom, #fff 0%,#eee 100%)',
                borderRadius: '50%',
                width: '22px',
                height: '22px',
                position: 'absolute',
                top: '2px',
                left: '2px',
                transition: 'left 0.25s',
            },
            '.toggle:hover &:before': {
                background: 'linear-gradient(to bottom, #fff 0%,#fff 100%)',
            },
            '.toggle-checkbox:checked': {
                background: colors.GREEN_BRIGHT,
                '&:before': {
                    left: '30px',
                },
            }
        },
        '.toggle-checkbox': {
            position: 'absolute',
            visibility: 'hidden',
        },
        /***TOGGLE CHECKBOX STYLE END***/

        'input[type=date]::-webkit-calendar-picker-indicator': {
            color: 'rgba(0, 0, 0, 0)',
            backgroundImage: `url(${iconCalendar})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: '100% center',
            backgroundSize: 20,
            cursor: 'pointer',
        },

        '.stripe-input': {
            display: 'block',
            marginTop: 4,
            padding: '12px 20px',
            minWidth: '100%',
            border: 'solid 2px rgba(56, 86, 167, 0.2)',
            borderRadius: 5,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '115%',
            letterSpacing: '0.01em',
            color: colors.BLUE,
        },

        // react-select
        '.select-dropdown': {
            '&.state-dropdown': {
                '.select__control': {
                    padding: '6px 9px'
                }
            },

            '.select__control': {
                display: 'flex',
                padding: '0 9px',

                '.select__value-container': {
                    paddingTop: 0,
                    paddingBottom: 0,
                    marginTop: -2,
                    marginBottom: -2,

                    'div:last-child': {
                        marginTop: 0,
                        marginBottom: 0,
                        paddingBottom: 0,
                        paddingTop: 0,

                        input: {
                            position: 'relative',
                            marginTop: 1,
                            marginBottom: 1,
                        },
                    },
                },

                '.select__single-value': {
                    color: colors.BLUE,
                },

                '.select__placeholder': {
                    color: colors.BLUE_LIGHT,
                },

                '.select__indicator-separator': {
                    display: 'none',
                },
            },
        },

        form: {
            label: {
                display: 'block',
                fontSize: '15px',
                lineHeight: '115%',
                letterSpacing: '0.02em',
                color: '#3856A7',
                paddingBottom: 20,

                '&.checkbox': {
                    marginBottom: 22,
                    lineHeight: '135%',
                    color: '#15206B',
                },
            },

            '.info': {
                marginBottom: 22,
            },
        },

        'label.error': {
            'input[type=text], input[type=password], input[type=email], input[type=tel]': {
                borderColor: colors.RED,
                // backgroundImage: `url(${iconError})`,
                backgroundPosition: 'right 12px center',
                backgroundRepeat: 'no-repeat',

                '&:hover': {
                    borderColor: colors.RED,
                },
            },

            'select, .select__control': {
                borderColor: colors.RED,

                '&:hover': {
                    borderColor: colors.RED,
                },
            },

            '.flag-dropdown': {
                borderColor: colors.RED,
            },
        },

        'label.condensed': {
            paddingBottom: 0,
        },

        '.error-message': {
            marginTop: 8,
            color: colors.RED,
            fontWeight: 600,
            fontSize: '13px',
            lineHeight: '115%',
            letterSpacing: '0.01em',
        },

        '.break': {
            flexBasis: '100%',
            height: 0,
        },

        '.subscription-notification': {
            textAlign: 'center',

            '.interval': {
                color: colors.BLUE,
                fontWeight: 600,
                fontSize: 15,
                lineHeight: '135%',
                letterSpacing: '0.02em',
                backgroundImage: `url(${iconSubscription})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'left center',
                paddingLeft: 30,
                marginRight: 10,
                display: 'inline-block',
            },

            '.control': {
                color: colors.BLUE,
                fontWeight: 500,
                fontSize: 13,
                lineHeight: '115%',
                textAlign: 'center',
                letterSpacing: '0.01em',
                display: 'inline-block',
            },
        },

        [mobileOnly]: {
            h1: {
                fontWeight: 600,
                fontSize: 26,
                letterSpacing: '2',
            }
        },
    }),

    v2FullScreenPageWrapper: css({
        // marginTop: '50px !important',
        padding: '0px !important',

        [tabletLandscapeUp]: {
            // marginTop: '60px !important',
        },
    }),

    v2BuilderPageWrapper: css({
        marginTop: '50px !important',
        padding: '0px !important',

        [tabletLandscapeUp]: {
            marginTop: '60px !important',
        },

        '.builder-blocks > .builder-block': {
            maxWidth: '1440px !important',
            width: '100% !important',
            margin: '0 auto !important',
            paddingLeft: 24,
            paddingRight: 24,

            [tabletLandscapeUp]: {
                paddingLeft: 80,
                paddingRight: 80,
            },
            [mobileOnly]: {
                paddingLeft: 24,
                paddingRight: 24,
            },
        },
    }),

    boardMobileNav: css({
        position: 'fixed',
        top: 50,
        zIndex: 1,
        display: 'block',
        width: '100%',
        transition: '0.2s ease all',

        h1: {
            height: 50,
            width: '100%',
            fontStyle: 'normal',
            fontWeight: 600,
            fontSize: 13,
            alignContent: 'center',
            textAlign: 'center',
            backgroundColor: colors.BLUE,
            color: colors.WHITE,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'uppercase',
            letterSpacing: '0.2em',

            span: {
                position: 'absolute',
                top: 18,
                left: 25,
            },
        }
    }),

    userIntro: css({
        paddingTop: '30px',
        paddingLeft: '25px',
        paddingRight: '25px',
        paddingBottom: '30px',

        '.welcome': {
            fontWeight: 800,
            fontSize: 16,
            color: colors.NAVY,
        },
        '.points': {
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: 12,
            color: colors.NAVY,
        },

        [tabletLandscapeUp]: {
            '.welcome': {
                fontWeight: 800,
                fontSize: 22,
                color: colors.NAVY,
            },
            '.points': {
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: 12,
                color: colors.NAVY,
            },
        }
    })
};
