import { css } from '@emotion/css';
import { tabletLandscapeUp } from '@styles/breakpoints';
import colors from '@styles/colors';

export default {
  oosBar: css({
    backgroundColor: colors.PINK_LIGHT,
    color: colors.RED_DARK,
    padding: '0 10px',
    height: '50px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',

    '.wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      alignContent: 'center',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      maxWidth: 400,
      width: '100%',
      height: '100%',
      textDecoration: 'none !important',

      '.wrapper:hover, .wrapper:active, .wrapper:focus': {
        textDecoration: 'none !important',
        cursor: 'pointer'
      },

      p: {
        margin: 0,
        fontSize: '10px !important',

        [tabletLandscapeUp]: {
          fontSize: '12px !important',
        },
      },
    },
  }),
};