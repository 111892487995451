import { ITrackingNumber } from "@v2/interfaces"

const trackingLink = ({ customTrackingLink, trackingNumber, trackingProvider }: ITrackingNumber) => {
    if(customTrackingLink) {
        return customTrackingLink;
    }

    switch (trackingProvider.toUpperCase()) {
        case 'UPS': {
            return `https://www.ups.com/mobile/track?trackingNumber=${trackingNumber}`
        }
        case 'FEDEX': {
            return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`
        }
        case 'FEDEX2': {
            return `https://www.fedex.com/fedextrack/?trknbr=${trackingNumber}`
        }
        case 'USPS': {
            return `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`
        }
        default: {
            return '#'
        }
    }
}

const unzoom = () => {
    const viewport = document.querySelector("meta[name='viewport']") as any;
    const width = window.screen && window.screen.width ? window.screen.width : window.innerWidth;
    // const scale1 = window.visualViewport.scale;

    if (viewport && width < 600) {
        viewport.content = `width=${width*0.99}, minimum-scale=0.99, maximum-scale=1.0, initial-scale=0.99`;

        setTimeout(function() {
            viewport.content = `width=device-width, initial-scale=1.0, minimum-scale=1.0`;
            // alert(viewport.content + '; scale-from: ' + scale1 + ', scale-to: ' + window.visualViewport.scale);
        }, 200);
    }
}

const clickAndDrag = (selector: string, scroll_speed = 1, classOnEvent = 'grabbed_elem') => {
    const slider: any = document.querySelector(selector);
    const buttons: any = document.querySelectorAll(selector);
    let isDown = false;
    let startX: any;
    let scrollLeft: any;
    let x: number;

    buttons.forEach((button: any) => {
        button.addEventListener('click', (e: any) => {
            if (e.pageX > x + 5 || e.pageX < x - 5) {
                e.stopPropagation();
            }
        });
    });

    slider.addEventListener('click', (e: any) => {
        e.preventDefault();
    });

    slider.addEventListener('mousedown', (e: any) => {
        e.preventDefault();
        isDown = true;
        slider.classList.add(classOnEvent);
        startX = e.pageX - slider.offsetLeft;
        x = e.pageX;
        scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove(classOnEvent);
    });
    slider.addEventListener('mouseup', () => {
        isDown = false;
        slider.classList.remove(classOnEvent);
    });
    slider.addEventListener('mousemove', (e: any) => {
        if (!isDown) {
            return;
        }
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * scroll_speed; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
    });
}

const decodeHTMLEntities = (text: string) => {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = text;
    const result = textArea.value;
    textArea.remove();
    return result;
}

export {
    trackingLink,
    unzoom,
    clickAndDrag,
    decodeHTMLEntities
};
