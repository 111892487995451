import { IBox, IBoxProduct, IChild } from "@v2/interfaces";
import BaseEntity from "./base.entity";
import ChildEntity, { IChildRecord } from "./child.entity";

export interface IBoxRecord {
    id: number;
    oldId: number;
    userId: number;
    subscriptionId: number;
    childId: number;
    planId: number;
    itemId: number;
    type: string;
    title: string;
    price: number;
    items: { [key: number]: number };
    cadence: string;
    nextPayment: string;
    nextPaymentTime: string;
    status: string;
    properties: any;

    // TODO: to be deprecated
    products: IBoxProduct[];
    planPrice: number;
    planPriceOriginal: number;
    planPriceSavings: number;

    availability: string;

    child?: IChildRecord;
}

export default class BoxEntity extends BaseEntity implements IBoxRecord {
    id: number;
    oldId: number;
    userId: number;
    subscriptionId: number;
    childId: number;
    planId: number;
    itemId: number;
    type: string;
    title: string;
    image: string;
    price: number;
    items: { [key: number]: number };
    cadence: string;
    nextPayment: string;
    nextPaymentTime: string;
    status: string;
    properties: any;

    // TODO: to be deprecated
    products: IBoxProduct[];
    planPrice: number;
    planPriceOriginal: number;
    planPriceSavings: number;

    availability: string;

    child?: ChildEntity;
    createdAt?: string;

    static map = { child: ChildEntity };

    static fromJson(json: { [key: string]: any }): IBoxRecord {
        return BaseEntity.fromJson(new this(), json, BoxEntity.map) as BoxEntity;
    }

    getItemsCount(): number {
        return Object.values(this.items).reduce((a, b) => a + b, 0);
    }

    getIBox(): IBox {
        return { ...this, ...{
            name: this.title,
            nochild: !this.childId,
            isChild: !!this.childId,
        }};
    }

    getIChild(): IChild {
        return { ...this.getIBox(), ...{
            gender: this.child?.gender,
            stageId: this.child?.stageId,
            childName: this.child?.name,
            milestoneIds: this.child?.milestones,
            recommendedPacksEnabled: this.child?.recommendations,
        }};
    }
}