import { useEffect } from 'react';

const id = 'crs-optimizer'
const src = 'https://crsoptimize.conversionrate.store/comp/5BwyILtF0y.js'

const loadScript = () => {
    return new Promise<void>((resolve, reject) => {
        if (document.getElementById('crs-optimizer')) {
            resolve();
            return;
        }

        const script = document.createElement('script');
        script.id = id;
        script.src = src;
        script.async = true;

        script.onload = () => {
            resolve();
        };

        script.onerror = () => {
            reject(new Error(`Failed to load script: '${src}`));
        };

        document.head.appendChild(script);
    });
};

const CRSOptimizer = () => {
    const location = (window as any).location;
    const path = location?.pathname;

    useEffect(() => {
        if (process.env.ENV !== 'production') {
            return;
        }
        const load = async () => {
            try {
                await loadScript();
            } catch (error) {
                console.error(error);
            }
        };
        load();
    }, [])

    useEffect(() => {
        if (process.env.ENV !== 'production') {
            return;
        }
        const load = async () => {
            try {
                const existingScript = document.getElementById(id);
                if (existingScript) {
                    existingScript.remove();
                }
                await loadScript();
            } catch (error) {
                console.error(error);
            }
        };
        load();
    }, [path]);

    return (<></>);
}

export default CRSOptimizer;