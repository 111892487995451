export const getMyIp = async () => {
    let ip;

    await fetch('https://api.ipify.org?format=json')
    .then(response => {
      return response.json();
     })
    .then(res => {
        ip = res && res.ip
            ? res.ip
            : null;
    })
    .catch(err => {
        ip = null;
    })

    return ip;
  }