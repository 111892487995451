import { ICartStore, ICart, ICartBox, ICartBoxLine, IProduct, ISwap } from '@interfaces/_cart';
import { IParent } from '@v2/interfaces';
import { isCategoryNotFull } from '@v2/utils/cart';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';

const cartWithMetaSelector = createSelector([(state: any) => state.cart?.data as ICartStore], (item) => item);

function useCartWithMeta(): ICartStore {
    return useSelector(cartWithMetaSelector);
}

function useCart(): ICart {
    const cartWithMeta = useCartWithMeta();
    return useMemo(() => cartWithMeta.cart as ICart, [cartWithMeta]);
}

function useCartSwap(): ISwap {
    const cartWithMeta = useCartWithMeta();

    return useMemo(() => cartWithMeta.swap as ISwap, [cartWithMeta]);
}

function useCartAllBoxes(): ICartBox[] {
    const cart = useCart();

    return useMemo(() => cart && cart.boxes ? Object.values(cart.boxes) : [], [cart]);
}

function useCartBoxesCount(): number {
    const cart = useCart();
    return useMemo(() => cart && cart.boxes ? Object.values(cart.boxes).filter((box: ICartBox) => !box.incomplete).length : 0, [cart])
}

function useCartBoxes(): ICartBox[] {
    const cart = useCart();

    return useMemo(() => cart && cart.boxes ? Object.values(cart.boxes).filter((box: ICartBox) => !box.incomplete) : [], [cart]);
}

function useActiveBox(): ICartBox {
    const data = useCartWithMeta();

    return useMemo(() => data && data.cart && data.cart.boxes && Number.isInteger(data.activeBox) && data.cart.boxes[data.activeBox]
        ? data.cart.boxes[data.activeBox]
        : undefined, [data]);
}

function useActiveBoxIndex(): number {
    const data = useCartWithMeta();

    return useMemo(() => data && Number.isInteger(data.activeBox) && data.cart && data.cart.boxes && data.cart.boxes[data.activeBox]
        ? data.activeBox
        : null, [data]);
}

function useActiveBoxLine(productId: number): ICartBoxLine {
    const box = useActiveBox();

    return useMemo(() => box && box.lines && box.lines[productId]
        ? box.lines[productId]
        : undefined, [box, productId]);
}

function useActiveBoxItemsCount(): number {
    const box = useActiveBox();

    return useMemo(() => box && Number.isInteger(box.count) ? box.count : 0, [box]);
}

function useActiveBoxPercentCompleted(): number {
    const box = useActiveBox();
    const boxCount = useActiveBoxItemsCount();

    return useMemo(() => box && box.blueprint
            ? Math.round(boxCount / box.blueprint.minItems * 100)
            : 0, [box, boxCount]);
}

function useIsActiveBoxCountAllowed(): boolean {
    const box = useActiveBox();
    const boxCount = useActiveBoxItemsCount();

    return useMemo(() => box && box.blueprint
            ? boxCount >= box.blueprint.minItems
            : false, [box, boxCount]);
}

function useIsActiveBoxCategoryCountAllowed(): boolean {
    const box = useActiveBox();

    return useMemo(() => {
        if (!box || !box.lines) {
            return true;
        }

        for (let id in box.lines) {
            const product = box.lines[id].product;

            if (!box.counts || !box.counts[product.category] || box.counts[product.category] > product.categoryMaxItems) {
                return false;
            }
        }

        return true;
    }, [box]);
}

function useIsActiveBoxFull(): boolean {
    const box = useActiveBox();

    return useMemo(() => (!box || !box.blueprint)
        ? null
        : box.blueprint.minItems <= box.count, [box]);
}

function useIsCategoryFull(product: IProduct, productSize?: number): boolean {
    const box = useActiveBox();
    return useMemo(() => {
        const size = productSize ? (productSize - 1) : 0;

        if (!box || !box.lines || !box.counts || !box.blueprint || isCategoryNotFull(product, box.counts, size)) {
            return false;
        }

        return true;
    }, [box, productSize, product]);
}

function useIsInitialBoxChanged(): boolean {
    const box = useActiveBox();

    return useMemo(() => {
        if (!box || !box.lines || !box.editing || !box.initialLines) {
            return true;
        }

        if (Object.keys(box.initialLines).length !== Object.keys(box.lines).length) {
            return true;
        }

        for (let id in box.lines) {
            if (!box.initialLines[id] || box.lines[id].count !== box.initialLines[id].count) {
                return true;
            }
        }

        return false;
    }, [box]);
}

function usePackBoxIndex(id: number): number {
    const boxes = useCartAllBoxes();

    return useMemo(() => {
        if (!boxes || !id) {
            return null;
        }

        for (const key in boxes) {
            if (boxes[key].productId && boxes[key].productId === id) {
                return Number(key);
            }
        }

        return null;
    }, [boxes, id]);
}

function useIsCartEmpty(): boolean {
    const boxes = useCartBoxes();

    return useMemo(() => {
        if (!boxes) {
            return true
        }

        return boxes.length === 0;
    }, [boxes]);
}

function useChildParent(): IParent {
    const data = useCartWithMeta();
    const box = useMemo(() => {
        return data && data.cart && data.cart.boxes
            ? Object.values(data.cart.boxes).filter((box: ICartBox) => box.parent && box.parent.email && box.parent.firstName).slice(0, 1)[0] || null
            : null;
    }, [data]);

    return useMemo(() => {
        return (box && box.parent && box.parent.email && box.parent.firstName)
            ? box.parent
            : undefined;
    }, [box]);
}

function useActiveChildBox(): ICartBox {
    const data = useCartWithMeta();

    return useMemo(() => {
        return data && data.cart && data.cart.boxes && Number.isInteger(data.activeChildBox) && data.cart.boxes[data.activeChildBox]
        ? data.cart.boxes[data.activeChildBox]
        : undefined;
    }, [data]);
}

function useActiveChildBoxIndex(): number {
    const data = useCartWithMeta();

    return useMemo(() => {
        return data && Number.isInteger(data.activeChildBox) && data.cart && data.cart.boxes && data.cart.boxes[data.activeChildBox]
        ? data.activeChildBox
        : null;
    }, [data]);
}

function useIsGlobalSubscribed(): boolean {
    const boxes = useCartBoxes();

    return useMemo(() => {
        return Object.keys(boxes).length > 0
            ? Object.keys(boxes.filter((b: ICartBox) => b.subscribe)).length > 0
            : true;
    }, [boxes]);
}

export {
    useCart,
    useCartBoxes,
    useCartAllBoxes,
    useCartWithMeta,
    useCartBoxesCount,
    useCartSwap,
    useActiveBox,
    useActiveBoxIndex,
    useActiveBoxLine,
    useActiveBoxItemsCount,
    useActiveBoxPercentCompleted,
    useIsActiveBoxCountAllowed,
    useIsActiveBoxCategoryCountAllowed,
    useIsActiveBoxFull,
    useIsCategoryFull,
    useIsInitialBoxChanged,
    usePackBoxIndex,
    useIsCartEmpty,
    useChildParent,
    useActiveChildBox,
    useActiveChildBoxIndex,
    useIsGlobalSubscribed
}

(window as any).cbHooks = {
    useActiveBox: useActiveBox,
}
