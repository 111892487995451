import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

const NextDoorPixes = () => {
  const location = (window as any).location;
  const path = location?.pathname;
  const ndp = (window as any)?.ndp || null;
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (process.env.ENV !== "production" || !ndp) {
      return;
    }

    if (!loaded) {
      ndp('init', 'b14c99ed-02e7-496c-88f6-6f20d4e60c85', {});
      setLoaded(true);
    }

    ndp('track', 'PAGE_VIEW');
  }, [path, ndp]);

  return (
    <Helmet>
      <script type="text/javascript" id="nextdoor-pixel-script">
        {`(function(win, doc, sdk_url) {
                if (win.ndp) return;
                var tr = win.ndp = function() {
                    tr.handleRequest ? tr.handleRequest.apply(tr, arguments) : tr.queue.push(arguments);
                };
                tr.queue = [];
                var s = 'script';
                var new_script_section = doc.createElement(s);
                new_script_section.async = !0;
                new_script_section.src = sdk_url;
                var insert_pos = doc.getElementsByTagName(s)[0];
                insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
            })(window, document, 'https://ads.nextdoor.com/public/pixel/ndp.js');
            `}
      </script>
      <noscript id="nextdoor-pixel-image">
        {`<img height="1" width="1" style="display:none" src="https://flask.nextdoor.com/pixel?pid=b14c99ed-02e7-496c-88f6-6f20d4e60c85&ev=CONVERSION&noscript=1"/>`}
      </noscript>
    </Helmet>
  );
};

export default NextDoorPixes;
