import { css } from '@emotion/css';
import { tabletLandscapeUp } from '@styles/breakpoints';
import colors from '@styles/colors';

export default {
  promoBar: css({
    backgroundColor: colors.BLUE,
    color: colors.WHITE,
    padding: '0 10px',
    height: '50px',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',

    p: {
      margin: 0,
      fontSize: '14px !important',
      [tabletLandscapeUp]: {
        fontSize: '16px !important',
      },
    },

    'a:hover, a:active, a:focus': {
      color: colors.WHITE,
      textDecoration: 'underline',
    }
  }),
};