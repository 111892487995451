import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { usePageTrack } from '@v2/services/analytics.service';

import Hero from '@v2/components/pages/home/home-hero';
import Quotes from '@v2/components/pages/home/home-quotes';
import AgeBundles from '@v2/components/pages/home/home-age-bundles';
import ChooseAdventure from '@v2/components/pages/home/home-choose-adventure';
import UsVSThem from '@v2/components/pages/home/home-us-vs-them';
import ParentingIsAnArt from '@v2/components/pages/home/home-piaa';
import About from '@v2/components/pages/home/home-about';
import Reviews from '@v2/components/pages/home/home-reviews';
import Growing from '@v2/components/pages/home/home-growing';

import styles from '@v2/components/pages/home/home.styles';
import v2WrapperStyles from '@styles/wrapper';
import { useIsMobile } from '@components/common/helpers/screen-check';
import HomePageService from '@v2/services/homepage.service';
import { setEntities } from '@app/store/rtk.entities.store';
import { useEntity } from '@app/store/rtk.entities.selectors';
import { useIsLogged } from '@app/store/rtk.user.selectors';

export default function HomePage() {
    const [active, setActive] = useState(0);
    // const [active1, setActive1] = useState(1);
    const [active2, setActive2] = useState(4);
    const [height, setHeight] = useState('400vh');
    const mobile = useIsMobile(600);
    const tablet = useIsMobile(900);
    const isLogged = useIsLogged();

    const dispatch = useDispatch();
    const homepageData = useEntity('homepage');
    const withPromoBar = (document.getElementsByClassName('with-promo') && document.getElementsByClassName('with-promo')[0]) ? true : false;
    const headerOffset = (withPromoBar ? 108 : 58) - (tablet ? 10 : 0) - (isLogged ? 50 : 0);

    usePageTrack('Homepage');

    useEffect(() => {
        if (homepageData) {
            return;
        }

        HomePageService.getCached()
            .then((response: any) => dispatch(setEntities({'homepage': response})))
            .catch((err: any) => {
                HomePageService.get()
                    .then((response: any) => dispatch(setEntities({'homepage': response})))
                    .catch((err: any) => console.error('Header info failed', err));
            });
    }, []);

    useEffect(() => {
        /*
        function isInViewport(element: any, percentile: number) {
            const rect = element.getBoundingClientRect();

            return rect.top < window.innerHeight * percentile;
        }*/

        if (mobile) {
            return;
        }

        const handleScroll = (e: any) => {
            const a = document.documentElement.scrollTop;
            //const ch = document.documentElement.clientHeight;
            //const mod = 0;

            const s1 = document.getElementById('slide-1-1').offsetHeight
                        + document.getElementById('slide-1-2').offsetHeight
                        + document.getElementById('slide-1-3').offsetHeight;
            const s11 = s1
                        + document.getElementById('slide-1-4').offsetHeight;
            const s12 = s1
                        + document.getElementById('slide-1-4').offsetHeight;

            // const s12 = s1 + document.getElementById('slide-1-4').offsetHeight - document.getElementById('slide-1-last').offsetHeight;

            // const s2 = document.getElementById('slide-2-1').offsetHeight;
            const s3 = document.getElementById('slide-3-1').offsetHeight;
            const s4 = document.getElementById('slide-4-1').offsetHeight
                        + document.getElementById('slide-4-2').offsetHeight
                        + document.getElementById('slide-4-3').offsetHeight
                        + document.getElementById('slide-4-4').offsetHeight
                        + document.getElementById('slide-4-5').offsetHeight;

            setHeight(`${s11 + s4}px`);

            if (a < s1 - 100) {
                setActive(0);
            } else if (a >= s1 - 100 && a < s12) {
                setActive(0);

                /*
                const chunk = (s12 - s1 + 100) / 4;

                if (a < s12 - chunk * 2.8) {
                    setActive(1);
                    setActive1(1);
                } else if (a < s12 - chunk * 2.4) {
                    setActive(1);
                    setActive1(2);
                } else if (a < s12 - chunk * 1.2) {
                    setActive(1);
                    setActive1(3);
                } else {
                    setActive(1);
                    setActive1(4);
                }
                */
            } else if (a >= s12 - 100 && a < s11) {
                setActive(0);
            } else if (a >= s11 - 100 && a < s11 + s3) {
                const chunk = (s11 +  s3 - s11 + 100) / 3;

               if (a < s11 + s3 - chunk * 2) {
                    setActive(2);
                    setActive2(5);
                } else if (a < s11 + s3 - chunk * 1) {
                    setActive(2);
                    setActive2(6);
                } else {
                    setActive(2);
                    setActive2(7);
                }
            } else {
                setActive(0);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`${v2WrapperStyles.v2Global} ${v2WrapperStyles.v2FullScreenPageWrapper} ${styles.container}`}
                style={{position: 'relative', zIndex: 1, padding: 0, pointerEvents: 'none',}}
                id="sticky">
            <div className="page-wrapper" style={{height: height, position: 'relative', zIndex: 1, pointerEvents: 'none', background: '#ebf5f0'}}>
                <div className="a" style={{
                    padding: 0,
                    margin: 0,
                    position: active === 1 || active === 2 ? 'sticky' : 'initial',
                    height: `calc(100vh - ${headerOffset}px)`,
                    top: headerOffset,
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pointerEvents: 'none',
                }}>
                    {/*
                    <div className={`sequence ${active === 0 ? 'active' : ''}`} style={{backgroundColor: '#336699'}}></div>
                    <div className={`sequence ${active === 1 ? 'active' : ''}`} style={{backgroundColor: '#fafafa'}}></div>
                    <div className={`sequence ${active === 2 ? 'active' : ''}`} style={{backgroundColor: '#003366'}}></div>
                    <div className={`sequence ${active === 3 ? 'active' : ''}`} style={{backgroundColor: '#ff00ff'}}></div>
                    */}
                        <div id="slide-1" className={`sequence ${active === 0 ? 'active' : ''}`} style={{opacity: 1}}>
                            <div id="slide-1-1"><Hero /></div>
                            <div id="slide-1-2"><Growing /></div>
                            <div id="slide-1-3"><Quotes /></div>
                            <div id="slide-1-4">
                                <About active={4}/>
                                {/*<About active={1}/>
                                <div className="d-none d-md-block">
                                    <About active={2}/>
                                    <About active={3}/>
                                    <About active={4}/>
                                    <div id="slide-1-last">
                                        <About active={4}/>
                                    </div>
                                </div>
                                */}
                            </div>
                            <div id="slide-4-1">
                                <UsVSThem active={active2} setActive={setActive2}/>
                                <div className="d-none d-md-block">
                                    <UsVSThem active={active2} setActive={setActive2}/>
                                </div>
                            </div>
                            <div id="slide-4-2"><Reviews /></div>
                            <div id="slide-4-3"><ParentingIsAnArt /></div>
                            <div id="slide-4-4"><AgeBundles /></div>
                            <div id="slide-4-5"><ChooseAdventure /></div>
                        </div>
                        <div id="slide-2" className={`sequence ${active === 1 ? 'active' : ''}`} style={{zIndex: 10}}>
                            <div id="slide-2-1" className="subslide">
                                <About active={4}/>
                                {/*<About active={active1}/>*/}
                            </div>
                        </div>
                        <div id="slide-3" className={`sequence ${active === 2 ? 'active' : ''}`} style={{zIndex: 10}}>
                            <div id="slide-3-1">
                                <UsVSThem active={active2} setActive={setActive2}/>
                            </div>
                        </div>
                        {/*
                        <div id="slide-4" className={`sequence ${active === 3 ? 'active' : ''}`} style={{marginTop: 2200}}>
                            <div id="slide-4-1"><UsVSThem active={active2}/></div>
                            <div id="slide-4-2"><Reviews /></div>
                            <div id="slide-4-3"><ParentingIsAnArt /></div>
                            <div id="slide-4-4"><AgeBundles /></div>
                            <div id="slide-4-5"><ChooseAdventure /></div>
                        </div>
                        */}
                </div>
            </div>
        </div>
    );
}
