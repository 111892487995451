import React, { useEffect, useState } from 'react';
import { imgV2 as img } from '@styles/utils';
import styles from '@v2/components/pages/home/home.styles';
import { useHistoryWithReferrer } from '@utils/history';
import Slider, { Settings } from 'react-slick';
import slickStyle from '@v1/styles/vendor/slick';
import { useIsMobile } from '@components/common/helpers/screen-check';
import { LinkWithReferrer } from '@components/common/helpers/link';
import Select from 'react-select';
import BlogService, { BlogCategory, BlogPost } from '@v2/services/blog.service';
import Loader from '@v2/components/common/presentational/loader/loader';
import ThemeImage from '@v2/utils/image';

export default function ParentingIsAnArt() {
    const history = useHistoryWithReferrer();
    const isMobile = useIsMobile(767);

    const [loadingCategories, setLoadingCategories] = useState(false);
    const [loadingArticles, setLoadingArticles] = useState(false);
    const [categories, setCategories] = useState(null);
    const [articles, setArticles] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);

    function getTopicOptions() {
        var options: {value: number, label: string}[] = [];

        if (categories) {
            Object.values(categories).forEach((category: BlogCategory, index) => {
                if (category.count) {
                    options.push({ value: category.id, label: category.name });
                }
            });
        }

        return options;
    }

    // function setTopicSelectedOption(category: any) {
    //     history.pushWithReferrer(`/parenting-is-an-art/?cat=${category.value}`);
    // }

    function setCategorySelectedOption(category: any) {
        if(selectedOption.value !== category.value) {
            setSelectedOption(category);
        }
    }

    useEffect(() => {
        (async function () {
            setLoadingCategories(true);

            await BlogService.categories()
                .then((categories) => {
                    setCategories(categories);

                    return categories;
                })
                .then((categories) => {
                    setSelectedOption({ value: categories[0].id, label: categories[0].name});
                });

            setLoadingCategories(false);
        })();
    }, []);

    useEffect(() => {
        if (selectedOption) {
            (async function () {
                setLoadingArticles(true);

                await BlogService.articlesForCategory(selectedOption.value)
                    .then(setArticles);

                setLoadingArticles(false);
            })();
        }

    }, [selectedOption]);

    var settings: Settings = {
        onInit() {
            const slickList: any = document.getElementsByClassName("slick-list");
            slickList[0].classList.add('slick-list-after');
        },
        beforeChange(currentSlide, nextSlide) {
            const slides = document.getElementsByClassName("slick-slide");
            const activeSlides = document.getElementsByClassName("slick-active");
            const slickList: any = document.getElementsByClassName("slick-list");

            if ( slides.length === ( activeSlides.length + nextSlide ) ) {
                slickList[0].classList.remove('slick-list-after');
            } else {
                slickList[0].classList.add('slick-list-after');
            }
        },
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 850,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 767,
                settings: 'unslick',
            }
        ],
    };

    return (
        <div className={`${styles.container} ${styles.ParentingIsAnArt}`}>
            <div className="page-container">
                <div className="inner-container">
                    <div className="wrapper">
                        <div className="intro-wrapper">
                            <div className="intro">
                                <ThemeImage
                                    src={`homepage3/piaa/mother_siting_with_children.png`}
                                    alt={''} />
                                <div className="content">
                                    <h4>Parenting is an art</h4>
                                    <p>We believe there is a better way to nourish our kids.
                                        Cerebelly unites neuroscience, nutrition, and organic, whole food ingredients to give your little ones the very best start possible.</p>
                                    <button
                                        aria-label="Join our community"
                                        className="button primary red v2"
                                        onClick={() => {
                                            history.pushWithReferrer('/parenting-is-an-art');
                                        }}>
                                        join our community
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className={`categories section-carousel ${slickStyle}`}>
                        {!loadingCategories ?
                            !isMobile && categories &&
                                <Slider {...settings}>
                                    {
                                        Object.values(categories).map((category: BlogCategory, index) => category.count ?
                                            <a key={index}
                                                className={`category ${selectedOption.value === category.id ? 'active' : ''}`}
                                                onClick={() => setCategorySelectedOption({value: category.id, label: category.name})}>
                                                <div className="icon">
                                                    <img loading="lazy" src={category.icon} alt={''} />
                                                </div>
                                                <div className="name">{category.name}</div>
                                            </a> : null
                                        )
                                    }
                                </Slider>
                            :
                            <Loader style={{minHeight: 50, maxHeight: 50}} />
                        }
                        </div>
                        <div className="articles">
                            {!loadingArticles ?
                                articles &&
                                    <>
                                        {
                                            Object.values(articles).map((post: BlogPost, index) =>
                                                <div className="article" key={index}>
                                                    <div className="image">
                                                        <img loading="lazy" src={post.image} alt={''} />
                                                    </div>

                                                    <div className="content">
                                                        <div className="title">{post.title}</div>

                                                        <LinkWithReferrer
                                                            to={'/parenting-is-an-art/' + post.path}
                                                            className="explore">
                                                            Read this
                                                        </LinkWithReferrer>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>
                                :
                                <div className="loader">
                                    <Loader />
                                </div>
                            }
                        </div>
                        <div className="topic">
                            {isMobile &&
                                <Select
                                    className="filter"
                                    classNamePrefix="filter"
                                    defaultValue={selectedOption}
                                    onChange={setCategorySelectedOption}
                                    options={getTopicOptions()}
                                    placeholder="Select a topic..."
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>

            <ThemeImage className="leafs left" src={`homepage3/piaa/left_leafs.png`} alt={''} />
            <ThemeImage className="leafs right" src={`homepage3/piaa/right_leafs.png`} alt={''} />

            <img src={`${img('homepage3/piaa/separator.svg')}`} alt="wave" className="separator"></img>
        </div>
    );
}