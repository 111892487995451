import React from 'react';
import { buildRequest, RequestMethod, apiRequest } from '@utils/http';
import colors from '@styles/colors';
import { css } from '@emotion/css';

interface IErrorState {
    hasError: boolean;
}

const styles = css({
    minHeight: '50vh',
    margin: 'auto',
    textAlign: 'center',

    '.message': {
        margin: '100px auto',
        maxWidth: 600,
        padding: '70px 120px',
        backgroundColor: colors.BLUE_EXTRA_LIGHT,
        borderRadius: 10,
        textAlign: 'center',
    },
})

export function ErrorMessagePage({ heading, message }: { heading?: string, message?: string }) {
    return (
        <div className={styles}>
            <h1>
                {heading ? heading : 'Whoops! Something went wrong!'}
            </h1>
            <div className="message">
                {message ? message : 'Our site encountered an error. A report was sent to our team!'}
            </div>
        </div>
    );
}

//https://stackoverflow.com/questions/55946119/what-error-type-can-we-expect-in-getderivedstatefromerror
class ErrorBoundary extends React.Component<{}, IErrorState> {
    constructor(props: {}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    saveError = async (error: Error) => {
        if (!['ChunkLoadError'].includes(error.name)) {
            const request = buildRequest(RequestMethod.POST, true, {
                'name': error.name,
                'message': error?.message,
                'cause': error?.cause,
                'stack': error?.stack
            });

            await apiRequest('js-logs/create', request);
            return;
        }
        // According to Story 6331
        window.location.reload();
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        if (errorInfo && errorInfo.componentStack) {
            // The component stack is sometimes useful in development mode
            // In production it can be somewhat obfuscated, so feel free to omit this line.
            // console.log(errorInfo.componentStack);
        }

        window.TrackJS && TrackJS.track(error);

        this.saveError(error);

        console.error(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;

        if (!hasError) {
            return (this.props as any).children;
        }

        // You can render any custom fallback UI
        return <ErrorMessagePage />;
    }
}

export default ErrorBoundary;