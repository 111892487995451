import { IBoxChildStage } from '@interfaces/_cart';
import { IIngredient, IProduct, INutrient, IPack } from '@interfaces';
import { apiGet, apiPost } from '@utils/http';

interface IProductsResponse {
    pouches: IProduct[],
    bars: IProduct[],
    smoothies: IProduct[],
    bonebrothpouches: IProduct[],
    pouchpacks: IProduct[],
    smoothiepacks: IProduct[],
    barpacks: IProduct[],
    bonebrothpouchpacks: IProduct[],
    ageBundles: IProduct[],
    varietyPacks: IProduct[],
    boxes: IProduct[],
    categories: IProduct[],
}

export default class ProductService {
    static get(id: number): Promise<IProduct> {
        return apiGet(`product/get?id=${id}`)
            .then((response: any) => response as IProduct);
    }

    static getByName(category: string, name: string): Promise<IProduct> {
        return apiGet(`product/get-by-name?category=${category}&name=${name}`)
            .then((response: any) => response as IProduct);
    }

    static list(): Promise<IProductsResponse> {
        return apiGet(`product/list`)
            .then((response: any) => response);
    }

    static nutrients(id: number): Promise<INutrient[]> {
        return apiGet(`product/nutrients?id=${id}`)
            .then((response: any) => response as INutrient[]);
    }

    static ingredients(id: number): Promise<IIngredient[]> {
        return apiGet(`product/ingredients?id=${id}`)
            .then((response: any) => response as IIngredient[]);
    }

    static recommended(child: any): Promise<IBoxChildStage> {
        return apiPost('product/recommended', child)
            .then((response: any) => response as IBoxChildStage);
    }

    static defaultBundle(birthdate: string): Promise<IPack> {
        return apiPost('product/default-bundle', { birthdate })
            .then((response: any) => response as IPack);
    }
}