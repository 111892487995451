import { ICartStore, ICart, ICartBox, ICartBoxLine, IProduct, ISetActiveBoxBlueprint, ISwap } from '@interfaces/_cart';
import cloneDeep from 'lodash.clonedeep';
import { IChild } from '@interfaces';
import { IParent, ISurveyMeta } from '@interfaces/_survey';
import { BOX_CADENCE_OPTIONS } from "@components/common/helpers/box-delivery";
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useGTMCartDispatch } from '@v2/utils/gtm';

function round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

function calculateCartTotal(state: any) {
    return round(Object.values((state.cart.boxes))
        .filter((box: ICartBox) => !box.incomplete)
        .reduce((total: number, line: ICartBoxLine) =>  total + line.price, 0) as number);
}

function calculateBoxTotal(box: ICartBox) {
    box.count = 0;
    box.price = 0;
    box.priceDefault = 0;

    for (const productId in box.lines) {
        const line = box.lines[productId];
        const product = line.product;
        const productPrice = box.subscribe ? product.priceSubscribed : product.price;


        line.price = round(line.count * productPrice);

        box.count += line.count;
        box.price += line.price;

        box.priceDefault += round(line.count * product.price)
    }
}

function recalculateBox(state: any = {}, action: PayloadAction<{ index: number }>) {
    const data = getStateData(state);

    calculateBoxTotal(data.cart.boxes[action.payload.index]);

    return data;
}

function recalculateActiveBox(state: any) {
    const data = getStateData(state);

    calculateBoxTotal(data.cart.boxes[data.activeBox]);

    return data;
}

function getStateData(state: any = {}) {
    const data = state;

    if (!data.cart || !data.cart.boxes) {
        data.cart = {
            boxes: {},
            price: 0,
        };
    }

    return data;
}

function getEmptyBox(properties?: {}, isChild?: boolean): ICartBox {
    const defaultData: ICartBox = {
        lines: {} as { [key: number]: ICartBoxLine },
        type: 'custom',
        price: 0,
        count: 0,
        subscribe: true,
        newOrder: false,
        cadence: BOX_CADENCE_OPTIONS[0].value,
        counts: {},
        incomplete: true,
    };

    let data = { ...defaultData };

    if (isChild) {
        data = { ...data, ...getEmptyChildData() };
    }

    if (properties) {
        data = { ...data, ...properties };
    }

    return data;
}

function getEmptyCartData(): ICartStore {
    return {
        activeBox: null,
        activeChildBox: null,
        cart: {
            boxes: {},
            price: 0,
        },
        swap: {
            box: {}
        }
    };
}

function getEmptyChildData() {
    return {
        type: 'personalized',
        parent: {
            firstName: '',
            email: '',
            isLoggedIn: false,
        } as IParent,
        child: {
            name: '',
            gender: 'female',
            birthdate: '',
            recommendedPacksEnabled: true,
            isVegan: false,
            milestoneIds: [],
            planId: null,
            packItems: null
        } as IChild,
        survey: {
            previousScreen: 'initial',
            activeScreen: 'initial',
            completed: false,
        } as ISurveyMeta,
    };
}

function addBoxHelper(state = {}, action: PayloadAction<{ [key: string]: any }>) {
    const data = getStateData(state);

    data.activeBox = Object.keys(data.cart.boxes).length;

    if (action?.payload?.child) {
        data.activeChildBox = Object.keys(data.cart.boxes).length;
    }

    data.cart.boxes[data.activeBox] = getEmptyBox(action?.payload || {}, action?.payload?.child);

    return data;
}

function addIncompleteBoxHelper(state = {}) {
    const data = getStateData(state);

    data.activeBox = Object.keys(data.cart.boxes).length;
    data.cart.boxes[data.activeBox] = getEmptyBox();
    data.cart.boxes[data.activeBox].incomplete = true;

    return data;
}

function addChildBoxHelper(state = {}, action: PayloadAction<{ parent?: IParent, child?: IChild }>) {
    const data = getStateData(state);

    data.activeBox = Object.keys(data.cart.boxes).length;
    data.activeChildBox = Object.keys(data.cart.boxes).length;
    data.cart.boxes[data.activeBox] = getEmptyBox({}, true);

    if (action && action.payload.parent) {
        data.cart.boxes[data.activeBox].parent = action.payload.parent;
    }

    if (action && action.payload.child) {
        data.cart.boxes[data.activeBox].child = action.payload.child;
        data.cart.boxes[data.activeBox].title = action.payload.child.name
                                                ? `${action.payload.child.name}'s Box`
                                                : null;
    }

    // calculateBoxTotal(data.cart.boxes[data.activeBox]);

    return data;
}

function updateActiveChildBoxHelper(state = {}, action: PayloadAction<{ box: ICartBox }>) {
    const data = getStateData(state);

    if (action.payload.box) {
        if (action.payload.box.lines) {
            data.cart.boxes[data.activeChildBox].lines = action.payload.box.lines;
        }

        if (action.payload.box.blueprint) {
            data.cart.boxes[data.activeChildBox].blueprint = action.payload.box.blueprint;
        }

        if (action.payload.box.child) {
            data.cart.boxes[data.activeChildBox].child = action.payload.box.child;
            data.cart.boxes[data.activeChildBox].title = action.payload.box.child.name
                                                    ? `${action.payload.box.child.name}'s Box`
                                                    : null;
        }

        if (action.payload.box.survey) {
            data.cart.boxes[data.activeChildBox].survey = action.payload.box.survey;
        }

        if (action.payload.box.stage) {
            data.cart.boxes[data.activeChildBox].stage = action.payload.box.stage;
        }

        if (action.payload.box.parent) {
            data.cart.boxes[data.activeChildBox].parent = action.payload.box.parent;
        }

        calculateBoxTotal(data.cart.boxes[data.activeChildBox]);
    }

    let newState = data;

    if (action.payload.box) {
        newState.cart.boxes[data.activeChildBox] = action.payload.box;

        if (action.payload.box.lines) {
            newState.cart.price = calculateCartTotal(newState);
            newState = recalculateCategoryTotals(newState);
        }
    }

    return newState;
}

function resetChildBoxHelper(state = {}, action: PayloadAction<{ isVegan?: boolean }>) {
    const data = getStateData(state);
    const isVegan = action.payload.isVegan === null
                    ? (data.cart.boxes[data.activeBox].child.isVegan ? true : false)
                    : (action.payload.isVegan ? true : false);
    const products = isVegan
                    ? data.cart.boxes[data.activeBox].stage.veggieLines
                    : data.cart.boxes[data.activeBox].stage.lines;

    data.cart.boxes[data.activeBox].lines = cloneDeep(products);
    calculateBoxTotal(data.cart.boxes[data.activeBox]);

    let newState = data;
    if (data.cart.boxes[data.activeBox].stage.blueprint) {
        newState = storeSetActiveBoxBlueprint(newState, { blueprint: data.cart.boxes[data.activeBox].stage.blueprint })
    }
    newState.cart.boxes[data.activeBox.toString()].child.recommendedPacksEnabled = true;
    newState.cart.boxes[data.activeBox.toString()].child.isVegan = isVegan;
    newState.cart.boxes[data.activeBox.toString()].title = `${data.cart.boxes[data.activeBox].child.name}'s Box`;

    return newState;
}

function removeBoxHelper(state: any = {}, payload: { index: number }) {
    let newState = state;
    delete newState.cart.boxes[payload.index.toString()];

    if (payload.index === newState.activeBox) {
        newState.activeBox = null;
    } else if (payload.index < newState.activeBox) {
        newState.activeBox = newState.activeBox - 1;
    }

    const cart = { boxes: {}, price: 0 } as ICart;
    // eslint-disable-next-line
    Object.values((newState.cart.boxes) as any).forEach((box: ICartBox, key: number) => {
        Object.assign(cart.boxes, { [key.toString()]: box});
    });

    cart.price = round(Object.values(cart.boxes as any)
                .filter((box: ICartBox) => !box.incomplete)
                .reduce((total: number, line: ICartBoxLine) => total + line.price, 0) as number);
    newState.cart = cart;
    return newState;
}

function editBoxHelper(action: PayloadAction<{ box: ICartBox}>) {
    const data = getEmptyCartData();
    data.activeBox = 0;
    data.cart.boxes = [action.payload.box];
    data.cart.boxes[0].editing = true;
    data.cart.boxes[0].initialLines = data.cart.boxes[0].lines;
    data.cart.boxes[0].oosLines = {};
    data.cart.boxes[0].lines = {};
    data.cart.boxes[0].count = 0;

    Object.values(data.cart.boxes[0].initialLines).forEach((line: ICartBoxLine) => {
        if (line.product && line.product.availability === 'outofstock') {
            data.cart.boxes[0].oosLines[line.product.id] = line;
        } else {
            data.cart.boxes[0].lines[line.product.id] = line;
            data.cart.boxes[0].count = data.cart.boxes[0].count + line.count;
        }
    });

    data.cart.price = action.payload.box.price;
    return recalculateCategoryTotals(data as any);
}

function storeSetActiveBoxBlueprint(state: any = {}, box: ISetActiveBoxBlueprint) {
    const activeBoxIndex = state.activeBox.toString();
    const currentCount = state.cart.boxes[activeBoxIndex].count;
    const activeBox = state.cart.boxes[activeBoxIndex];
    let newState = state;
    newState.cart.boxes[activeBoxIndex].blueprint = box.blueprint
    // We need to scale the content of the box down if we had more items
    // than the box can contain. I expect this function lity to be changed
    const reducers: { [key: string]: number } = {};
    let reduced = 0;
    const currentLines: { [key: number]: ICartBoxLine } = activeBox.lines;
    // A dummy waty to make sure we're not gonna enter an endless loop;
    let lastExecutionReduced = 1;

    while (currentCount - reduced > box.blueprint.minItems && lastExecutionReduced > 0) {
        lastExecutionReduced = 0;

        for (let id in currentLines) {
            if (currentCount - reduced <= box.blueprint.minItems) {
                break;
            }

            reducers[id] = !reducers[id] ? 0 : reducers[id];
            if (currentLines[id].count - reducers[id] <= 0) {
                continue;
            }

            reducers[id] ++;
            reduced++;
            lastExecutionReduced++;
        }
    }

    // we need to recalculate the prices
    let count = 0;
    let price = 0;
    let priceDefault = 0;

    Object.values(currentLines).forEach((line: ICartBoxLine) => {
        const productPrice = activeBox.subscribe ? line.product.priceSubscribed : line.product.price;

        const countReducer = reducers[line.product.id] ? reducers[line.product.id] : 0;
        const lineCount = Math.max(line.count - countReducer, 0);
        const linePrice = round(lineCount * productPrice);

        if (lineCount > 0) {
            newState.cart.boxes[activeBoxIndex].lines[line.product.id.toString()].count = lineCount;
            newState.cart.boxes[activeBoxIndex].lines[line.product.id.toString()].price = linePrice;
            count += lineCount;
            price += linePrice;
            priceDefault += round(lineCount * line.product.price)
        } else {
            // I actually expect a request to leave the items but allow customers to add quantity
            delete newState.cart.boxes[activeBoxIndex].lines[line.product.id.toString()];
        }
    });

    newState.cart.boxes[activeBoxIndex].count = count;
    newState.cart.boxes[activeBoxIndex].price = round(price);
    newState.cart.boxes[activeBoxIndex].priceDefault = round(priceDefault);
    newState.cart.price = calculateCartTotal(newState);

    if (box.productId) {
        newState.cart.boxes[activeBoxIndex].productId = box.productId;
    }
    if (box.title) {
        newState.cart.boxes[activeBoxIndex].title = box.title;
    }
    if (box.image) {
        newState.cart.boxes[activeBoxIndex].image = box.image;
    }
    if (box.boxType) {
        newState.cart.boxes[activeBoxIndex].type = box.boxType;
    }
    if (box.productBlueprint) {
        newState.cart.boxes[activeBoxIndex].productBlueprint = box.productBlueprint;
    }
    if (box.boxType === 'age_bundle') {
        newState.cart.boxes[activeBoxIndex].isVegan = box.isVegan ? true : false;
    }
    if (box.product && box.product.size) {
        newState.cart.boxes[activeBoxIndex].productSize = box.product.size;
    }

    return newState;
}

function addActiveBoxLinesHelper(state: any = {}, lines: ICartBoxLine[]) {
    let newState = state;
    lines.forEach((line: ICartBoxLine) => {
        newState = updateActiveBoxLineHelper(newState, line, true);
    });

    return newState;
}

function updateActiveBoxLineHelper(state: any = {}, line: ICartBoxLine, add?: boolean) {
    const activeBoxIndex = state.activeBox.toString();
    let activeBox = state.cart.boxes[activeBoxIndex];
    const categoryItems = activeBox.counts || {};
    const existingLine = activeBox.lines[line.product.id.toString()];
    const existingCount = existingLine ? existingLine.count : 0;
    let categoryItemsCount = categoryItems[line.product.category] ? categoryItems[line.product.category] : 0;

    let productCount = add
                    ? Math.max(Math.min(line.count, line.product.categoryMaxItems - categoryItemsCount), 0) + existingCount
                    : (line.product.categoryMaxItems - categoryItemsCount - line.count + existingCount >= 0
                        ? line.count
                        : line.count + (line.product.categoryMaxItems - categoryItemsCount - line.count + existingCount));
    let newState = state;

    if (productCount <= 0) {
        if (existingLine) {
            delete newState.cart.boxes[activeBoxIndex].lines[line.product.id.toString()];
        }
    } else {
        const productPrice = activeBox.subscribe || activeBox.editing ? line.product.priceSubscribed : line.product.price;
        const newLine = {
            product: line.product,
            count: productCount,
            price: round(productCount * productPrice)
        } as ICartBoxLine;

        newState.cart.boxes[activeBoxIndex].lines[line.product.id.toString()] = newLine;
    }

    // recalculate cart/box totals
    activeBox = newState.cart.boxes[activeBoxIndex];
    let count = 0;
    let price = 0;
    let priceDefault = 0;

    Object.values(activeBox.lines).forEach((line: ICartBoxLine) => {
        count += line.count;
        price += line.price;
        priceDefault += line.count * line.product.price;
    });

    newState.cart.boxes[activeBoxIndex].count = count;
    newState.cart.boxes[activeBoxIndex].price = round(price);
    newState.cart.boxes[activeBoxIndex].priceDefault = round(priceDefault);
    newState.cart.price = calculateCartTotal(newState);
    newState = recalculateCategoryTotals(newState);

    return newState;
}

function recalculateCategoryTotals(state: any = {}) {
    const data = state as ICartStore;

    for (let key in data.cart.boxes) {
        const counts = {} as any;

        for (let productId in data.cart.boxes[key].lines) {
            const line = data.cart.boxes[key].lines[productId];

            if (!counts[line.product.category]) {
                counts[line.product.category] = 0;
            }
            counts[line.product.category] += line.count;
        }
        data.cart.boxes[key].counts = counts;
    }

    return data;
}

function updateCartProductsHelpers(state: any = {}, action: PayloadAction<{ data: any}>) {
    let data = state as ICartStore;

    for (let key in data.cart.boxes) {
        for (let productId in data.cart.boxes[key].lines) {
            const line = data.cart.boxes[key].lines[productId];

            if (action.payload.data[line.product.category]) {
                for (let pk in action.payload.data[line.product.category]) {
                    const product = action.payload.data[line.product.category][pk];
                    // eslint-disable-next-line
                    if (product.id == productId) {
                        data.cart.boxes[key].lines[productId].product = product;
                        break;
                    }
                }
            }
        }

        if (action.payload.data.boxes && data.cart.boxes[key].blueprint) {
            for (let bk in action.payload.data.boxes) {
                // eslint-disable-next-line
                if (action.payload.data.boxes[bk].id == data.cart.boxes[key].blueprint.id) {
                    let overwrites = {};
                    if (data.cart.boxes[key].type === 'variety_pack') {
                        overwrites = {
                            minItems: 0,
                            maxItems: 10 * data.cart.boxes[key].productSize,
                        };
                    }

                    data.cart.boxes[key].blueprint = Object.assign({}, action.payload.data.boxes[bk], overwrites);
                    break;
                }
            }
        }

        if (Object.keys(data.cart.boxes[key].lines).length > 0) {
            calculateBoxTotal(data.cart.boxes[key]);
        }
    }

    return recalculateCategoryTotals(data);
}

function addActiveBoxToCartHelper(state: any = {}) {
    let newState = state;

    newState.cart.boxes[state.activeBox.toString()].incomplete = false;
    newState.cart.price = calculateCartTotal(newState);
    newState.activeBox = null;
    newState.activeChildBox = null;

    return newState;
}

function emptyActiveBoxHelper(state: any = {}) {
    let newState = state;
    newState.cart.boxes[state.activeBox.toString()] = getEmptyBox();
    newState.cart.price = calculateCartTotal(newState);

    return newState;
}

function emptyCartHelper() {
    return getEmptyCartData();
}

function emptyCartOnlyHelper(state: any = {}) {
    let cartData = getEmptyCartData()
    cartData.swap = state.swap;
    return cartData;
}


function subscribeBoxHelper(state: any = {}, action: PayloadAction<{ index: number, subscribe: boolean }>) {
    const data = getStateData(state);
    const boxIndex =  action.payload.index.toString();
    const subscribe = action.payload.subscribe;
    const box = (state.cart.boxes[boxIndex]);
    let newState = data;
    let boxTotal = 0;

    Object.values(box.lines).forEach((line: ICartBoxLine) => {
        const productPrice = subscribe ? line.product.priceSubscribed : line.product.price;
        const linePrice = round(line.count * productPrice);

        newState.cart.boxes[boxIndex].lines[line.product.id.toString()].price = linePrice
        boxTotal += linePrice;
    });

    newState.cart.boxes[boxIndex].subscribe = subscribe;
    newState.cart.boxes[boxIndex].price = round(boxTotal);
    newState.cart.price = calculateCartTotal(newState);

    return newState;
}

function updateBoxCadenceHelper(state: any = {}, action: PayloadAction<{ index: number, cadence: string }>) {
    const data = getStateData(state);
    let newState = data;
    newState.cart.boxes[action.payload.index.toString()].cadence = action.payload.cadence;
    return newState;
}

function setBoxNewOrderHelper(state: any = {}, action: PayloadAction<{ index: number, newOrder: boolean }>) {
    const data = getStateData(state);
    let newState = data;
    newState.cart.boxes[action.payload.index.toString()].newOrder = action.payload.newOrder;
    return newState;
}

function swapBoxHelper(state: any = {}, action: PayloadAction<{ swap: ISwap }>) {
    const data = getStateData(state);
    let newState = data;
    newState.swap.box = action.payload.swap.box;
    return newState;
}

const INITIAL_STATE = getInitialState();

function getInitialState() {
    let emptyCartData = getEmptyCartData();

    let initialCartData = emptyCartData;

    try {
        const serializedState = localStorage.getItem('v4Cart');
        if (serializedState !== null) {
            initialCartData = JSON.parse(serializedState);
        }
    } catch (e) { }

    let initialUIState: any = {
        data: { ...initialCartData }
    };

    // do this to clean your local storage
    // initialUIState = {data: emptyCartData }; localStorage.setItem('v4Cart', JSON.stringify(initialUIState));

    return initialUIState;
}

const persistCart = (state: any) => {
    localStorage.setItem('v4Cart', JSON.stringify(state.data));
    return state;
}

const slice = createSlice({
    name: 'cart',
    initialState: INITIAL_STATE,
    reducers: {
        addNewBox: (state: any, action: PayloadAction<{ [key: string]: any }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: addBoxHelper(clonedState.data, action) };
            return persistCart(newState);
        },
        addNewIncompleteBox: (state: any): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: addIncompleteBoxHelper(clonedState.data) };
            return persistCart(newState);
        },
        addChildBox: (state: any, action: PayloadAction<{ parent?: IParent, child?: IChild }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: addChildBoxHelper(clonedState.data, action) };
            return persistCart(newState);
        },
        resetChildBox: (state: any, action: PayloadAction<{ isVegan?: boolean }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: resetChildBoxHelper(clonedState.data, action) };
            return persistCart(newState);
        },
        removeBox: (state: any, action: PayloadAction<{ index: number }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: removeBoxHelper(clonedState.data, action.payload)}
            return persistCart(newState);
        },
        removeActiveBox: (state: any): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: Number.isInteger(clonedState.data.activeBox)
                                ? removeBoxHelper(clonedState.data, {index: clonedState.data.activeBox})
                                : clonedState.data };
            return persistCart(newState);
        },
        editBox: (_: any, action: PayloadAction<{ box: ICartBox}>): any => {
            const newState = { data: editBoxHelper(action) };
            return persistCart(newState);
        },
        subscribeBox: (state: any, action: PayloadAction<{ index: number, subscribe: boolean }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: subscribeBoxHelper(clonedState.data, action) };
            return persistCart(newState);
        },
        subscribeActiveBox: (state: any, action: PayloadAction<{ subscribe: boolean }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: subscribeBoxHelper(clonedState.data, { ...action, payload: { ...action.payload, index: clonedState.data.activeBox }})};
            return persistCart(newState);
        },
        updateBoxCadence: (state: any, action: PayloadAction<{ index: number, cadence: string }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: updateBoxCadenceHelper(clonedState.data, action) };
            return persistCart(newState);
        },
        setBoxNewOrder: (state: any, action: PayloadAction<{ index: number, newOrder: boolean }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: setBoxNewOrderHelper(clonedState.data, action)};
            return persistCart(newState);
        },
        recalculateBoxTotals: (state: any, action: PayloadAction<{ index: number }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: recalculateBox(clonedState.data, action)};
            return persistCart(newState);
        },
        recalculateActiveBoxTotals: (state: any): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: recalculateActiveBox(clonedState.data)};
            return persistCart(newState);
        },
        addActiveBoxLines: (state: any, action: PayloadAction<{ bundle: IProduct }>): any => {
            const clonedState = cloneDeep(state);
            const lines: ICartBoxLine[] = action.payload.bundle.products.map((product: any) => {
                return {
                    count: action.payload.bundle.config[product.id],
                    product: product,
                }
            });
            const newState = { data: addActiveBoxLinesHelper(clonedState.data, lines)};
            return persistCart(newState);
        },
        setActiveBox: (state: any, action: PayloadAction<{ index: number }>): any => {
            const clonedState = cloneDeep(state);
            const newState = clonedState;
            newState.data.activeBox = parseInt(`${action.payload.index}`);
            return persistCart(newState);
        },
        setActiveChildBox: (state: any, action: PayloadAction<{ index: number }>): any => {
            const clonedState = cloneDeep(state);
            const newState = clonedState;
            newState.data.activeChildBox = parseInt(`${action.payload.index}`);
            return persistCart(newState);
        },
        setActiveBoxBlueprint: (state: any, action: PayloadAction<{ box: ISetActiveBoxBlueprint }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: storeSetActiveBoxBlueprint(clonedState.data, action.payload.box)};
            return persistCart(newState);
        },
        setActiveBoxProductCount: (state: any, action: PayloadAction<{ count: number }>): any => {
            const clonedState = cloneDeep(state);
            let data = getStateData(clonedState.data);
            data.cart.boxes[data.activeBox].productCount = parseInt(`${action.payload.count}`);
            const newState = { data };
            return persistCart(newState);
        },
        updateActiveBox: (state: any, action: PayloadAction<{ box: ICartBox }>): any => {
            const clonedState = cloneDeep(state);
            let data = getStateData(clonedState.data);
            data.cart.boxes[data.activeBox] = action.payload.box;
            const newState = { data };
            return persistCart(newState);
        },
        updateActiveBoxLine: (state: any, action: PayloadAction<{ product: IProduct, count: number }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: updateActiveBoxLineHelper(clonedState.data, { ...action.payload }) };
            return persistCart(newState);
        },
        updateCartProducts: (state: any, action: PayloadAction<{ data: any}>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: updateCartProductsHelpers(clonedState.data, action) };
            return persistCart(newState);
        },
        addActiveBoxToCart: (state: any): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: addActiveBoxToCartHelper(clonedState.data) };
            return persistCart(newState);
        },
        emptyActiveBox: (state: any): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: emptyActiveBoxHelper(clonedState.data) };
            return persistCart(newState);
        },
        emptyCart: (): any => {
            const newState = { data: emptyCartHelper() };
            return persistCart(newState);
        },
        emptyCartOnly: (state: any): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: emptyCartOnlyHelper(clonedState.data) };
            return persistCart(newState);
        },
        swapBox: (state: any, action: PayloadAction<{ swap: ISwap }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: swapBoxHelper(clonedState.data, action) };
            return persistCart(newState);
        },
        updateActiveChildBox: (state: any, action: PayloadAction<{ box: ICartBox }>): any => {
            const clonedState = cloneDeep(state);
            const newState = { data: updateActiveChildBoxHelper(clonedState.data, action) };
            return persistCart(newState);
        },
    },
});

const cartReducer = slice.reducer;

const { addActiveBoxToCart } = slice.actions
export const {
    addActiveBoxLines,
    addChildBox,
    addNewBox,
    addNewIncompleteBox,
    editBox,
    emptyCart,
    emptyCartOnly,
    recalculateActiveBoxTotals,
    recalculateBoxTotals,
    removeActiveBox,
    removeBox,
    resetChildBox,
    setActiveBox,
    setActiveBoxBlueprint,
    setActiveBoxProductCount,
    setActiveChildBox,
    setBoxNewOrder,
    subscribeActiveBox,
    subscribeBox,
    swapBox,
    updateActiveBox,
    updateActiveBoxLine,
    updateActiveChildBox,
    updateBoxCadence,
    updateCartProducts
 } = slice.actions;

export default cartReducer;

export function useAddActiveBoxToCart() {
    const dispatch = useDispatch();
    const gtmDispatch = useGTMCartDispatch(true);

    return () => {
        gtmDispatch('add_to_cart');
        dispatch(addActiveBoxToCart());
    };
}