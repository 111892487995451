import { useModalsSelector, useShopModals } from '@v2/store/rtk.modals.selectors';
import { closeShopModals, hideGeneralModal } from '@v2/store/rtk.modals.store';
import * as H from 'history';
import { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

interface HistoryWithReferrer extends H.History {
    pushWithReferrer(path: any, state?: H.LocationState): void;
}

function referrerObject(a?: string) {
    return { referrer: window.location.href };
}

function useHistoryWithReferrer() {
    const dispatch = useDispatch();
    const history = useHistory() as HistoryWithReferrer;
    const goBackRef = useRef(history.goBack);
    const shopModals = useShopModals();
    const generalModals = useModalsSelector();

    const pushWithReferrer = useCallback((path: any, state?: H.LocationState) => {
        const newState = Object.assign({}, state, referrerObject());
        history.push(path, newState);
    }, [history]);

    const goBack = useCallback(() => {
        if(!(history?.location?.state as any)?.referrer) {
            history.replace('/shop');
            if(shopModals.length > 0) {
                dispatch(closeShopModals());
                return;
            }
            dispatch(hideGeneralModal())
        } else {
            goBackRef.current();
        }
    }, [history, shopModals, generalModals]);

    history.pushWithReferrer = pushWithReferrer;
    history.goBack = goBack;
    return history;
}

export {
    referrerObject,
    useHistoryWithReferrer,
}
