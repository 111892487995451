import { IBox, IShippingScheduleDate, ICartBox, ICoupon, ICancellation, ISubscriptionGiftCard, ICart, IUserCheckout } from '@interfaces';
import { apiGet, apiPut, apiPost } from '@utils/http';
import SubscriptionEntity, { ISubscription } from '@v2/entities/subscription.entity';
import cloneDeep from 'lodash.clonedeep';

interface ISubscriptionResponse {
    subscription: ISubscription;
    error: string;
}


export function mapUserAddresses(user: IUserCheckout) {
    const addresses: { [key: string]: any } = { shipping: {}, billing: {}};

    for (let key in addresses) {
        const address = (user as any)[key + 'Address'];

        addresses[key] = {
            first_name: address.firstName,
            last_name: address.lastName,
            address_1: address.address1,
            address_2: address.address2,
            city: address.city,
            state: address.state,
            postcode: address.postcode,
            phone: user.smsEnabled ? (user.phone?.startsWith?.('+1') ? user.phone : `+1${user.phone}`) : null,
            email: user.email,
        }
    }

    return [addresses.shipping, user.billingAsShipping ? addresses.shipping : addresses.billing]
}

export function mapBoxes(cart: ICart, oldBoxCount?: number) {
    const boxes: any = [];

    for (const boxIndex in cart.boxes) {
        const box = cart.boxes[boxIndex];

        if (box.incomplete || !box.blueprint) {
            continue;
        }

        const products: { [key: number]: number } = {};
        const nochild = !(box.child && box.child.name);

        for (const productId in box.lines) {
            products[productId] = oldBoxCount || box.lines[productId].count;
        }

        let plan = {
            products: products,
            nochild: nochild,
            plan_id: box.blueprint.id,
            product_id: box.productId,
            product_count: box.type === 'product' ? box.count : box.productCount,
            new_order: box.newOrder,
            cadence: box.subscribe ? box.cadence : 'none',
            type: box.type,
            name: box.title ? box.title
                            : (nochild
                                ? box.blueprint.name
                                : box.child.name),

            // Trail packs disabled
            // is_trialpack: (nochild && localStorage.getItem('cb_is_trialpack') === 'yes'),
            // bundle_name: localStorage.getItem('cb_bundle_name') || '',

            is_trialpack: false,
            bundle_name: '',
        }

        if (!plan.nochild) {
            plan = {
                ...plan,
                ...{
                    child_name: box.child.name,
                    gender: box.child.gender as any,
                    birthday: box.child.birthdate,
                    milestones: box.child.milestoneIds,
                    stage_id: box.stage.id,
                    recommended_packs_enabled: box.child.recommendedPacksEnabled !== null ? box.child.recommendedPacksEnabled : true,
                    is_vegan: box.child.isVegan,
                }
            }
        } else if (box.type === 'age_bundle') {
            plan = {
                ...plan,
                ...{
                    is_vegan: box.isVegan,
                }
            }
        }
        boxes.push(plan);
    }

    return boxes;
}

export default class SubscriptionService {
    static get(): Promise<SubscriptionEntity> {
        return apiGet('subscription/get')
            .then((response: any) => SubscriptionEntity.fromJson(response)).then((subscription) => {
                // Temporary solution according to Story 6187
                const orderSubsctiptionCost = subscription?.order?.costShipping ?? 0 + subscription?.order?.costShippingPrepaid ?? 0;
                const shippingMethodCost = subscription?.shipping?.options?.[subscription?.shipping?.method?.toLowerCase?.()]?.cost ?? 0;
                if(orderSubsctiptionCost !== shippingMethodCost) {
                    return this.setShippingMethod(subscription.shipping.method).then(subscription => {
                        return subscription as SubscriptionEntity;
                    });
                }
                return subscription;
            });
    }

    static getBox(id: number): Promise<ICartBox> {
        return apiGet(`subscription/get-box?id=${id}`)
                .then((response: any) => response as ICartBox);
    }

    static giftCards(): Promise<ISubscriptionGiftCard[]> {
        return apiGet(`subscription/gift-cards`)
                .then((response: any) => response as ISubscriptionGiftCard[]);
    }

    static getCancellationData(boxId: number): Promise<ICancellation> {
        return apiGet(`cancellation/get-data?id=${boxId}`)
                .then((response: any) => response as ICancellation);
    }

    static reactivateBox(boxId: number): Promise<Response> {
        return apiPut('subscription/reactivate-box', { 'id': boxId });
    }

    static pauseBox(box: IBox): Promise<Response> {
        const data = {
            'id': box.id,
            'cancel_event_type': 'pause',
        };

        return apiPost('subscription/cancel-box', data);
    }

    static cancelBox(boxId: number): Promise<ISubscription>  {
        const data = {
            'id': boxId,
            'cancel_event_type': 'cancel',
        };

        return apiPost('subscription/cancel-box', data)
                .then((response: any) => {
                    if (response.code) {
                        throw response;
                    }

                    return response as ISubscription;
                });
    }

    static submitCancellationFeedback(reasons: Array<string>, moreInfo: string): Promise<Response> {
        const data = { reasons, moreInfo };

        return apiPost('cancellation/survey', data);
    }

    static reorderBox(box: IBox, newOrder: boolean, subscribe: boolean, cadence: string): Promise<Response> {
        return apiPost('subscription/reorder-box', {
            id: box.id,
            new_order: newOrder,
            subscribe: subscribe,
            cadence: cadence,
        });
    }

    static addBoxes(boxes: { [key: number]: ICartBox }, shippingMethod?: string, coupons?: ICoupon[])
        : Promise<{ subscription: ISubscription, order: { id: string, type: string, count: number }}> {
        const data = {
            boxes: mapBoxes({ boxes: boxes }),
            shipping: shippingMethod,
            coupons: coupons,
        };

        return apiPost('subscription/add-boxes', data)
                .then((response: any) => {
                    if (response.code) {
                        throw response;
                    }

                    return {
                        subscription: response.subscription as ISubscription,
                        order: response.order as { id: string, type: string, count: number },
                    };
                });
    }

    static swapBoxes(newBoxes: { [key: number]: ICartBox }, oldBox: IBox, saveOldCount?: boolean) : Promise<ISubscription> {
        const data = {
            new_boxes: mapBoxes({ boxes: newBoxes }, saveOldCount ? oldBox.products[0].quantity : null),
            old_box: oldBox,
        };
        return apiPost('subscription/swap-boxes', data)
                .then((response: any) => {
                    if (response.code) {
                        throw response;
                    }

                    return response as ISubscription;
                });
    }

    static swapBoxItems(box: { id: string, pouches: any, name: string, properties: any }) : Promise<ISubscription> {
        return apiPost('subscription/swap-box-items', {box: box})
                .then((response: any) => {
                    if (response.code) {
                        throw response;
                    }

                    return response as ISubscription;
                });
    }

    static addCoupon(code: string): Promise<ISubscriptionResponse> {
        return apiPost('coupon/apply-coupon', { code: code })
                .then((response: any) => response as ISubscriptionResponse);
    }

    static removeCoupon(code: string): Promise<ISubscriptionResponse> {
        return apiPost('coupon/remove-coupon', { code: code })
                .then((response: any) => response as ISubscriptionResponse);
    }

    static addDynamicCoupon(): Promise<ISubscriptionResponse> {
        return apiPost('coupon/apply-winback-coupon', {})
                .then((response: any) => response as ISubscriptionResponse);
    }

    static setShippingMethod(method: string): Promise<ISubscription> {
        return apiPost('subscription/set-shipping-method', { new_shipping_option: method })
                .then((response: any) => response as ISubscription);
    }

    static setBoxShipping(box: IBox, date: string, cadence: string): Promise<ISubscription> {
        return apiPost('subscription/box-shipping', {
            id: box.id,
            date: date,
            cadence: cadence,
        }).then((response: any) => response as ISubscription);
    }

    static shipTogether(date: string, cadence: string, boxIds: number[]): Promise<ISubscription> {
        return apiPost('subscription/ship-together', { date: date, cadence: cadence, boxIds: boxIds})
                .then((response: any) => response as ISubscription);
    }

    static rescheduleShipment(date: string, cadence: string, boxIds: number[]): Promise<ISubscription> {
        return apiPost('subscription/reschedule-shipment', { date: date, cadence: cadence, boxIds: boxIds})
                .then((response: any) => response as ISubscription);
    }

    static applyGifts(): Promise<Response> {
        return apiPost('subscription/apply-gifts', {});
    }

    static getBuyAgain(): Promise<Response> {
        return apiGet('subscription/buy-again');
    }

    /**
     * @deprecated used when the calendar was a thing
     */
    static setShippingDates(shippingDates: Array<IShippingScheduleDate>): Promise<Response> {
        const data = { shippingDates: shippingDates }

        return apiPost('subscription/shipping-dates', data);
    }

    /**
     * @deprecated used when the calendar was a thing
     *
     * @param should be 'YYYY-MM-DD'
     */
    static setNextShipmentDate(nextShippingDate: string): Promise<Response> {
        const updateData = {
            'new_shipping_date': nextShippingDate
        };

        return apiPost('subscription/reschedule-shipment', updateData);
    }


    static addUserBox(box: ICartBox): Promise<Response> {
        return apiPost('subscription/add-box', { new_child_data: mapBoxes({ boxes: [box] }) });
    }

    static updateUserBox(box: ICartBox, products?: { [key: number]: number }, properties?: { [key: string]: any }, child_properties?: { [key: string]: any }):
            Promise<ISubscription> {

        let updatedProducts = cloneDeep(products);

        if (!updatedProducts) {
            updatedProducts = {};

            for (const productId in box.lines) {
                updatedProducts[productId] = box.lines[productId].count;
            }
        }

        let payload = {
            id: box.id,
            plan_id: box.blueprint.id,
            products: updatedProducts,
            properties: properties,
            child_properties: child_properties,
            recommended_packs_enabled: (box.child && box.editing)
                                        ? (box.child.recommendedPacksEnabled !== null
                                            ? box.child.recommendedPacksEnabled
                                            : true)
                                        : null,
        };

        return apiPost('subscription/update-box', payload).then((response: any) => response as ISubscription);
    }
}