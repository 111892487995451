import React from 'react';
import { imgV2 as img } from '@styles/utils';
import styles from '@v2/components/pages/home/home.styles';
import { LinkWithReferrer } from '@v2/components/common/helpers/link';
import ThemeImage from '@v2/utils/image';

export default function Reviews() {
    const Reviews = () => {
        return (
            <>
                <div className="card review">
                    <div className='stars'>
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                    </div>
                    <h4>LOVE A HEALTHY ALTERNATIVE!</h4>
                    <p>This is definitely a premium gourmet baby food that incorporates mature flavors in a way that amateur tasters love,
                        and the best part is the ingredients are nutritious and support healthy development. My baby loves the food.</p>
                    <div> — JONETTA A.</div>
                </div>
                <div className="card">
                    <ThemeImage src={`homepage3/reviews/1.jpg`} width={350} height={350} alt={''} maxWidth={false} />
                </div>
                <div className="card review">
                    <div className='stars'>
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                    </div>
                    <h4>MY 1 YEAR OLD AND MY 3 YEAR OLD LOVE THESE!</h4>
                    <p>
                        These pouches are such an easy way to give my boys a different variety of veggies.
                        I initially purchased for my 1 year old, but I had to buy more so he and my 3 year old would not fight over them!
                        They both love the flavors and I love that they are getting tons of nutrition :) a staple in our house! –
                    </p>
                    <div>— NICOLE</div>
                </div>
                <div className="card">
                    <ThemeImage src={'homepage3/reviews/2.jpg'} width={350} height={350} alt={''} maxWidth={false} />
                </div>
                <div className="card review">
                    <div className='stars'>
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                    </div>
                    <h4>GREAT AND EASY</h4>
                    <p>It's comforting to know your baby food is created with them in mind, and I feel at peace feeding my little one Cerebelly products.
                        My baby loves all the flavors, and we will continue to purchase from Cerebelly because of their excellent product and customer service.</p>
                    <div>— ANDREW H.</div>
                </div>
                <div className="card">
                    <ThemeImage src={`homepage3/reviews/3.jpeg`} width={350} height={350} alt={''} maxWidth={false} />
                </div>
                <div className="card review">
                    <div className='stars'>
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                    </div>
                    <h4>WORTH IT!</h4>
                    <p>
                        My twins love these pouches & they are so convenient to have on hand, especially in the toddler stage!
                        We pack them with their lunch for preschool, and always keep a few in the diaper bag for whenever hunger strikes.
                        I feel good as a parent knowing that my “quick & easy” go-to is something healthy.
                    </p>
                    <div>— ALI</div>
                </div>
                <div className="card">
                    <ThemeImage src={'homepage3/reviews/1.jpg'} width={350} height={350} alt={''} maxWidth={false} />
                </div>
                <div className="card review">
                    <div className='stars'>
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                        <img loading="lazy" src={`${img('homepage3/reviews/star.svg')}`} alt={'star'} />
                    </div>
                    <h4>GREAT SELECTION AND INGREDIENTS</h4>
                    <p>My toddlers have been loving the Cerebelly smoothie pouches and i’m excited to try the additional flavors with our new baby when its time.
                        Cerebelly is a brand I trust to make healthy, organic, nutritious food for my babies and toddlers and i’m so glad they are here!</p>
                    <div>— JESSICA C.</div>
                </div>
                <div className="card">
                    <ThemeImage src={'homepage3/reviews/4.jpg'} width={350} height={350} alt={''} maxWidth={false} />
                </div>
            </>
        )
    }
    return (
        <div className={`${styles.container} ${styles.Reviews}`}>
            <img src={`${img('homepage3/reviews/separator.svg')}`} alt="wave" className="separator"></img>

            <div className="wrapper">
                <div className="header">why parents love cerebelly</div>
                <div className="slider">
                    <div className="slide-track">
                        <Reviews />
                        <Reviews />
                    </div>
                </div>

                <div className="view-all">
                    <LinkWithReferrer
                        to={'/reviews'}
                        className="explore">
                        SEE ALL REVIEWS
                    </LinkWithReferrer>
                </div>

                <ThemeImage className='leafs left' src={`homepage3/reviews/left_leafs.png`} alt={''} />
                <ThemeImage className='leafs right' src={`homepage3/reviews/right_leafs.png`} alt={''} />
            </div>
        </div>
    )
}