import { IProduct, IBoxBlueprint } from '@interfaces/_cart';

export function findBlueprintBySize({ list, size }: { list: IBoxBlueprint[], size: number }) {
    for (let blueprint of list) {
        if (size >= blueprint.minItems && size <= blueprint.maxItems) {
            return blueprint;
        }
    }

    return null;
}

export function mapVeggieBundle(bundle: IProduct): IProduct {

    if (!bundle.veggie || !bundle.veggie.size || !bundle.veggie.config || !bundle.veggie.blueprint || !bundle.veggie.products) {
        return bundle;
    }

    const copy = Object.assign({}, bundle, {
        config: bundle.veggie.config,
        size: bundle.veggie.size,
        products: bundle.veggie.products,
        blueprint: bundle.veggie.blueprint,
        nutrients: bundle.veggie.nutrients,
        image: bundle.veggie.image ? bundle.veggie.image : bundle.image,
        mainImage: bundle.veggie.mainImage ? bundle.veggie.mainImage : bundle.image,
        status: bundle.veggie.status ? bundle.veggie.status : bundle.status,
        hash: bundle.veggie.hash ? bundle.veggie.hash : bundle.hash,
    });

    return copy;
}