import React, { Fragment, useEffect, useState } from 'react';
import styles from './loader.styles';
import { imgV2 as img } from '@styles/utils';

export function Animation({ size, showText, text }: { size?: number, showText?: boolean, text?: string[] }) {
    const [imgIndex, setImgIndex] = useState(0);
    const [textIndex, setTextIndex] = useState(0);

    const images = [
        'Apple',
        'Avocado',
        'Beans',
        'Broccoli',
        'ChickPea',
        'Ginger',
        'Leaf',
        'Mango',
        'Pear',
        'Peas',
        'Tomato',
    ];

    const loaderText = text || [
        'Picking apples',
        'Plucking spinach',
        'Foraging for mushrooms',
        'Counting pumpkin seeds',
        'Squashing squashes',
        'Extracting algal oil',
    ];

    useEffect(() => {
        setTimeout(function () {
            setImgIndex(images.length === imgIndex + 1 ? 0 : imgIndex + 1);
            setTextIndex(loaderText.length === textIndex + 1 ? 0 : textIndex + 1);
        }, 2000);
    });

    return (
        <>
            {
                <span className={styles.fruitAnimation}>
                    {Object.values(images).map((image: any, index: number) => {
                        return (
                            <img
                                key={index}
                                alt={''}
                                width={size ? size : '140'}
                                height={size ? size : '140'}
                                src={img(`/loader/${image}.svg`)}
                                style={imgIndex !== index ? { display: 'none' } : {}}
                            />
                        )
                    })
                    }
                </span>
            }
            {showText &&
                <div className={'loader-text'}>
                    {loaderText[textIndex]}
                </div>
            }
        </>
    );
}

export function ContentLoader({ children, loading, mobileDots, style }
    : { children?: React.ReactNode, loading: boolean, mobileDots?: boolean, style?: any }
): JSX.Element {
    return loading
        ? <Loader mobileDots={mobileDots} style={style} />
        : <Fragment>{ children }</Fragment>;
}

export default function Loader({ overlay, style, debug, mobileDots }
    : { overlay?: boolean, style?: any, debug?: string, mobileDots?: boolean }
) {
    return (
        <div className={styles.dots}>
            <div className={mobileDots ? 'd-none d-sm-block' : ''}>
                <div className={`${styles.fruitLoader} ${overlay ? 'overlay' : ''}`} style={style}>
                    <div><Animation size={80} /></div>
                    {process.env.ENV === 'development' && debug ? debug : null}
                </div>
            </div>
            <div className={`dot-pulse ${mobileDots ? 'd-block' : 'd-none'} d-md-none`}></div>
        </div>
    );
}