import React, { useCallback, useRef, useState, useEffect, Suspense, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { useHistoryWithReferrer } from '@utils/history';

import { IModalState } from '@components/common/presentational/modal/interfaces';
import Clickable from '@v2/components/common/presentational/clickables/default';

import { modalCSS, modalOverlayCSS } from '@components/common/presentational/modal/styles';
import closeButton from '@img/modal/close-button.svg';
import closeButtonWhite from '@img/modal/close-button-white.svg';
import { unzoom } from '@v2/utils/ui';
import Loader from '../loader/loader';
import { useModalsSelector } from '@app/store/rtk.modals.selectors';
import { useActiveBox } from '@app/store/cart.selectors';
import { hideGeneralModal } from '@app/store/rtk.modals.store';
import cloneDeep from 'lodash.clonedeep';

const classMap: { [key: string]: string } = {
    undefined: 'custom',
    null: 'custom',
    plain: '',
}

function ModalContent({ settings, onLoaded, props }: { settings: IModalState, onLoaded: (() => void), props?: any }) {
    const [componentProps, setProps] = useState({ ...settings?.content?.props, ...(props || {}) })
    const Component = settings?.content?.component;
    const Content: any = useMemo(() => cloneDeep(Component), [Component])

    useEffect(() => {
        setProps({ ...settings?.content?.props, ...(props || {}) });
    }, [settings, props]);

    // there is a bug somewhere and I can't find it
    if (!Component) {
        return null;
    }

    if((Component as any)?._payload) {
        return <Suspense fallback={<Loader />}>
                    <Content props={componentProps} {...componentProps} />
                </Suspense>
    }

    if(Component instanceof Function) {
        return <Component props={componentProps} {...componentProps} />
    }

    if(typeof Component === 'string') {
        return <Component props={componentProps} {...componentProps} />
    }

    return null;
}

function ExitConfiramtion({ handleClose, showConfirmation, setShowConfirmation, isSwapping }
    : { handleClose: any, showConfirmation: boolean, setShowConfirmation: any, isSwapping?: boolean }) {
    const history = useHistoryWithReferrer();
    const box = useActiveBox();
    const oosEdit = box && box.editing && box.oosLines && Object.keys(box.oosLines).length > 0;
    return (
        <>
            {showConfirmation &&
                <div className={`confirmation-screen`}>
                    <div className="wrapper">
                        <Clickable className="default-close-container" onClick={() => setShowConfirmation(false)}>
                            <div className={`default-close`}>
                                <img src={closeButton} tabIndex={0} role="button" alt={'close modal'} />
                            </div>
                        </Clickable>
                        <h3>
                            Your changes won’t be saved
                        </h3>
                        {((oosEdit && !isSwapping) || history.location.pathname.startsWith('/swap/oos'))
                            ?
                            <p>
                                There still is an <b>Out of Stock</b> item within your bundle are you sure you would like to exit?
                            </p>
                            :
                            <p>
                                You will be taken back to the My Subscriptions Page and your product replacement will not be saved.
                            </p>
                        }
                        <button onClick={handleClose} className="button primary blue">
                            CONTINUE
                        </button>
                    </div>
                </div>
            }
        </>
    )
}

function ModalComponent({ settings, onClose, props }: { settings: IModalState, onClose: any, props?: any }) {
    const dispatch = useDispatch();
    const history = useHistoryWithReferrer();
    const div = useRef(null);
    const box = useActiveBox();
    const [close, setClose] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const closeable = settings.closeable === undefined ? true : settings.closeable;
    const willShowConfirmation = (box && box.editing && box.oosLines && Object.keys(box.oosLines).length > 0) ||
        history.location.pathname.startsWith('/swap/');

    const handleClose = (e: React.FormEvent<EventTarget>) => {
        e.preventDefault();
        setClose(true);

        onClose();

        if (settings.onClose) {
            settings.onClose({ dispatch: dispatch, props: settings.content.props });
        }

        if (!settings.onCloseKeepOverflowHidden) {
            document.body.classList.remove('m-overflow-hidden');
        }
    }

    const onLoaded = useCallback(() => {
        if (settings.windowType !== 'CART2') {
            document.body.classList.add('m-overflow-hidden');
        }

        if (div?.current?.focus) {
            div.current.focus();
        }

        unzoom();
    }, []);

    const className = classMap[settings.type] ? classMap[settings.type] : '';

    const handleCloseShop = () => {
        history.goBack();
    }

    return (
        <>
            <span className={modalOverlayCSS(settings)}></span>
            <div className={`modal
                    ${modalCSS(settings)}
                    ${settings.className}
                    ${(['SHOP'].includes(settings.windowType) || ['BOTTOM'].includes(settings.alignType))
                    ? `animate__animated ${close ? 'animate__slideOutDown' : 'animate__slideInUp'}`
                    : ''}`
            }
                style={settings.style}
                tabIndex={-1}
                ref={div}
                role="dialog"
                aria-hidden="false"
                data-acsb-hidden="false"
                onMouseDown={(closeable && !['SHOP'].includes(settings.windowType)) ? handleClose : handleCloseShop}>
                <div className={`main`}
                    role="dialog"
                    aria-modal="true"
                    aria-hidden="false"
                    data-acsb-overlay="sidebar"
                    data-acsb-hidden="false"
                    onMouseDown={e => {
                        e.nativeEvent.stopImmediatePropagation();
                        e.stopPropagation();
                    }}>
                    <div className={Array.isArray(settings.classes)
                        ? [className, ...settings.classes].join(' ')
                        : className}>

                        {(!['SHOP'].includes(settings.windowType) &&
                            !['BOTTOM'].includes(settings.alignType)) &&
                            <div className={'controls'}>
                                {closeable && <CloseButton settings={settings} handleClose={handleClose} />}
                            </div>
                        }
                        <div className={'content'}>
                            <ModalContent
                                settings={settings}
                                onLoaded={onLoaded}
                                props={props} />

                            {
                                settings.content && settings.content.iframe &&

                                <iframe
                                    src={settings.content.iframe}
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        border: 0,
                                    }} />
                            }

                            <ExitConfiramtion
                                showConfirmation={showConfirmation}
                                setShowConfirmation={setShowConfirmation}
                                handleClose={handleClose}
                                isSwapping={history.location.pathname.startsWith('/swap')} />
                        </div>
                        <span
                            tabIndex={0}
                            role="button"
                            data-acsb-clickable="true"
                            data-acsb-navigable="true"
                            data-acsb-now-navigable="true"
                            onFocus={() => div?.current?.focus()}>
                        </span>
                    </div>
                </div>
                {(['SHOP'].includes(settings.windowType) || ['BOTTOM'].includes(settings.alignType) ||
                    history.location.pathname.startsWith('/swap/survey') ||
                    history.location.pathname.startsWith('/swap/builder')) && settings.className === 'how-to-cancel-subscription' &&

                    <div className={`controls shop-modal`}>
                        {(closeable && !showConfirmation) &&
                            <CloseButton
                                settings={settings}
                                handleClose={(e: any) => willShowConfirmation ? setShowConfirmation(true) : handleClose(e)} />}
                    </div>
                }
            </div>
        </>
    );
}

function CloseButton({ settings, handleClose, white }: { settings: IModalState, handleClose: Function, white?: boolean }) {
    return (
        <Clickable className="default-close-container" onClick={handleClose}>
            <div className={`default-close ${settings.closeButtonOutside ? 'outside-close' : ''}`}>
                <img
                    src={white ? closeButtonWhite : closeButton}
                    tabIndex={0}
                    role="button"
                    alt={'close modal'} />
            </div>

            <span className="text" style={{ display: 'none' }}>Close</span>
        </Clickable>
    )
}

function StoreModalComponent({ settings }: { settings: IModalState }) {
    const dispatch = useDispatch();

    const handleClose = () => {
        if (['SHOP'].includes(settings.windowType) || ['BOTTOM'].includes(settings.alignType)) {
            setTimeout(() => dispatch(hideGeneralModal()), 1000);
            return;
        }

        dispatch(hideGeneralModal());
    }

    return (
        <ModalComponent settings={settings} onClose={handleClose} />
    );
}

export const Modal = React.memo(ModalComponent, function (prev, next) {
    const sameId = prev.settings.id && next.settings.id && prev.settings.id === next.settings.id;
    const sameProps = (prev.settings.windowType === next.settings.windowType)
        && (prev.settings.alignType === next.settings.alignType)
        && (prev.settings.position === next.settings.position)
        && (prev.settings.content.component === next.settings.content.component);

    return sameId || sameProps;
});

const StoreModal = React.memo(StoreModalComponent, function (prev, next) {
    const sameId = prev.settings.id === next.settings.id;
    const sameProps = (prev.settings.windowType === next.settings.windowType)
        && (prev.settings.alignType === next.settings.alignType)
        && (prev.settings.position === next.settings.position)
        && (prev.settings.content.component === next.settings.content.component);

    return sameId || sameProps;
});

export default function ModalsWrapper() {
    const modalsState = useModalsSelector();
    return (
        <div>
            {
                modalsState &&
                modalsState.map((modal: IModalState, key: number) => {
                    return (
                        <StoreModal
                            key={modal.id}
                            settings={modal} />
                    );
                })
            }
        </div>
    );
}
