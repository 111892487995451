import { LinkWithReferrer } from '@components/common/helpers/link';
import styles from '@v2/components/pages/home/home.styles';
import { packAndTrack } from '@v2/services/analytics.service';
import { imgV2 } from '@v2/styles/utils';
import ThemeImage from '@v2/utils/image';
import { useIsMobile } from '@components/common/helpers/screen-check';

export default function Hero() {
    const isMobile = useIsMobile(767);

    const eventAction = () => {
        packAndTrack(
            'Shop Now Clicked',
            [{ 'view': 'Homepage', 'section': 'Product Slider' }]);
    }

    return (
        <div className={styles.hero}>
            <div className="page-container">
                <div className="inner-container">
                    {!isMobile
                        ?
                        <h1>
                            the only baby food with 16 key <br />brain-supporting nutrients
                        </h1>
                        :
                        <h1>
                            the only baby food with <br />16 key brain-supporting <br />nutrients
                        </h1>
                    }
                </div>
            </div>
            <div className="page-container">
                <div className="inner-container shop-now-warapper">
                    <LinkWithReferrer
                    className="button primary red v2"
                    to={'/shop'}
                    onClick={eventAction}>shop now</LinkWithReferrer>
                </div>
            </div>

            {!isMobile ?
                <div className="animations desktop">
                    <div className="hand-first">
                        <ThemeImage
                            src={'homepage3/hero/desktop/pears.svg'} alt={'pears'}
                            className="pears animate__animated animate__fadeIn"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/leaf-top-hand-first.svg'} alt={''}
                            className="leaf-top animate__animated animate__zoomIn animate__delay-08s"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/leaf-bottom-hand-first.svg'} alt={''}
                            className="leaf-bottom animate__animated animate__zoomIn animate__delay-08s"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/pouch-hand-first.png'}
                            lazy={false}
                            width={736}
                            className="image-hand"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/vB2.svg'} alt={'vB2'}
                            className="vB2 animate__animated animate__fadeInUp"/>
                    </div>
                    <div className="hand-middle">
                        <ThemeImage
                            src={'homepage3/hero/desktop/apple1.svg'} alt={'apple'}
                            className="apple1 animate__animated animate__fadeIn"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/apple2.svg'} alt={'apple'}
                            className="apple2 animate__animated animate__fadeIn"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/leaf-middle.svg'} alt={''}
                            className="leaf-middle animate__animated animate__zoomIn animate__delay-1-2s"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/bar-hand-middle.png'}
                            lazy={false}
                            width={1154}
                            className="image-hand"/>
                        <ThemeImage src={'homepage3/hero/desktop/DHA.svg'} alt={'DHA'}
                            className="DHA animate__animated animate__fadeInUp"/>
                    </div>
                    <div className="hand-last">
                        <ThemeImage src={'homepage3/hero/desktop/leaf-top-hand-last.svg'} alt={''}
                            className="leaf-top animate__animated animate__zoomIn animate__delay-1-7s"/>
                        <ThemeImage src={'homepage3/hero/desktop/leaf-bottom-hand-last.svg'} alt={''}
                            className="leaf-bottom animate__animated animate__zoomIn animate__delay-1-7s"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/Fe.svg'} alt={'Fe'}
                            className="Fe animate__animated animate__fadeInUp"/>
                        <ThemeImage
                            src={'homepage3/hero/desktop/pouch-hand-last.png'}
                            lazy={false}
                            width={784}
                            className="image-hand"/>
                        <ThemeImage src={'homepage3/hero/desktop/peas.svg'} alt={'peas'}
                            className="peas animate__animated animate__fadeIn"/>
                    </div>
                    <ThemeImage src={'homepage3/awards-logo.svg'} lazy={false} alt={'awards logo'} className="scale-up scale-up-delay-2 awards-logo"/>
                </div>
                :
                <div className="mobile">
                    {/* Animation: bounceInUp */}
                    <div className='animations'>
                        <div className="hand-first">
                            <ThemeImage src={'homepage3/hero/mobile/leaf-top-hand-first.svg'} alt={'leafs'} className="scale-up scale-up-delay-2 leaf-top"/>
                            <ThemeImage src={'homepage3/hero/mobile/leaf-bottom-hand-first.svg'} alt={'leafs'} className="scale-up scale-up leaf-bottom"/>
                            <ThemeImage src={'homepage3/hero/mobile/pouch-hand-first.png'} lazy={false} width={400} className="image-hand"/>
                        </div>
                        <div className="hand-middle">
                            <ThemeImage src={'homepage3/hero/mobile/leaf-middle.svg'} alt={'leafs'} className="scale-up scale-up-delay-2 leaf-middle"/>
                            <ThemeImage src={'homepage3/hero/mobile/bar-hand-middle.png'} lazy={false} width={140} className="image-hand"/>
                        </div>
                        <div className="hand-last">
                            <ThemeImage src={'homepage3/hero/desktop/leaf-top-hand-last.svg'} alt={'leafs'} className="scale-up scale-up-delay-2 leaf-top"/>
                            <ThemeImage src={'homepage3/hero/desktop/leaf-bottom-hand-last.svg'} alt={'leafs'} className="scale-up scale-up-delay-2-5 leaf-bottom"/>
                            <ThemeImage src={'homepage3/hero/mobile/pouch-hand-last.png'} lazy={false} width={400} className="image-hand"/>
                        </div>
                        <ThemeImage src={'homepage3/awards-logo.svg'} lazy={false} alt={'awards logo'} className="scale-up scale-up-delay-2-5 awards-logo"/>
                    </div>

                    {/* Animation: fadeIn */}
                    <img src={imgV2('homepage3/hero/desktop/apple1.svg')} alt={'red apple'} className="fade-in red-apple"/>
                    <img src={imgV2('homepage3/hero/desktop/apple2.svg')} alt={'apple'} className="fade-in apple"/>
                    <img src={imgV2('homepage3/hero/desktop/pears.svg')} alt={'pears'} className="fade-in pears"/>
                    <img src={imgV2('homepage3/hero/desktop/peas.svg')} alt={'peas'} className="fade-in peas"/>

                    {/* Animation: fadeInUp */}
                    <img src={imgV2('homepage3/hero/desktop/DHA.svg')} alt={'apple'} className="fade-in-up dha"/>
                    <img src={imgV2('homepage3/hero/desktop/Fe.svg')} alt={'pears'} className="fade-in-up fe"/>
                    <img src={imgV2('homepage3/hero/desktop/vB2.svg')} alt={'peas'} className="fade-in-up vb2"/>
                </div>
            }
            <img src={imgV2('homepage3/hero/desktop/separator.svg')} alt={''} className="separator"/>
        </div>
    );
}
