import { imgV2 } from '@styles/utils';

import { apiImageSource } from '@v2/utils/http';

export function WPImage({ src, lazy = true, alt, className, animations, width }
    : { src: string, lazy?: boolean, alt?: string, className?: string, animations?: string, width?: number }
) {
    if (typeof src !== 'string') {
        return null;
    }

    const isUploads = src && src.includes('wp-content/uploads/');
    const isUploadsShort = src && ['2019', '2020', '2021', '2022', '2023', '2024'].includes(src.split('/')[0]);
    const uploadsPath = isUploads ? src.split('wp-content/uploads/')[1] : (isUploadsShort ? src : null);

    let webpSrc = `webp?path=${uploadsPath}`;
    let avifSrc = `avif?path=${uploadsPath}`;
    let baseSrc = `get?path=${uploadsPath}`;

    if (width) {
        webpSrc += '&width=' + width;
        avifSrc += '&width=' + width;
        baseSrc += '&width=' + width;
    }

    return (
        <picture>
            {(isUploads || isUploadsShort) && !src.endsWith('.svg') ?
                <source type="image/avif" srcSet={`${apiImageSource(avifSrc)} 1x`} /> : null
            }
            {(isUploads || isUploadsShort) && !src.endsWith('.svg') ?
                <source type="image/webp" srcSet={`${apiImageSource(webpSrc)} 1x`} /> : null
            }
            <img src={isUploads || isUploadsShort ? apiImageSource(baseSrc) : src}
                    loading={lazy ? 'lazy' : 'eager'}
                    alt={alt || ''}
                    style={{maxWidth: '100%'}}
                    className={className}
                    {...{ animations: animations || null }}
                    />
        </picture>
    );
}

export function WPImagePlain({ src, alt, lazy, className }: { src: string, alt?: string, lazy?: boolean, className?: string }) {
    const isUploadsShort = src && ['2019', '2020', '2021', '2022', '2023', '2024'].includes(src.split('/')[0]);

    return <img src={isUploadsShort ? `${process.env.API_BASE}/wp-content/uploads/${src}` : src}
                loading={lazy ? 'lazy' : 'eager'}
                className={className}
                alt={alt || ''} />
}


export default function ThemeImage({ src, lazy = true, alt, className, animations, trim, maxWidth = true, width, height }
: {src: string, lazy?: boolean,
    alt?: string, className?: string, animations?: string,
    trim?: boolean, maxWidth?: boolean, width?: number, height?: number }
) {
    if (typeof src !== 'string') {
        return null;
    }

    const isUploads = src && src.includes('wp-content/uploads/');
    const isUploadsShort = src && ['2019', '2020', '2021', '2022', '2023', '2024'].includes(src.split('/')[0]);
    const isTheme = src && !isUploadsShort && !src.includes('wp-content/uploads/');

    let uploadsPath = isUploads ?
        src.split('wp-content/uploads/')[1] :
        (
            isTheme ?
                imgV2(src).split('wp-content/themes/')[1] :
                (
                    isUploadsShort ?
                    src :
                    null
                )
        );

    // 2x
    let avifSrc2x = null;
    let webpSrc2x = null;

    if (window.devicePixelRatio > 1 && !src.endsWith('.svg')) {
        avifSrc2x = apiImageSource(`avif?format=avif&is_theme_image=${isTheme}`+
        `${width ? '&width='+width : ''}`+
        `${height ? '&height='+height : ''}`+
        `&path=${encodeURIComponent(uploadsPath)}`);

        webpSrc2x = apiImageSource(`webp?format=webp&is_theme_image=${isTheme}`+
        `${width ? '&width='+width : ''}`+
        `${height ? '&height='+height : ''}`+
        `&path=${encodeURIComponent(uploadsPath)}${trim ? '&mod=trim' : ''}`);
    }

    const webpSrc = apiImageSource(`webp?format=webp&is_theme_image=${isTheme}`+
        `${width ? '&width='+width : ''}`+
        `${height ? '&height='+height : ''}`+
        `&path=${encodeURIComponent(uploadsPath)}${trim ? '&mod=trim' : ''}`);

    const avifSrc = apiImageSource(`avif?format=avif&is_theme_image=${isTheme}`+
        `${width ? '&width='+width : ''}`+
        `${height ? '&height='+height : ''}`+
        `&path=${encodeURIComponent(uploadsPath)}`);

    const baseSrc = apiImageSource(`get?is_theme_image=${isTheme}`+
        `${width ? '&width='+width : ''}`+
        `${height ? '&height='+height : ''}`+
        `&path=${encodeURIComponent(uploadsPath)}`);

    // 1x && svg
    if (!height && width && window.devicePixelRatio !== 2) {
        width = parseInt(Number(width / 2).toFixed());
    }

    const thepath = src.endsWith('.svg') ? imgV2(src) : baseSrc;

    return (
        <picture>
            {!src.endsWith('.svg') ?
                <source type="image/avif" srcSet={`${avifSrc} 1x${avifSrc2x ? ', ' + avifSrc2x + ' 2x' : ''}`}/> : null
            }
            {!src.endsWith('.svg') ?
                <source type="image/webp" srcSet={`${webpSrc} 1x${webpSrc2x ? ', ' + webpSrc2x + ' 2x' : ''}`} /> : null
            }
            <img src={isUploads || isUploadsShort || isTheme ? thepath : src}
                    loading={lazy ? 'lazy' : 'eager'}
                    alt={alt || ''}
                    style={{maxWidth: maxWidth ? '100%' : 'auto'}}
                    className={className}
                    width={width}
                    height={height}
                    {...{ animations: animations || null }}
                    />
        </picture>
    );
}