import styles from './loader.styles';
import { Animation } from './loader';
import { Fragment } from 'react';

export function PageContentLoader({ children, loading, text, showText, overlay, debug }
    : { children: React.ReactElement, loading: boolean, text? : string[], showText?: boolean, overlay?: boolean, debug?: string }): React.ReactElement {

    return loading
        ? <PageLoader text={text} showText={showText} overlay={overlay} debug={debug} />
        : <Fragment>{ children }</Fragment>;
}

export default function PageLoader(
    { text, showText, overlay, debug } :
    { text? : string[], showText?: boolean, overlay?: boolean, debug?: string }
) {
    if (process.env.ENV === 'development' && debug) {
        showText = true;
        text = [debug];
    }

    return (
        <div className={`${styles.fruitPageLoader}
                            ${showText ? 'with-text' : ''}
                            ${overlay ? 'overlay' : ''}`}>
            <Animation showText={showText} text={text}/>
        </div>
    );
}
