import { ICartBox, ICartStore } from '@interfaces';
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { IProduct } from "@v2/interfaces";
import { track } from '@v2/services/analytics.service';
import { useCart } from '@app/store/cart.selectors';
import { useUser } from '@app/store/rtk.user.selectors';
import { useCheckoutData } from '@v2/store/rtk.checkout.selectors';
import { store } from '@v2/store';

interface IGTMActionFieldObject {
    id?: string;	        // The transaction ID (e.g. T1234).
    affiliation?: string;	// The store or affiliation from which this transaction occurred (e.g. Google Store).
    revenue?: number;	    // Specifies the total revenue or grand total associated with the transaction (e.g. 11.99).
                            // This value may include shipping, tax costs, or other adjustments to total revenue that
                            // you want to include as part of your revenue calculations. Note: If revenue is not set,
                            // its value will be automatically calculated using the product quantity and price fields
                            // of all products in the same hit.
    tax?: number;           // The total tax associated with the transaction.
    shipping?: number;	    // The shipping cost associated with the transaction.
    coupon?: string;	    // The transaction coupon redeemed with the transaction.
    list?: string;	        // The list that the associated products belong to. Optional.
    step?: number;	        // A number representing a step in the checkout process. Optional on `checkout` actions.
    option?: string;        // Additional field for checkout and checkout_option actions that can describe option
                            // information on the checkout page, like selected payment method.
}

interface IGTMProductFieldObject {
    id?: string;	    // The product ID or SKU (e.g. P67890).
                        // * One of id or name must be set.
    name?: string;	    // The name of the product (e.g. Android T-Shirt).
                        // * One of id or name must be set.
    brand?: string;	    // The brand associated with the product (e.g. Google).
    category?: string;  // The category to which the product belongs (e.g. Apparel). Use / as a delimiter to
                        // specify up to 5-levels of hierarchy (e.g. Apparel/Men/T-Shirts).
    variant?: string;   // The variant of the product (e.g. Black).
    price?: number;     // The price of a product (e.g. 29.20).
    quantity?: number;  // The quantity of a product (e.g. 2).
    coupon?: string;    // The coupon code associated with a product (e.g. SUMMER_SALE13).
    position?: number;  // The product's position in a list or collection (e.g. 2).
}

interface IGTMItem {
    item_id: string;
    item_name: string;
    item_category: string;
    item_brand: string;
    currency: string;
    quantity: number;
    price: number;
    discount: number;
}

interface IGTMPayload {
    client_id?: string;
    user_properties?: { [key: string]: any },
    event: string;
    ecommerce: {
        transaction_id?: string;
        currency?: string;
        coupon?: string;
        value?: number;
        shipping?: number;
        items?: IGTMItem[];

        creative_name?: string;
        creative_slot?: string;
        location_id?: string;
    };
    gua?: {
        ecommerce: {
            purchase?: {
                actionField?: IGTMActionFieldObject;
                products: IGTMProductFieldObject[];
            };

            detail?: {
                actionField?: IGTMActionFieldObject;
                products: IGTMProductFieldObject[];
            };

            checkout?: {
                actionField?: IGTMActionFieldObject;
                products: IGTMProductFieldObject[];
            };
        };
    }
}

/**
 *
 *  Existing GA4 properties
 *  {
 *      item_id: "SKU_12345",
 *      item_name: "Stan and Friends Tee",
 *      affiliation: "Google Merchandise Store",
 *      coupon: "SUMMER_FUN",
 *      currency: "USD",
 *      discount: 2.22,
 *      index: 0,
 *      item_brand: "Google",
 *      item_category: "Apparel",
 *      item_category2: "Adult",
 *      item_category3: "Shirts",
 *      item_category4: "Crew",
 *      item_category5: "Short sleeve",
 *      item_list_id: "related_products",
 *      item_list_name: "Related Products",
 *      item_variant: "green",
 *      location_id: "L_12345",
 *      price: 9.99,
 *      quantity: 1
 *  },
 */
function productToItem(product: IProduct, properties?: { [key: string]: any }): IGTMItem {
    const item: { [key: string]: any } = {
        item_name: product.title,
        item_id: 'ID_' + product.id,
        item_category: product.subcategory ? product.subcategory : product.category,
        item_brand: "Cerebelly",
    };

    return {
        ...item,
        ...(properties || {})
    } as IGTMItem;
}

export function boxToItem(box: ICartBox): IGTMItem {
    let id = `ID_${box.blueprint.id}`;
    let title = box.blueprint.name.replace('Bundle Bundle', 'Bundle');
    let count = box.count;
    let category = Object.keys(box.counts)[0];

    switch (box.type) {
        case 'personalized':
            id = 'STAGE_' + box.stage.id;
            title = 'Personalized ' + box.blueprint.name;
            count = 1;
            category = 'personalized';
            break;
        case 'custom':
            id = 'CUSTOM_' + box.blueprint.id;
            title = 'Custom ' + box.blueprint.name;
            count = 1;
            category = 'custom';
            break;
        case 'age_bundle':
            id = 'ID_' + box.productId;
            title = box.title.replace('Bundle Bundle', 'Bundle');
            count = 1;
            category = box.type;
            break;
        case 'variety_pack':
            id = 'ID_' + box.productId;
            title = box.title;
            count = box.productCount;
            category = box.type;
            break;
        case 'product':
            id = 'ID_' + box.productId;
            title = box.title;
            break;
    }

    if (!box.priceDefault) {
        console.error('Box has no default price:', box)
    }

    return {
        item_id: id,
        item_name: title,
        item_category: category,
        item_brand: "Cerebelly",
        currency: 'USD',
        quantity: count,
        price: Number((box.priceDefault).toFixed(2)),
        discount: box.subscribe
                    ? Number((Number((box.priceDefault).toFixed(2)) - Number((box.price).toFixed(2))).toFixed(2))
                    : 0,
    }
}


export function useGTMCartDispatch(activeBoxOnly = false, withShipping = false) {
    const gtmDispatch = useGTMDispatch();
    const cart = useCart();
    const user = useUser();
    const checkout = useCheckoutData();

    return (event: string, properties?: { [key: string]: any }, cartDiscount?: number, userProps?: { [key: string]: any }) => {
        try {
            let items = null;

            if (activeBoxOnly) {
                // I don't want to debug right now, but trust me, the active box can't come from a hook
                const cartStore: { data: ICartStore | {} } = store.getState().cart
                    ? store.getState().cart as { data: ICartStore }
                    : { data: {} };
                const activeBoxIndex = (cartStore.data as ICartStore)?.activeBox;
                const boxes = (cartStore.data as ICartStore)?.cart?.boxes;
                const activeBox = boxes && boxes[activeBoxIndex];

                items = [boxToItem(activeBox)];
            } else {
                items = Object.values(cart.boxes)
                                .filter(box => !box.incomplete)
                                .map(box => boxToItem(box));
            }

            properties = properties || {};

            if (withShipping && checkout && checkout.shippingMethod && !properties['shipping_tier']) {
                properties['shipping_tier'] = checkout.shippingMethod;
            }

            const payload: IGTMPayload = {
                event: event,
                ecommerce: {
                    items: items,
                    currency: 'USD',
                    value: items.reduce((sum, item) => sum + item.price - (item.discount || 0) , 0),
                    coupon: checkout && checkout.coupons && checkout.coupons.length
                                ? checkout.coupons.map(c => c.code).join('|')
                                : null,
                    ...(properties || {}),
                }
            };

            if (userProps) {
                payload.user_properties = {};

                Object.keys(userProps as { [key: string]: any })
                        .forEach((key: string) => payload.user_properties[key] = { value: userProps[key] });
            }

            if (user && user.gaClientId) {
                payload.client_id = user.gaClientId;
            }

            if (cartDiscount) {
                const couponDiscount = Number((cartDiscount / payload.ecommerce.items.length).toFixed(4));

                payload.ecommerce.items = payload.ecommerce.items.map(item => {
                    return {
                        ...item,
                        discount: item.discount + couponDiscount,
                    }
                });
            }

            if (payload.ecommerce.coupon === '') {
                delete payload.ecommerce.coupon;
            }

            // Enhanced Ecommerce GUA
            switch (event) {
                case 'purchase_rebill':
                case 'purchase':
                    payload.gua = {
                        ecommerce: {
                            purchase: {
                                actionField: {
                                    id: payload.ecommerce.transaction_id,
                                    revenue: payload.ecommerce.value,
                                    shipping: payload.ecommerce.shipping,
                                    coupon: payload.ecommerce.coupon || ''
                                },
                                products: payload.ecommerce.items.map(item => {
                                    return {
                                        id: item.item_id,
                                        name: item.item_name,
                                        category: item.item_category,
                                        price: item.price,
                                        quantity: item.quantity,
                                    }
                                }),
                            },
                        },
                    };

                    break;
                case 'view_item':
                    payload.gua = {
                        ecommerce: {
                            detail: {
                                products: payload.ecommerce.items.map(item => {
                                    return {
                                        id: item.item_id,
                                        name: item.item_name,
                                        category: item.item_category,
                                        price: item.price,
                                        quantity: item.quantity,
                                    }
                                })
                            }
                        }
                    }

                    break;
                case 'begin_checkout':
                    payload.gua = {
                        ecommerce: {
                            checkout: {
                                actionField: {
                                    step: 1
                                },
                                products: payload.ecommerce.items.map(item => {
                                    return {
                                        id: item.item_id,
                                        name: item.item_name,
                                        category: item.item_category,
                                        price: item.price,
                                        quantity: item.quantity,
                                    }
                                })
                            }
                        }
                    };

                    break;
            }

            if (payload.gua) {
                track(`GTM ${event}`, payload);
            }

            if (window.debugGTM) {
                console.info('GTM Debug: ' + event, payload)
            }

            gtmDispatch(payload);
        } catch (e) {
            console.error(`Error while sending GTM ${event}:`, {
                cart: cart,
                properties: properties,
                error: e,
            });
        }
    };
}

export function useGTMItemDispatch() {
    const gtmDispatch = useGTMDispatch();
    const user = useUser();

    return (event: string, product: IProduct|IProduct[], properties?: { [key: string]: any }) => {
        try {
            const payload: IGTMPayload = {
                event: event,
                ecommerce: {
                    currency: 'USD',
                    items: Array.isArray(product) ? product.map((p: IProduct) => productToItem(p)) : [productToItem(product)],
                    ...(properties || {}),
                }
            };

            if (user && user.gaClientId) {
                payload.client_id = user.gaClientId;
            }

            // Enhanced Ecommerce GUA
            switch (event) {
                case 'view_item':
                    payload.gua = {
                        ecommerce: {
                            detail: {
                                products: payload.ecommerce.items.map(item => {
                                    return {
                                        id: item.item_id,
                                        name: item.item_name,
                                        category: item.item_category,
                                        price: item.price,
                                        quantity: item.quantity,
                                    }
                                })
                            }
                        }
                    }

                    break;
            }

            if (payload.gua) {
                track(`GTM ${event}`, payload);
            }

            if (window.debugGTM) {
                console.info('GTM Debug: ' + event, payload)
            }

            gtmDispatch(payload);
        } catch (e) {
            console.error(`Error while sending GTM ${event}:`, {
                product: product,
                properties: properties,
                error: e,
            });
        }
    };
}
