import { css } from '@emotion/css'

export default function Clickable({ children, className, onClick, ariaLabel, style } :
    { children?: any, className?: string, onClick?: Function, ariaLabel?: string, style?: any}
) {
    return (
        <span
            aria-label={ariaLabel ? ariaLabel : ''}
            className={`${css({cursor: 'pointer'})} ${className ? className : ''}`}
            style={style ? style : {}}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => onClick ? eventAction(e, onClick) : {}}
            onClick={(e) => onClick ? eventAction(e, onClick) : {}}>
            {children}
        </span>
    );
}

export const eventAction = (e: any, action: Function) => {
    if (e.type === 'click' || (e.type === 'keydown' && (e.key === 'Space' || e.key === 'Enter'))) {
        action(e);
    }
}