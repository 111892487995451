import { useDispatch } from 'react-redux';

import { IModalSettings } from '@components/common/presentational/modal/interfaces';
import { hideGeneralModal, showGeneralModal } from '../store/rtk.modals.store';

export function useModal(modalSettings: IModalSettings) {
    const dispatch = useDispatch();

    const updatedModalSettings: IModalSettings = {
        id: Math.random().toString(36).slice(2),
        ...modalSettings,
        content: {
            ...modalSettings.content,
            props: {
                hide: () => {
                    close();

                    if (modalSettings.onHide) {
                        modalSettings.onHide();
                    }
                },
                ...(modalSettings.content.props || {})
            }
        }
    };

    const show = () => dispatch(showGeneralModal(updatedModalSettings));
    const close = () => dispatch(hideGeneralModal(updatedModalSettings.id));

    return [show, close];
}
