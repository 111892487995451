import { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

import { emptyCart } from '@app/store/rtk.cart.store';

import { reset } from '@v2/services/analytics.service';
import { store } from '@v2/store';
import { logout } from '@v2/store/rtk.user.store';
import { emptyForm, resetForms } from '@v2/store/rtk.form.store';
import { emptyGlobals, setEntities } from '@v2/store/rtk.entities.store';
import { resetSubscription } from '@v2/store/rtk.subscription.store';
import { resetCheckout } from '@v2/store/rtk.checkout.store';

// move it out in an actions file togethet with the login logic
export function logoutUser() {
    const dispatch = (window as any).store.dispatch;

    // I can't have you empty my cart if you make a 401 when I'm not logged in
    const jwt = store.getState().user?.jwt?.token;

    if (jwt) {
        reset();
        dispatch(emptyCart());

        // Reset GiftCard checkout
        dispatch(emptyForm('checkout-gift'));
        localStorage.removeItem('checkout-gift');
        localStorage.removeItem('cb-transition-modal');
        localStorage.removeItem('cb-orders-tab');
        localStorage.removeItem('cerebelly-lastlogin');
        localStorage.removeItem('cb-pricing-notified');
        // localStorage.removeItem('cb-pricing-notified-nov-24');
    }

    dispatch(resetForms());
    dispatch(resetSubscription());
    dispatch(resetCheckout());
    dispatch(setEntities({ globals: emptyGlobals }));
    dispatch(setEntities({ orders: null }));
    dispatch(logout());
}

export default function Logout() {
    useEffect(() => {
        logoutUser();
    }, []);

    return (
        <Redirect to={{ pathname: "/" }} />
    );
}