import { ILoginOutcome } from '@components/common/presentational/header/modals/login';
import { IAddress, ICard, IOrder, ISettings, IUser, IUserNotifications } from '@interfaces';
import { apiGet, apiPut, apiPost } from '@utils/http';
import { ILoginCredentials } from '@v2/components/common/presentational/header/modals/login';
import { IUserForm } from '@v2/components/pages/settings/interfaces';
import Cookies from 'js-cookie';

interface ILoginResponse {
    token?: string;
    message?: string;
    user_email?: string;
    user: {
        id: number;
    },
    settings: ISettings;
}

interface IDashboardRepsonse {
    order: IOrder;
}

export default class ProfileService {
    static login(credentials: ILoginCredentials): Promise<ILoginResponse> {
        return apiPost('@auth/token', credentials)
                .then(response => (response as any) as ILoginResponse);
    }

    static dashboard(): Promise<IDashboardRepsonse> {
        return apiGet('profile/dashboard')
                    .then(response => (response as any) as IDashboardRepsonse);
    }

    static globals(): Promise<Response> {
        return apiGet('profile/globals');
    }

    static settings(): Promise<IUser> {
        return apiGet('profile/settings')
            .then((response: any) => response as IUser);
    }

    static shippingBilling(): Promise<Response> {
        return apiGet('user/shipping-billing');
    }

    static updateAccount(data: IUserForm): Promise<Response> {
        return apiPut('profile/update', data);
    }

    static updatePassword(current: string, pass: string, repass: string): Promise<Response> {
        return apiPost('user/change-password', {
            cur_password: current,
            password_1: pass,
            password_2: repass,
        });
    }


    static updatePhone(phone: string) {
        return apiPost('profile/update', {phone: phone});
    }

    static updateSMSNotification(sms: boolean) {
        return apiPost('profile/update-sms-meta', {sms: sms});
    }

    static updateCommunicationPreferences(notifications: IUserNotifications): Promise<Response> {
        return apiPut('profile/update-communication-preferences', notifications);
    }

    static addCard(stripeSourceId: string): Promise<Response> {
        return apiPost('cards/add', { stripe_source: stripeSourceId });
    }

    // This is used for removing or making deafult card by id, and it uses the old api...
    static editCard(card: ICard, action: string): Promise<Response> {
        return apiPost('cards/edit', {
            'source_id': card.id,
            'event': action
        });
    }

    static newPassword(key: string, login: string, pass1: string, pass2: string): Promise<Response> {
        return apiPost('user/new-password', {
            'key': key,
            'login': login,
            'pass1': pass1,
            'pass2': pass2,
        })
        .then((response: any) => response as any);
    }


    static resetPassword(email: string): Promise<Response> {
        return apiPost('user/reset-password', {
            'email': email,
        })
        .then((response: any) => response as any);
    }

    static addAddress(address: IAddress, entity: string): Promise<Response> {
        return apiPost('profile/update-address', {
            event: 'add',
            type: entity,
            address: {...address, state: address?.state?.value || null},
        });
    }

    static editAddress (address: IAddress, entity: string, id: number): Promise<Response> {
        return apiPost('profile/update-address', {
            index: id,
            event: 'edit',
            type: entity,
            address: {...address, state: address?.state?.value || null},
        });
    }

    static setDefaultAddress(entity: string, id: number): Promise<Response> {
        return apiPost('profile/update-address', {
            index: id,
            type: entity,
            event: 'set_default',
        });
    }

    static deleteAddress(entity: string, id: number): Promise<Response> {
        return apiPost('profile/update-address', {
            index: id,
            type: entity,
            event: 'delete',
        });
    }

    static updateGorgiasCustomer = async () => {
        return apiPost('profile/update-gorgias-customer', {});
    }

    static handleLogin(creds: any): Promise<ILoginOutcome> {
        return ProfileService.login(creds)
            .then(async (response) => {
                const data: ILoginOutcome = {
                    success: false,
                    message: null,
                    payload: {
                        user: {},
                        token: null,
                        user_email: null,
                        settings: null
                    }
                };

                if (response.token) {
                    data.success = true;

                    data.payload.user = response.user;
                    data.payload.user_email = response.user_email;
                    data.payload.token = response.token;
                    data.payload.settings = response.settings;

                    localStorage.removeItem('cb_auto_coupon_applied');

                    Cookies.remove('cb-oos-modal');
                } else {
                    data.message = response.message;
                }

                return data;
            })
            .catch(async (response) => {
                response = response.json ? await response.json() : response;

                return {
                    success: false,
                    message: response.message || 'An unexpected error occured. Please try again ',
                };
            });
    }
}