import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotification, IOrder } from '@v2/interfaces';
export interface IGlobals {
    children: { id: number, name: string }[],
    notifications: INotification[],
    badges: any[],
    orders: IOrder[]
}

export const emptyGlobals: IGlobals = { children: [], notifications: [], badges: [], orders: [] };

interface EntitiesState {
    entities: { [key: string]: any };
}

const initialState: EntitiesState = {
    entities: {
        globals: emptyGlobals,
    },
}

const isPropertyUndefinedOrNull = (item: any, key: string) => item.hasOwnProperty(key) && (item[key] === null || item[key] === undefined);

// Filter products with according rules
const filterEntities = (entity: any) => {
    if(Array.isArray(entity)) {
        return entity.filter((item: any) => {
            // Prevents saving items with null or undefined price;
            if(isPropertyUndefinedOrNull(item, 'price')) {
                return false;
            }
            // Prevents saving items with null or undefined priceSubscribed;
            if(isPropertyUndefinedOrNull(item, 'priceSubscribed')) {
                return false;
            }
            return true;
        })
    }
    return entity;
}

const slice = createSlice({
    name: 'entities',
    initialState,
    reducers: {
        setEntities: (state: EntitiesState, action: PayloadAction<{ [key: string]: any }>): void => {
            Object.keys(action.payload).forEach((key) => {
                state.entities[key] = filterEntities(action.payload[key]);
            });
        },
    },
});

const entitiesReducer = slice.reducer;

export const { setEntities } = slice.actions;

export default entitiesReducer;