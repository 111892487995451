import Clickable from '@v2/components/common/presentational/clickables/default';

import { useCartModal } from '@components/common/presentational/modal/common';
import { packAndTrack } from '@v2/services/analytics.service';

import iconCart from '@img/homepage2/cart.svg';
import { useGTMCartDispatch } from '@v2/utils/gtm';
import { useIsSwapping } from '@components/common/products/utils';
import { useCartWithMeta } from '@app/store/cart.selectors';
import { useEffect, useRef } from 'react';
import { useModalsSelector } from '@v2/store/rtk.modals.selectors';
import { useDispatch } from 'react-redux';
import { hideGeneralModal } from '@v2/store/rtk.modals.store';

export function CartButton() {
    const dispatch = useDispatch();
    const gtmDispatch = useGTMCartDispatch();
    const data = useCartWithMeta();
    const [showCartModal] = useCartModal();
    const isSwapping = useIsSwapping();
    const modals = useModalsSelector();

    const boxesCount = data && data.cart && data.cart.boxes
            ? Object.values(data.cart.boxes).filter(box => !box.incomplete).length
            : 0

    const ref = useRef(null);

    useEffect(() => {
        function handleClickOutside(e: any) {
            // @ts-ignore
            if (ref.current && !ref.current.contains(e.target)) {
                if(modals.find((modal) => modal.windowType === 'CART2')) {
                    dispatch(hideGeneralModal());
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [ref, modals]);

    return (
        <div ref={ref}>
            <Clickable onClick={() => {
                if (!isSwapping) {
                    showCartModal();
                }

                gtmDispatch('view_cart');

                packAndTrack(`Show Cart Modal Clicked`, [{'view': 'Header', 'section': 'Shop Icon'}]);
            }}
                className={`mobile-cart-box ${boxesCount > 0 ? 'active' : ''}`}>
                <img loading="lazy" src={iconCart} alt={'Icon Cart'} width={21} height={23} />
            </Clickable>
        </div>
    );
}