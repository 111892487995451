import { store } from '@v2/store';
import { Route, Redirect } from 'react-router-dom';

// @ts-ignore
export default function PrivateRoute ({ component: Component, ...rest }) {
    const jwt = store.getState().user?.jwt?.token;

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!jwt) {
                    localStorage.setItem('openLoginForm', '1');
                }

                return jwt
                    ? <Component {...props} />
                    : <Redirect to={{pathname: '/', state: {from: props.location}}} />
            }}
        />
    );
}
