import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface FormState {
    data: { [key: string]: any };
}

const initialState: FormState = {
    data: {},
}

const slice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        resetForms: (state: FormState, payload: PayloadAction<void>): void => {
            state.data = {};
        },

        initForm: (state: FormState, payload: PayloadAction<{ formId: string, formData: Object }>): void => {
            const action = payload.payload;

            state.data[action.formId] = action.formData;
        },

        emptyForm: (state: FormState, payload: PayloadAction<string>): void =>{
            delete state.data[payload.payload];
        },

        updateFormPartial: (state: FormState, action: PayloadAction<{formId: string, data: { [key: string]: any }}>): void => {
            if (!state.data[action.payload.formId]) {
                state.data[action.payload.formId] = {};
            }

            Object.keys(action.payload.data).forEach((key: string) => {
                let target = state.data[action.payload.formId];

                key.split('.').forEach((keyPart: string, index: number) => {
                    if (index === key.split('.').length - 1) {
                        (target as any)[keyPart] = action.payload.data[key];
                    } else {
                        target = (target as any)[keyPart] || {};
                    }
                });
            });
        },
    },
});

const formReducer = slice.reducer;

export const { resetForms, initForm, emptyForm, updateFormPartial } = slice.actions;

export default formReducer;