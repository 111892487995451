import { useSelector } from 'react-redux';

import { ICheckout, IUserCheckout } from '@v2/interfaces';
import { emptyCheckoutOrder, emptyCheckoutUser } from './rtk.checkout.store';
import { RootState } from '@v2/store';

export function useCheckoutData(): ICheckout {
    return useSelector((state: RootState) => state.checkout.order || emptyCheckoutOrder);
}

export function useCheckoutUser(): IUserCheckout {
    return useSelector((state: RootState) => state.checkout.user || emptyCheckoutUser);
}

export function useIsCheckoutLoading(): boolean {
    return useSelector((state: RootState) => state.checkout.loading);
}