import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAnnouncement, INutrient } from "@v2/interfaces";
import { BlogPost } from "@v2/services/blog.service";
import { NutrientPost } from "@v2/services/nutrient.service";

interface INutrientRandom extends INutrient {
    sort: number
}

interface ForYouState {
    announcements: {
        top?: IAnnouncement[],
        bottom?: IAnnouncement[]
    },
    nutrients: NutrientPost[],
    blogPosts: BlogPost[],
    instagramPosts: any,
    referral: any
}

const initialState: ForYouState = {
    announcements: {},
    nutrients: [],
    blogPosts: [],
    referral: [],
    instagramPosts: [],
}

const slice = createSlice({
    name: 'for-you',
    initialState,
    reducers: {
        setForYouNutrients: (state: ForYouState, action: PayloadAction<INutrient[]>): void => {
            state.nutrients = action.payload.map((nutrient: INutrientRandom) => {
                nutrient.sort = Math.random();
                return NutrientPost.fromNutrientObject(nutrient);
            }).sort((a: any, b: any) => a.sort - b.sort);
        },
        setForYouPosts: (state: ForYouState, action: PayloadAction<BlogPost[]>): void => {
            state.blogPosts = action.payload;
        },
        setForYouAnnouncements: (state: ForYouState, action: PayloadAction<IAnnouncement[]>): void => {
            state.announcements = {
                top: action.payload.filter(announcement => announcement.showOnTop),
                bottom: action.payload.filter(announcement => !announcement.showOnTop)
            }
        },
        setForYourRferral: (state: ForYouState, action: PayloadAction<any[]>): void => {
            state.referral = action.payload;
        },
        setInstagramPosts: (state: ForYouState, action: PayloadAction<any[]>): void => {
            state.instagramPosts = action.payload
        },
    },
});

const forYouReducer = slice.reducer;

export const { setForYouAnnouncements, setForYouNutrients, setForYouPosts, setForYourRferral, setInstagramPosts } = slice.actions;

export default forYouReducer;
