import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IAddress, ICheckout, IUserCheckout } from '@v2/interfaces';

const emptyAddress = {
    firstName: '',
    lastName: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postcode: '',
} as IAddress;

export const emptyCheckoutUser = {
    email: '',
    password: '',
    phone: '',
    smsEnabled: false,
    smsNewsEnabled: false,
    billingAsShipping: true,
    shippingAddress: emptyAddress,
    billingAddress: emptyAddress,
} as IUserCheckout;

// export const paymentMethods = ['stripe', 'paypal', 'venmo'];
export const paymentMethods = ['stripe', 'paypal'];

export const emptyCheckoutOrder = {
    cartBoxes: {},
    cartBoxesErrors: [],
    coupons: [],
    shippingOptions: false,
    shippingMethod: 'Standard',
    paymentMethod: paymentMethods[0],
    shippingNext: null,
    messages: [],
    newDiscountCode: null,
    newDiscountResult: false,
    discount: 0,
    shipping: 0,
    shippingPrepaid: 0,
    taxes: 0,
    total: 0,
} as ICheckout;

interface CheckoutState {
    order: ICheckout;
    user: IUserCheckout;
    loading: boolean;
}

const initialState: CheckoutState = {
    order: emptyCheckoutOrder,
    user: emptyCheckoutUser,
    loading: true,
}

const slice = createSlice({
    name: 'checkout',
    initialState,
    reducers: {
        resetCheckout: (state: CheckoutState, action: PayloadAction<void>): void => {
            state.order = initialState.order;
            state.user = initialState.user;
        },

        resetCheckoutOrder: (state: CheckoutState, action: PayloadAction<void>): void => {
            state.order = initialState.order;
        },

        resetCheckoutUser: (state: CheckoutState, action: PayloadAction<void>): void => {
            state.user = initialState.user;
        },

        setCheckoutUserPartial: (state: CheckoutState, action: PayloadAction<{ [key: string]: any }>): void => {
            if (!state.user) {
                state.user = Object.assign({}, emptyCheckoutUser);
            }

            if(action?.payload?.phone?.startsWith?.("+1")) {
                action.payload.phone = action.payload.phone.substr(2);
            }

            Object.keys(action.payload).forEach((key: string) => {
                let target = state.user;

                key.split('.').forEach((keyPart: string, index: number) => {
                    if (index === key.split('.').length - 1) {
                        (target as any)[keyPart] = action.payload[key];
                    } else {
                        target = (target as any)[keyPart];
                    }
                });
            });
        },

        setCheckoutLoading(state: CheckoutState, action: PayloadAction<boolean>): void {
            state.loading = action.payload;
        },

        setCheckoutData: (state: CheckoutState, action: PayloadAction<ICheckout>): void => {
            state.order = action.payload;
        },

        setCheckoutDataPartial: (state: CheckoutState, action: PayloadAction<{ [key: string]: any }>): void => {
            if (!state.order) {
                state.order = Object.assign({}, emptyCheckoutOrder);
            }

            Object.keys(action.payload).forEach((key: string) => {
                (state.order as any)[key] = action.payload[key];
            });
        },
        setCheckoutUser: (state: CheckoutState, action: PayloadAction<IUserCheckout>): void => {
            state.user = action.payload;
        },
    },
});

const checkoutReducer = slice.reducer;

export const {
    resetCheckout,
    setCheckoutLoading,
    setCheckoutData,
    setCheckoutDataPartial,
    setCheckoutUser,
    setCheckoutUserPartial
} = slice.actions;

export default checkoutReducer;