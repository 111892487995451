import { IModalState } from '@components/common/presentational/modal/interfaces';
import { css } from '@emotion/css'
import colors from '@styles/colors';
import { mobileOnly, tabletPortraitUp, tabletLandscapeUp, desktopUp, bigDesktopUp } from '@styles/breakpoints';

// Sizes (width):
// name     < 600   600-900   900-1280   120-1800   > 1800
// mini      35%      340       360         380       420
// small     52%      510       540         570       600
// medium    70%      680       720         760       800
// large     88%      1020      1080        1140      1200

// TODO: [v2] - find an appropriate place for these styles when the components
// structure gets revisited

const defaultZIndex = 1000;
const mobileOnlyWidths = {
    FULL: { MINI: '100%', SMALL: '100%', MEDIUM: '100%', LARGE: '100%', CART: '100%', PDETAILS: '100%', SHOP: '100%', SWAP: '100%', CART2: '100%', VIDEO: '100%', SHIP: '100%' },
    TALL: { MINI: '90%', SMALL: '90%', MEDIUM: '90%', LARGE: '90%', CART: '100%', PDETAILS: '100%', SHOP: '100%', SWAP: '100%', CART2: '100%', VIDEO: '100%', SHIP: '100%' },
    DEFAULT: { MINI: '90%', SMALL: '90%', MEDIUM: '90%', LARGE: '90%', CART: '100%', PDETAILS: '100%', SHOP: '100%', SWAP: '100%', CART2: '100%', VIDEO: '100%', SHIP: '100%' },
};
const tabletPortraitUpWidths = {
    FULL: { MINI:  340,  SMALL:  510,  MEDIUM:  580,  LARGE:  1020, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
    TALL: { MINI:  340,  SMALL:  510,  MEDIUM:  580,  LARGE:  1020, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
    DEFAULT: { MINI:  340,  SMALL:  510,  MEDIUM:  580,  LARGE:  1020, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
};
const tabletLandscapeUpWidths = {
    FULL: { MINI:  360,  SMALL:  540,  MEDIUM:  720,  LARGE:  1080, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
    TALL: { MINI:  360,  SMALL:  540,  MEDIUM:  720,  LARGE:  1080, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
    DEFAULT: { MINI:  360,  SMALL:  540,  MEDIUM:  720,  LARGE:  1080, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
};
const desktopUpWidths = {
    FULL: { MINI:  380,  SMALL:  570,  MEDIUM:  760,  LARGE:  1140, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
    TALL: { MINI:  380,  SMALL:  570,  MEDIUM:  760,  LARGE:  1140, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
    DEFAULT: { MINI:  380,  SMALL:  570,  MEDIUM:  760,  LARGE:  1140, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
};
const bigDesktopUpWidths = {
    FULL: { MINI:  400,  SMALL:  600,  MEDIUM:  800,  LARGE:  1200, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
    TALL: { MINI:  400,  SMALL:  600,  MEDIUM:  800,  LARGE:  1200, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
    DEFAULT: { MINI:  400,  SMALL:  600,  MEDIUM:  800,  LARGE:  1200, CART: 477, PDETAILS: 1014, SHOP: '100%', SWAP: '100%', CART2: 429, VIDEO: '100%', SHIP: '100%' },
};


export function modalOverlayCSS({ windowType, alignType, heightType, position, scroll, blurBackground }: IModalState) {
    return css([{
        zIndex: position ? position + defaultZIndex : defaultZIndex,
        position: 'fixed',
        left: 0,
        top: 0,
        width: windowType === 'CART2' ? 'auto' : '100%',
        height: windowType === 'CART2' ? 'auto' : '100%',
        backgroundColor: (windowType === 'CART2' || blurBackground) ? 'none' : 'rgb(213 217 248 / 0.95)',
        backdropFilter: blurBackground ? 'blur(3px)' : '',
    }]);
}

export function modalCSS({ windowType, alignType, heightType, position, scroll }: IModalState) {
    const responsiveMatrixHeightType = mobileOnlyWidths[heightType] ? heightType : 'DEFAULT';
    const hasBar = (document.getElementById('oos_bar') || document.getElementById('promo_bar')) ? true : false;

    return css([{
        // make sure that the last modals added are on top
        zIndex: position ? position + defaultZIndex : defaultZIndex,
        position: 'fixed',
        left: 0,
        top: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        overflow: 'auto',

        '& > .main': [{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            outline: 'none',
            backgroundColor: colors.WHITE,
            borderRadius: 5,

            [mobileOnly]: {
                width: mobileOnlyWidths[responsiveMatrixHeightType][windowType],
                maxHeight: '100%',
            },
            [tabletPortraitUp]: {
                width: tabletPortraitUpWidths[responsiveMatrixHeightType][windowType],
                borderRadius: 5,
                maxHeight: '100%',
            },
            [tabletLandscapeUp]: {
                width: tabletLandscapeUpWidths[responsiveMatrixHeightType][windowType],
                borderRadius: 10,
            },
            [desktopUp]: {
                width: desktopUpWidths[responsiveMatrixHeightType][windowType]
            },
            [bigDesktopUp]: {
                width: bigDesktopUpWidths[responsiveMatrixHeightType][windowType]
            },

            '&:focus': {
                outline: 'none',
            },

            '.bg-beige': {
                backgroundColor: '#F8F5ED',
            },

            '.confirmation-screen': {
                position: 'fixed',
                overflow: 'hidden',
                top: 0,
                left: 0,
                display: 'flex',
                backgroundColor: 'rgb(213 217 248 / 0.95)',
                zIndex: 999,
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignContent: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                paddingLeft: 15,
                paddingRight: 15,

                '.wrapper': {
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: colors.WHITE,
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingBottom: 40,
                    paddingTop: 68,
                    maxWidth: 450,
                    width: '100%',
                    height: 'auto',
                    borderRadius: 5,

                    '.default-close':{
                        position: 'absolute',
                    }
                },

                h3: {
                    fontStyle: 'normal',
                    fontWeight: 800,
                    fontSize: 22,
                    color: colors.NAVY,
                    marginBottom: 40,
                    marginTop: 0,
                    textAlign: 'center',
                    width: '100%',
                    maxWidth: 330,
                },
                p: {
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: 15,
                    color: colors.NAVY,
                    marginBottom: 40,
                    marginTop: 0,
                    maxWidth: 300,
                    width: '100%',
                    textAlign: 'center',
                },
                button: {
                    maxWidth: '202px !important',
                    width: '100% !important',
                    fontWeight: 'bold',
                    fontSize: 13,
                    letterSpacing: '0.2em',

                    '&.primary': {
                        marginBottom: 15,
                    },
                    '&.secondary': {
                        color: colors.BLUE,
                        borderColor: colors.BLUE
                    }
                }
            },
        },
        heightType === 'FULL' ? {
            height: '100%',
            overflowX: 'auto',
            borderRadius: '0px !important',
        } : {},
        {
        }],

        '.controls': {
            '.default-close': {
                position: 'absolute',
                right: 24,
                top: 24,
                cursor: 'pointer',
                zIndex: 100,
                marginRight: -7,
                marginTop: -7,
                padding: 7,

                webkitUserSelect: 'none',
                mozUserSelect: 'none',
                msUserSelect: 'none',
                userSelect: 'none',
            },

            '.outside-close': {
                right: 0,
                top: -36,
                [tabletLandscapeUp]: {
                    right: 24,
                    top: 24,
                }
            },

            '&.shop-modal': {
                '.default-close': {
                    top: 20,

                    [desktopUp]: {
                        top: 84,
                    },
                    [mobileOnly]: {
                        top: 15
                    },
                },
            },

            '&.ship-modal': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                width: '100%',
                borderRadius: '10px 10px 0 0',
                fontSize: 12,
                fontWeight: 800,
                letterSpacing: '0.2em',

                '.controls-wrapper': {
                    width: '100%',
                    margin: '20px 24px',
                    paddingBottom: 15,
                    borderBottom: '1px solid',
                    borderColor: colors.BLUE_EXTRA_LIGHT,
                },

                '.modal-heading': {
                  display: 'flex',
                  alignItems: 'center',
                  textTransform: 'uppercase'
                }
            },

            '&.swap-modal': {
                display: 'flex',
                postion: 'fixed',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: 60,
                width: '100%',
                position: 'absolute',
                backgroundColor: 'rgba(235, 238, 246, 1)',
                zIndex: 9,
                background: colors.BLUE,
                top: 49,
                borderRadius: '10px 10px 0 0',

                '.modal-heading': {
                  display: 'flex',
                  alignItems: 'center',

                  '.action': {
                        padding: '5px 20px',
                  },

                  '.counter': {
                    background: '#fc4d38',
                    fontSize: 14,
                    textAlign: 'center',
                    verticalAlign: 'middle',
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                    position: 'absolute',
                    lineHeight: '18px',
                    top: 20,
                    left: 30,
                    color: 'white',
                  },

                  '.title': {
                    padding: '5px 20px',
                    color: colors.WHITE,
                    textTransform: 'uppercase',
                    fontSize: 12,
                    fontWeight: 800,
                    letterSpacing: 3
                  }
                },

                '.default-close': {
                    top: 0,
                    left: 0,
                    position: 'relative',
                    height: '100%',
                    margin: 20,
                },


                [mobileOnly]: {
                    top: 15,
                },
            }
        },
    },
    windowType === 'PDETAILS' ? {
        '& > .main': {
            overflow: 'auto',
            minWidth: 240,
            width: '100%',
            height: '100%',
            position: 'absolute',
            top: 0,
            borderRadius: 0,
            margin: 0,
            backgroundColor: 'unset',
        }
    } : {},
    windowType === 'CART' ? {
        '& > .main': {
            borderRadius: '0px !important',
        },

        '.custom': {
            height: '100%',

            '.default-close': {
                top: 20,
                right: 20,
            },

            '.content': {
                height: '100%',

                '& > div': {
                    height: '100%',

                    '& > .container': {
                        height: '100%',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },
    } : {},
    windowType === 'SHOP' ? {
        paddingTop: 60,

        [mobileOnly]: {
            paddingTop: 50,
        },

        '& > .main': {
            borderRadius: '15px 15px 0 0 !important',
        },

        '.custom': {
            height: '100%',

            '.default-close': {
                top: 20,
                right: 20,
            },

            '.content': {
                height: '100%',

                '& > div': {
                    height: '100%',
                    '> div': {
                        height: '100%',
                        '> div': {
                            height: '100%',
                            '> div': {
                                height: '100%',
                            },
                        },
                    },

                    '& > .container': {
                        height: '100%',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },
    } : {},

    windowType === 'CART2' ? {
        alignItems: 'flex-start',
        backgroundColor: 'none !important',
        width: 'auto !important',
        height: 'auto !important',
        minWidth: 465,
        left: 'unset',
        right: 20,
        top: hasBar ? 100 : 50,
        borderRadius: 5,
        bottom: 0,
        padding: 5,
        margin: -5,
        marginTop: -35,

        '& > .main': {
            width: '100%',
            maxHeight: 608,
            [mobileOnly]: {
                marginRight: 0,
            }
        },

        '.custom': {
            height: '100%',
            backgroundColor: colors.WHITE,
            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.12)',
            borderRadius: 5,

            '.default-close': {
                top: 20,
                right: 20,
            },

            '.content': {
                height: '100%',

                '& > div': {
                    height: '100%',

                    '& > .container': {
                        height: '100%',
                        paddingLeft: 0,
                        paddingRight: 0,
                    },
                },
            },
        },
        [mobileOnly]: {
            maxWidth: 'calc(100vw - 40px)',
            minWidth: 'calc(100vw - 40px)'
        }
    } : {},
    windowType === 'VIDEO' ? {
        '& > .main': {
            width: '100% !important',
            height: '100% !important',
            background: 'none !important'
        }
    } : {},
    alignType === 'LEFT' ? { justifyContent: 'flex-start' } : {},
    alignType === 'RIGHT' ? { justifyContent: 'flex-end' } : {},
    alignType === 'BOTTOM' ? {
        justifyContent: 'center',
        alignItems: 'flex-end',

        '& > .main': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            alignContent: 'center',
            height: 'calc(100vh - 100px)',
            borderRadius: '20px 20px 0 0',
        },

        [desktopUp]: {
            '& > .main': {
                maxWidth: '1360px !important',
                width: '100% !important',
                height: 'calc(100vh - 200px)',
            }
        },
        [mobileOnly]: {
            '& > .main': {
                padding: '50px 20px',
                height: 'auto',
            }
        },
    } : {},
    alignType === 'CENTER' ? [
        {
            justifyContent: 'center',
        },
        heightType === 'TALL' ? {
            '& > .main': {
                borderRadius: 5,

                '& > .custom': {
                    borderRadius: 5,
                },

                '& > .custom > .content': {
                    padding: '60px 20px 70px 20px',
                    maxHeight: scroll === false ? '100%' : '65vh',
                    overflowY: scroll === false ? 'unset' : 'scroll',
                    overflowX: scroll === false ? 'unset' : 'hidden',

                    '.container': {
                        maxWidth: 438,
                        width: '100% !important'
                    },

                    '&::-webkit-scrollbar': {
                        width: 4,
                    },

                    '&::-webkit-scrollbar-track': {
                        background: 'rgb(255 255 255 / 0%)',
                    },

                    '&::-webkit-scrollbar-thumb': {
                        background: colors.BLUE_LIGHTER,
                        borderRadius: 10,
                    },

                    '&::-webkit-scrollbar-thumb:hover': {
                        background: colors.BLUE,
                    },

                    'img[src*="Nutrition-Facts"]': {
                        maxWidth: 300,
                        margin: '0 auto',
                        display: 'block',
                    },
                },

                [tabletPortraitUp]: {
                    '& > .custom > .content': {
                        padding: '60px 80px 70px 80px',
                    },

                    '& > .custom > .header': {
                        paddingLeft: 95,
                        paddingRight: 95,
                    },
                },

                [tabletLandscapeUp]: {
                    '& > .custom > .content': {
                        padding: '60px 155px 70px 155px',
                    },
                },
            },
        } : {},
    ] : {},]);
}
