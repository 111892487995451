import { css } from '@emotion/css'
import colors from '@styles/colors';
import { mobileOnly, desktopUp } from '@styles/breakpoints';

export default css({
    footer: {
        padding: '80px 40px 140px 40px',
        backgroundColor: colors.FOOTER_PINK,

        [mobileOnly]: {
            padding: '80px 0 80px 0',
        },

        h3: {
            color: colors.GREEN_DEEP_JUNGLE,
            fontStyle: 'normal',
            fontWeight: 800,
            fontSize: 17,
            letterSpacing: 3,
            textTransform: 'uppercase',

            [mobileOnly]: {
                fontSize: 18,
            },
        },

        a: {
            textDecoration: 'none',

            '&:hover': {
                textDecoration: 'none',
            }
        },

        '.footer-main': {
            textAlign: 'left',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignContent: 'center',

            [mobileOnly]: {
                flexDirection: 'column',
                alignItems: 'center',
            },

            '.left': {
                width: '100%',

                '.nav': {
                    display: 'flex',
                    flexWrap: 'wrap',

                    [mobileOnly]: {
                        flexWrap: 'wrap',
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        alignContent: 'center',
                        flexDirection: 'column',
                        marginBottom: 40,
                    },

                    '.column': {
                        marginBottom: 30,
                        marginRight: 25,
                        maxWidth: '100%',
                        width: 'auto',

                        [desktopUp]: {
                            maxWidth: 260,
                            width: '100%',
                        },

                        [mobileOnly]: {
                            marginBottom: 50,
                            marginRight: 0,
                            maxWidth: '100%',
                            width: '100%',
                        },

                        div: {
                            textAlign: 'left',
                            marginTop: 10,

                            [mobileOnly]: {
                                textAlign: 'center',
                                marginTop: 18,
                            },

                            a: {
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: 16,
                                textTransform: 'lowercase',

                                [mobileOnly]: {
                                    fontSize: 18,
                                },
                            }
                        }
                    }
                }
            },

            '.right': {
                fontSize: 14,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                alignContent: 'flex-start',

                [mobileOnly]: {
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignContent: 'center',
                    flexDirection: 'column',
                },

                '.social': {
                    '.link': {
                        marginRight: 15,
                        img: {
                            height: 32,
                            width: 32,
                        }
                    }
                },
                '.logo-link': {
                    marginTop: 48,
                },
                '.copy': {
                    marginTop: 10,
                }
            },
        },
        '.footer-bottom': {
            marginTop: 75,
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',

            [mobileOnly]: {
                marginTop: 40,
                flexDirection: 'column',
            },

            'a, span': {
                margin: '0 20px',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: 13,

                [mobileOnly]: {
                    marginBottom: 20,
                },

                '&:hover': {
                    cursor: 'pointer'
                }
            }
        },
        '.desktop': {
            [mobileOnly]: {
                display: 'none'
            },
        },
        '.mobile': {
            display: 'none',

            [mobileOnly]: {
                display: 'block'
            },
        }
    }
});