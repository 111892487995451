import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ShopState {
    veganOnly: boolean;
}

export interface IShop {
    veganOnly: boolean;
}

const initialState: ShopState = {
    veganOnly: false
}

const slice = createSlice({
    name: 'shop',
    initialState,
    reducers: {
        setVeganOnly: (state: ShopState, action: PayloadAction<boolean>): void => {
            state.veganOnly = action.payload;
        },
    },
});

const shopReducer = slice.reducer;

export const { setVeganOnly } = slice.actions;

export default shopReducer;