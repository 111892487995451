import { useShopModals } from "@v2/store/rtk.modals.selectors";
import { useEffect, useRef } from "react";

export const useAccessibility = () => {
    const isFocusable = useRef(true);
    const shopModals = useShopModals();

    useEffect(() => {
        const header = document.getElementById('header-section');
        const content = document.getElementById('content-section');
        const footer = document.getElementById('footer-section');
        if(shopModals.length > 0 && isFocusable.current) {
            if(header) {
                header.style.visibility = 'hidden';
            }
            if(content) {
                content.style.visibility = 'hidden';
            }
            if(footer) {
                footer.style.visibility = 'hidden';
            }
            isFocusable.current = false;
        } else if(shopModals.length === 0 && !isFocusable.current) {
            if(header) {
                header.style.visibility = 'visible';
            }
            if(content) {
                content.style.visibility = 'visible';
            }
            if(footer) {
                footer.style.visibility = 'visible';
            }
            isFocusable.current = true;
        }
    }, [shopModals])
}