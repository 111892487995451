import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import parse from 'html-react-parser';
import Cookies from 'js-cookie';

import SubscriptionService from '@v2/services/subscription.service';
import { LinkWithReferrer } from '@components/common/helpers/link';
import { useHistoryWithReferrer } from '@utils/history';
import { useConfirmationModal } from '@components/common/presentational/modal/common';
import { hasOOSBar } from '@components/common/presentational/header/header';

import styles from '@components/common/presentational/oos/oos-bar.styles';
import { imgV2 as img } from '@styles/utils';
import { ISubscription } from '@v2/entities/subscription.entity';
import { setSubscription } from '@app/store/rtk.subscription.store';
import { useIsLogged } from '@app/store/rtk.user.selectors';
import { useSubscription } from '@v2/store/rtk.subscription.selectors';

function Content({ mainDocElement }: { mainDocElement: any }) {
    const history = useHistoryWithReferrer();
    const messages = [
        'You have an OOS product in your active subscription.',
        'Head to the My Subscription page to edit.'
    ];

    const [lineIndex, setLineIndex] = useState(0);

    const [showModal, hideModal] = useConfirmationModal({
        title: 'Oh no!',
        message: 'There was an out of stock item in your most recent order. Head to the <b>My Subscription page</b> to make changes to your active subscriptions.',
        confirm: {
            text: `<img src="${img('oos/edit.svg')}" alt=""/> Edit`,
            action: () => {
                hideModal();
                history.pushWithReferrer('/subscription');
            },
        },
        deny: {
            text: `I'd like to wait until it's back in stock.`,
            action: () => {
                hideModal();
            },
        },
        alignLeft: true,
        className: 'oos'
    });

    useEffect(() => {
        // mainDocElement.classList['add']('with-oos');

        if (!Cookies.get('cb-oos-modal')) {
            showModal();
            Cookies.set('cb-oos-modal', 'yes');
        }

        const timer = setInterval(() => {
            setLineIndex(prevIndex => prevIndex + 1 === messages.length ? 0 : prevIndex + 1);
        }, 5000);

        return () => clearInterval(timer);
    }, []);

    return (
        <div id="oos_bar" className={styles.oosBar}>
            <LinkWithReferrer className="wrapper" to={"/subscription"}>
                <img src={img('oos/warning.svg')} alt='' />
                {messages.map((message, index) =>
                    <p key={index} style={{ display: lineIndex === index ? 'flex' : 'none' }}
                        className={`animate__animated animate__slideInDown`}>
                        {parse(message)}
                    </p>
                )}
                <img src={img('oos/arrow-right.svg')} alt='' />
            </LinkWithReferrer>
        </div>
    );
}

export default function OOSBar() {
    const dispatch = useDispatch();
    const history = useHistoryWithReferrer();
    const isLogged = useIsLogged();
    const subscription = useSubscription();
    const showBar = hasOOSBar(isLogged, history.location.pathname, subscription);
    const boxes = subscription?.boxes || null;

    const mainDocElement = document.getElementsByClassName('e-main-container')[0];

    useEffect(() => {
        mainDocElement.classList[!showBar ? 'remove' : 'add']('with-oos');
    }, [showBar]);

    useEffect(() => {
        if (!boxes || !Object.keys(boxes).length || !isLogged || localStorage.getItem('cb_discontinuation_modal') === '1') {
            return;
        }
    }, [boxes]);

    useEffect(() => {
      const from = ((history.location.state && (history.location.state as any).from))
                    ? (history.location.state as any).from.pathname
                    : '';

      if (!isLogged || (history.location.state && from.includes("subscription"))) {
        return;
      } else if ((isLogged && subscription?.boxes && subscription?.status)) {
        return;
      }

      SubscriptionService.get().then((subscription: ISubscription) =>
        dispatch(setSubscription(subscription))
      );
    }, [isLogged]);

    return (
        showBar
            ? <Content mainDocElement={mainDocElement} />
            : <></>
    );
}
