import { useEffect, useState } from "react";
import { useScript } from '@components/common/hooks/script';
import YotpoService from "@v2/services/yotpo.service";
import { useUser } from "@v2/store/rtk.user.selectors";
import { setForYourRferral } from '@v2/store/rtk.for-you.store';
import { useDispatch } from "react-redux";

declare var swellAPI: any;

export default function YotpoInit() {
    const doc = (document as any);
    const dispatch = useDispatch();
    const user = useUser();
    const [active, setActive] = useState(false);

    const youtpoSDK = useScript(process.env.YOTPO_SDK);
    const yotpoWidget = useScript(process.env.YOTPO_WIDGET);

    const loaded = youtpoSDK === 'ready' && yotpoWidget === 'ready' && ((window?.swellConfig?.swellAccount) ? true : false);

    const loadDetails = async () => {
        let referralDetails = await swellAPI.getCustomerDetails();

        if ((!referralDetails || !referralDetails.email) && user && user.email && user.id) {
            swellAPI.loginCustomer(user.email, user.id);
            referralDetails = await swellAPI.getCustomerDetails();
        }

        if (referralDetails?.email) {
            dispatch(setForYourRferral(referralDetails));
        }
    }

    const getClient = (e?: any) => {
        if (!swellAPI) {
            return;
        }

        loadDetails();
    }

    const refreshClient = (e?: any) => {
        if (!swellAPI) {
            return;
        }

        swellAPI.refreshCustomerDetails(loadDetails);
    }

    // Register yotpo actions
    useEffect(() => {
        if (!loaded) {
            return;
        }

        // Activates on referral modal show for new customer
        doc.addEventListener('swell:referred:customer:discount', (e: any) => {
            const existing_coupon = localStorage.getItem('cb_referral_coupon');
            const coupon = e && e.detail && e.detail.coupon_code;

            if (coupon && (!existing_coupon || existing_coupon !== coupon)) {
                localStorage.setItem('cb_referral_coupon', coupon);
                YotpoService.createReferralCoupon(false);
            }
        });

        if ((!user || !user.email) && localStorage.getItem('loyalty-coupon-code')) {
            localStorage.setItem('cb_referral_coupon', localStorage.getItem('loyalty-coupon-code'));
        }

        let timer: any;

        if (user && user.email && window?.swellConfig?.swellAccount) {
            window.swellConfig.swellAccount.authToken = process.env.YOTPO_SDK;
            window.swellConfig.swellAccount.email = user.id;
            window.swellConfig.swellAccount.id = user.id;

            doc.addEventListener('swell:setup', getClient);
            doc.addEventListener('swell:setup', refreshClient);

            timer = setInterval(() => {
                if (active || !document.getElementById('customer_information')) {
                    return;
                }

                refreshClient();
                setActive(true);
            }, 7000);
        } else {
            return () => clearInterval(timer);
        }
    }, [loaded, user]);

    return (user?.email && user?.id)
        ?
        <div
            id="swell-customer-identification"
            data-authenticated="true"
            data-email={user.email}
            data-id={user.id} />
        : <></>;
}