import { useState, useEffect } from 'react';

export function useScript(src: string, reload?: boolean) {
    const [status, setStatus] = useState(src ? 'loading' : 'idle');

    useEffect(
        () => {
            if (!src) {
                setStatus('idle');
                return;
            }

            let script = document.querySelector(`script[src='${src}']`) as any;

            if (!script || reload) {
                script = document.createElement('script');
                script.src = src;
                script.async = true;
                script.setAttribute('data-status', 'loading');
                document.body.appendChild(script);

                const setAttributeFromEvent = (event: any) => {
                    script.setAttribute(
                        'data-status',
                        event.type === 'load' ? 'ready' : 'error'
                    );
                };

                script.addEventListener('load', setAttributeFromEvent);
                script.addEventListener('error', setAttributeFromEvent);
            } else {
                if (script && reload) {
                    document.body.removeChild(script);
                }
                setStatus(script.getAttribute('data-status'));
            }

            const setStateFromEvent = (event: any) => {
                setStatus(event.type === 'load' ? 'ready' : 'error');
            };

            script.addEventListener('load', setStateFromEvent);
            script.addEventListener('error', setStateFromEvent);

            return () => {
                if (script) {
                    script.removeEventListener('load', setStateFromEvent);
                    script.removeEventListener('error', setStateFromEvent);
                }
            };
        },
        [src]
    );

    return status;
}