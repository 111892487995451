import { apiGet } from '@utils/http';

export interface IPromoMessage {
    text: string[];
    code: string;
    autoApply: boolean;
}

export default class HomePageService {
    static getCached(): Promise<any> {
        return fetch(`${process.env.API_BASE}wp-content/uploads/cache/frontend-homepage.json`)
                .then(response => response.json())
                .then((response: any) => {
                    return response as any;
                });
    }

    static get(): Promise<any> {
        return apiGet('frontend/homepage')
                .then((response: any) => response as any)
                .catch(err => console.error('Failed to load Home Page data', err));
    }

    static getPromoBanner(): Promise<IPromoMessage> {
        const storedPromoUrl = localStorage.getItem('cb_promo_url');
        const promoUrl = encodeURIComponent(storedPromoUrl ? storedPromoUrl : window.location.href);

        return apiGet(`frontend/get-promo-message?url=${promoUrl}`)
                .then((response: any) => (response as any) as IPromoMessage)
                .finally(() => {
                    localStorage.removeItem('cb_promo_url');
                });
    }
}