import { useSelector, useDispatch } from 'react-redux';

import SubscriptionEntity, { ISubscription } from '@v2/entities/subscription.entity';
import { RootState } from '@v2/store';
import SubscriptionService from '@v2/services/subscription.service';
import { setSubscription } from './rtk.subscription.store';

export function useSubscriptionSelector(): SubscriptionEntity | null {
    return useSelector((state: RootState) => {
        const data: ISubscription = state.subscription.data;

        return data instanceof SubscriptionEntity ? data : SubscriptionEntity.fromJson(data) as SubscriptionEntity;
    });
}

export function useSubscription(fetch?: boolean): SubscriptionEntity {
    const dispatch = useDispatch();

    let stored = useSubscriptionSelector();

    if (false && typeof stored === 'undefined' && fetch) {
        (async () => {
            await SubscriptionService.get()
                .then((subscription: SubscriptionEntity) => {
                    stored = subscription;
                    dispatch(setSubscription(subscription));
                })
                .catch(console.error);
        })();
    }

    return stored;
}
