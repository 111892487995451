import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StampedState {
    rating: number;
    total: number;
}

const initialState: StampedState = {
    rating: 0,
    total: 0
}

const slice = createSlice({
    name: 'stamped',
    initialState,
    reducers: {
        setRating: (state: StampedState, action: PayloadAction<{ rating: number, total: number }>): void => {
            state.rating = action.payload.rating;
            state.total = action.payload.total;
        },
    },
});

const stampedReducer = slice.reducer;

export const { setRating } = slice.actions;

export default stampedReducer;