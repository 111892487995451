import { logoutUser } from "@v2/components/pages/logout/logout.page";
import { getByKey } from "./localstorage";
import { store } from "@app/store";

export enum RequestMethod {
    GET = 'GET',
    HEAD = 'HEAD',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    OPTIONS = 'OPTIONS',
    TRACE = 'TRACE',
}

function buildHeaders(auth: boolean) {
    const headers = new Headers({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    });

    const jwt = store.getState().user?.jwt?.token;

    if (auth || jwt) {
        const testdata = getByKey('lastlogin');

        headers.append('Authorization', jwt ? 'Bearer ' + jwt : '');
        headers.append('X-Last-Username', testdata ? testdata.username : '');
        headers.append('X-Last-ID', testdata ? testdata.id : '');
        headers.append('X-Last-Tokenuser', testdata ? testdata.tokenuser : '');
    }

    return headers;
}

export function apiImageSource(path: string) {
    return process.env.API_URL.replace('/api/', '/image/') + path;
}

export function buildRequest(method: string, auth?: boolean, data?: object, signal?: AbortSignal) {
    return {
        method: method,
        signal: signal,
        headers: buildHeaders(auth),
        credentials: 'include' as RequestCredentials,
        ...(data && {body: JSON.stringify(data)}),
    };
}

export function apiRequest(
    request: RequestInfo,
    init?: RequestInit,
    allowUnauthenticatedRequest?: boolean
): Promise<Response> {
    let requestInfo = request;

    if (typeof request === 'string' || request instanceof String) {
        if (request.startsWith('@v2')) {
            requestInfo = request.replace('@v2', (process.env.API_LUMEN_URL.endsWith('/')
                                                        ? process.env.API_LUMEN_URL.substring(0, process.env.API_LUMEN_URL.length - 1)
                                                        : process.env.API_LUMEN_URL))
        } else if (request.startsWith('@auth')) {
            requestInfo = request.replace('@auth', (process.env.API_BASE.endsWith('/')
                                                        ? process.env.API_BASE.substring(0, process.env.API_LUMEN_URL.length - 1)
                                                        : process.env.API_BASE) + 'wp-json/jwt-auth/v1');
        } else {
            requestInfo = process.env.API_URL + request;
        }
    }

    return fetch(requestInfo, init)
        .then(response => {
            // Wordpress API returns 403 && new API returns 401. 401 is actually the correct response code
            // Update: now all I see is 401 from the Wordpress API, so something may have changed
            if (response.status === 403 || response.status === 401) {
                // remove token, do cleanup
                logoutUser();

                // The worpdress API has no functionality to skip the token validation so
                // when we get 403 it can also be for an endpoint where no auth is needed.
                if (!allowUnauthenticatedRequest) {
                    // let's make sure if something goes wrong we're not gonna enter a redirect loop
                    if (window.location.pathname !== '/') {
                        window.location.href = '/';
                    }
                }

                // return Promise.reject(RejectReasons.TOKEN_EXPIRED);
            }

            return Promise.resolve(response);
        })
}

export function apiGet(
    request: RequestInfo,
    allowUnauthenticated?: boolean
): Promise<Response> {
    return apiRequest(request, buildRequest('GET', !allowUnauthenticated), allowUnauthenticated)
        .then(response => response.json())
}

export function apiPut(
    request: RequestInfo,
    data: any,
    allowUnauthenticated?: boolean
): Promise<Response> {
    return apiRequest(request, buildRequest('PUT', !allowUnauthenticated, data), allowUnauthenticated)
        .then(response => {
            if (response.status >= 400) {
                throw response;
            }

            return response;
        })
        .then(response => response.json())
}

export function apiPost(
    request: RequestInfo,
    data: any,
    allowUnauthenticated?: boolean,
    signal?: AbortSignal,
    ignoreStatus?: boolean
): Promise<Response> {
    return apiRequest(request, buildRequest('POST', !allowUnauthenticated, data, signal), allowUnauthenticated)
        .then(response => {
            if (response.status >= 400 && !ignoreStatus) {
                throw response;
            }

            return response;
        })
        .then(response => response.json())
}
