import { css } from '@emotion/css'
import colors from './colors';
import { mobileOnly, tabletLandscapeUp } from '@styles/breakpoints';

export default (headerColor?: string) => css({
    '&:not(.is-scrolled)': {
        '.b-header': {
            '&.b-header-pdp': {
                boxShadow: 'none',

                '.e-header-inner': {
                    backgroundColor: colors.WHITE,
                    [tabletLandscapeUp]: {
                        backgroundColor: colors.BEIGE_PDP,
                    },
                },
            },

            '&.b-header-home': {
                boxShadow: 'none',

                '.e-header-inner': {
                    backgroundColor: colors.WHITE,
                    [tabletLandscapeUp]: {
                        backgroundColor: headerColor ? headerColor : colors.PINK_EXTRA_LIGHT,
                    }
                },
            },
        },
    },

    '&.is-logged': {
        '#promo_bar': {
            display: 'none',
        },
        '.b-header': {
            '&::before': {
                content: 'none',
                display: 'none',
            }
        }
    },

    '.b-header-empty': {
        '.b-promo-bar, .e-left, .e-right': {
            display: 'none !important',
        },

        '.e-logo-link': {
            margin: 'auto',
            display: 'block',

            '&.desktop': {
                display: 'none !important',
            },
            '&.mobile': {
                display: 'block !important',
            },
        },
    },

    '.b-header-home': {
        '.e-header-inner': {
            backgroundColor: 'rgb(255, 236, 240) !important',
        },
    },

    '&.is-scrolled': {
        '.b-header-home': {
            '.e-header-inner': {
                backgroundColor: 'rgba(255, 255, 255, 0.85) !important',
            },
        }
    },

    '&.is-scrolled-50': {
        '.b-header': {
            '&.m-account-settings2': {
                '.is-plp:not(.is-editing)': {
                    '.message': {
                        display: 'none',
                        [tabletLandscapeUp]: {
                            display: 'flex',
                        }
                    },

                    '.steps': {
                        display: 'flex',
                        [tabletLandscapeUp]: {
                            display: 'none',
                        }
                    },
                },
            },

            '.box-full': {
                backgroundColor: colors.WHITE,
                [tabletLandscapeUp]: {
                    backgroundColor: colors.MINT,
                    transition: 'background-color 500ms linear',
                }
            },
        },
    },

    '&.is-scrolled-60': {

    },

    '&.canada': {
        height: '100%'
    },

    '#swell-customer-identification': {
        display: 'none',
    },
});