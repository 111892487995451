import { ICoupon, IPaymentMethod } from "@v2/interfaces";
import BaseEntity from "./base.entity";
import BoxEntity, { IBoxRecord } from "./box.entity";

export interface ISubscription {
    id: number;
    status: string;
    nextPayment: string;

    boxes: IBoxRecord[];
    paymentMethod?: IPaymentMethod;
    oos: boolean;

    order: {
        costShipping: number;
        costShippingPrepaid: number;
        costDiscount: number;
        costSubtotal: number;
        costTaxes: number;
        costTotal: number;
        coupons: ICoupon[];
    };

    shipping: {
        firstName: string;
        lastName: string;
        date: string;
        datetime: string;
        delivery: string;
        method: string;
        options: any;
    };
}

export default class SubscriptionEntity extends BaseEntity implements ISubscription {
    id: number;
    status: string;
    nextPayment: string;

    paymentMethod?: IPaymentMethod;
    oos: boolean;
    order: {
        costShipping: number;
        costShippingPrepaid: number;
        costDiscount: number;
        costSubtotal: number;
        costTaxes: number;
        costTotal: number;
        coupons: ICoupon[];
    };
    shipping: {
        firstName: string;
        lastName: string;
        date: string;
        datetime: string;
        delivery: string;
        method: string;
        options: any;
    };

    boxes: BoxEntity[];

    static map = { boxes: BoxEntity };

    static fromJson(json: { [key: string]: any }): SubscriptionEntity {
        return BaseEntity.fromJson(new this(), json, SubscriptionEntity.map) as SubscriptionEntity;
    }

    getNextPaymentDate(): string {
        return this.nextPayment ? this.nextPayment.split(' ')[0] : '';
    }

    getBoxes(): BoxEntity[] {
        return this.boxes?.map(box => box instanceof BoxEntity ? box : BoxEntity.fromJson(box) as BoxEntity) || [];
    }

    getActiveBoxes(): BoxEntity[] {
        return this.getBoxes().filter((box: BoxEntity) => box.status === 'active');
    }

    getActiveSubscriptionBoxes(): BoxEntity[] {
        return this.getActiveBoxes().filter((box: BoxEntity) => box.cadence !== 'none');
    }

    getNextShipmentBoxes(): BoxEntity[] {
        return this.getActiveBoxes().filter((box: BoxEntity) => box.nextPayment === this.getNextPaymentDate());
    }

    getChildrenBoxes(statusIn?: string[], statusNotIn?: string[]): BoxEntity[] {
        return this.getBoxes().filter((box: BoxEntity) => box.childId)
                            .filter((box: BoxEntity) => statusIn.length
                                ? statusIn.includes(box.status)
                                : (statusNotIn.length ? !statusNotIn.includes(box.status) : true)
                            )
                            .sort((a: any, b: any) => a.availability === b.availability
                                ? new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                                    : (b.availability === 'outofstock' ? 1 : -1))
    }

    getSubscriptionBoxes(statusIn: string[] = [], statusNotIn: string[] = []): BoxEntity[] {
        return this.getBoxes().filter((box: BoxEntity) => box.cadence !== 'none')
                            .filter((box: BoxEntity) => statusIn.length
                                ? statusIn.includes(box.status)
                                : (statusNotIn.length ? !statusNotIn.includes(box.status) : true)
                            )
                            .sort((a: any, b: any) => a.availability === b.availability ?
                                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                                    : (b.availability === 'outofstock' ? 1 : (b.availability === 'active' ? 0 : -1)))
                            .sort((a: any, b: any) => a.status === b.status ?
                                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                                    : (b.status === 'inactive' ? -1 : 1))
    }

    getOneOffBoxes(statusIn?: string[], statusNotIn?: string[]): BoxEntity[] {
        return this.getBoxes().filter((box: BoxEntity) => box.cadence === 'none')
                            .filter((box: BoxEntity) => statusIn.length
                                ? statusIn.includes(box.status)
                                : (statusNotIn.length ? !statusNotIn.includes(box.status) : true)
                            )
                            .sort((a: any, b: any) => a.availability === b.availability ?
                                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
                                    : (b.availability === 'outofstock' ? 1 : -1));
    }

    hasActiveSubscriptions(): boolean {
        return this.getActiveSubscriptionBoxes().length > 0 && this.status === 'active';
    }

}