import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import { packAndTrack, subscribe_canada } from '@v2/services/analytics.service';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { emailRegex } from '@components/common/helpers/regex';
import Clickable from '@components/common/presentational/clickables/default';
import { useIsMobile } from '@components/common/helpers/screen-check';

import slickStyle from '@v1/styles/vendor/slick';
import { css } from '@emotion/css'
import colors from '@styles/colors';
import { imgV2 as img } from '@styles/utils';
import { mobileOnly, tabletPortraitUp, desktopUp } from '@v2/styles/breakpoints';
import closeButtonWhite from '@img/modal/close-button-white.svg';
import { useModal } from '@v2/hooks/modals';

const pageStyle = css({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%',
    background: `url(${img('canadian/ca-hero.jpg')})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'inherit',
    backgroundSize: 'cover',

    '&.mobile': {
        background: `url(${img('canadian/ca-hero-m.jpg')})`,
    }
});

const style = css({
    background: `#8AA6EC`,
    borderRadius: 10,
    maxWidth: 370,

    [tabletPortraitUp]: {
        maxWidth: 600,
    },
    [desktopUp]: {
        maxWidth: '100%',
    },

    '.modal-wrapper': {
        background: `url(${img('canadian/bg-desktop.png')})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 30px 20px 30px',
        position: 'relative',
        borderRadius: 10,
    },
    h2: {
        textAlign: 'center',
        marginBottom: 20,
        color: colors.WHITE,
        zIndex: 1,
    },
    p: {
        textAlign: 'center',
        fontWeight: 400,
    },
    '.ca': {
        zIndex: 1,

        '.ca-icon': {
            display: 'block',
            margin: 'auto',
            marginTop: 20,
        }
    },
    '.section': {
        borderRadius: 20,
        background: 'white',
        padding: 60,
        margin: '0 15px 0 15px',
        width: '500px',
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        alignContent: 'center',
        justifyContent: 'center',
        alignItems: 'center',
    },
    '.wrapper': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        alignContent: 'center',
        zIndex: 1,
    },
    '.help': {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: 16,
        textDecoration: 'ubderline',
        color: colors.WHITE,
        marginBottom: 24
    },
    '.action': {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16,
        color: colors.WHITE,
        textAlign: 'center',
        width: '100% !important',
        display: 'block',
    },
    '.slider': {
        maxWidth: '400px !important',

        '.slick-arrow': {
            height: 14,
            width: 8.64,
            margin: 0,

            '&.slick-prev': {
                background: `url('${img(`canadian/arrow-left.svg`)}') center/cover no-repeat`,
            },
            '&.slick-next': {
                background: `url('${img(`canadian/arrow-right.svg`)}') center/cover no-repeat`,
            },
            '&:before': {
                content: '""',
            },
            '&:hover': {
                opacity: 0.7,
            },
        }
    },
    '.brand-wrapper': {
        paddingLeft: 10,
        paddingRight: 10,

        '.brand': {
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'stretch',
            fontWeight: 600,
            border: '1px solid #c5c5c5',
            borderRadius: 20,

            img: {
                width: '100%',
                borderRadius: '20px 20px 0 0',
            },
            span: {
                padding: '10px',
                background: colors.BLUE,
                color: colors.WHITE,
                textAlign: 'center',
                width: '100% !important',
                display: 'block',
                borderRadius: '0 0 20px 20px',
                fontWeight: 500,
                fontSize: 12,
                textTransform: 'uppercase',
            },
            a: {
                textDecoration: 'none',

                '&:link': {
                    textDecoration: 'none',
                },
                '&:visited': {
                    textDecoration: 'none',
                },
                '&:hover': {
                    textDecoration: 'none',
                },
                '&:active': {
                    textDecoration: 'none',
                }
            }
        }
    },

    form: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        position: 'relative',

        '.error-message': {
            position: 'absolute',
            bottom: '-20px',
        },

        button: {
            marginLeft: '-30px',
            padding: '15px 50px !important',
            borderRadius: '50px !important',
        },
        'input[type=email]': {
            backgroundColor: '#EBEEF6',
            borderColor: '#EBEEF6',
            borderRadius: '30px !important',
            maxWidth: '240px !important',
            minWidth: '240px !important',
            padding: '15px 20px',

            '&::placeholder': {
                color: colors.BLUE,
            }
        },
    },
    '.toggle': {
        position: 'absolute',
        top: 20,
        left: 40,
    },
    '.confirmation-message': {
        width: 400,
        height: 50,
        background: colors.GREEN_BRIGHT,
        borderRadius: 25,
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: '0.2em',
        textTransform: 'uppercase',
        color: colors.WHITE,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',

        img: {
            marginRight: 7
        }
    },

    '.logo': {
        maxWidth: 220,
        width: '100%'
    },

    [mobileOnly]: {
        '.background': {
            background: `url(${img('canadian/bg-mobile.png')})`,
        },
        '.wrapper': {
            padding: '0 15px',
        },
        '.toggle': {
            position: 'absolute',
            top: 20,
            left: 20,
        },
        h2: {
            marginBottom: 15,
            fontWeight: 800,
            fontSize: 21,
        },
        p: {
            fontSize: '13px !important',
            color: `${colors.WHITE} !important`,
            fontWeight: '400 !important',
        },
        '.action, .help': {
            fontSize: '14px !important',
            fontWeight: '400 !important',
        },
        '.section': {
            borderRadius: 0,
            background: 'none',
            padding: 0,
            margin: 0,
            width: '100%',
            marginBottom: 20,
        },
        '.brand': {
            border: '0px solid white !important',
        },
        '.logo': {
            display: 'none'
        },

        form: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            justifyContent: 'center',
            alignItems: 'stretch',
            alignContent: 'center',
            width: '100%',
            maxWidth: 276,

            button: {
                marginLeft: '0px',
                padding: '15px 50px !important',
                borderRadius: '50px !important',
            },
            'input[type=email]': {
                maxWidth: '100% !important',
                minWidth: '100% !important',
                marginBottom: 5,
            },
        },
        '.confirmation-message': {
            width: '100%',
            maxWidth: 276,
        },
        '.slider': {
            maxWidth: '300px !important',
            '.slick-arrow': {
                height: 24,
                width: 24,

                '&.slick-prev': {
                    background: `url('${img(`canadian/arrow-left-m.svg`)}') center/cover no-repeat`,
                },
                '&.slick-next': {
                    background: `url('${img(`canadian/arrow-right-m.svg`)}') center/cover no-repeat`,
                },
            }
        },
        '.brand-wrapper .brand img': {
            maxWidth: 140,
        },
        '.brand-wrapper .brand span': {
            fontStyle: 'normal',
            fontSize: 10,
        }
    },
    '.close-container': {
        position: 'absolute',
        right: 20,
    },

    '&.international': {
        maxWidth: '100%',

        '.brand-wrapper': {
            width: 175,

            '.brand': {
                img: {
                    width: 88,
                    borderRadius: 0,
                    padding: '16px 10px 12px 10px',
                },

                a: {
                    background: 'white',
                    borderRadius: 20,
                }
            }
        },

        [mobileOnly]: {
            '.brand-wrapper': {
                width: 120,
            },
        },
    }
});

const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    centerMode: false,
    speed: 1000,
    autoplaySpeed: 1000,
    slidesToShow: 2,
    slidesToScroll: 2,
    rows: 1,
    slidesPerRow: 1,
    initialSlide: 0,
    className: 'carousel-container',
};

function SubscribeForm({ onSubmit, isFrench }: { onSubmit: Function, isFrench?: boolean }) {
    const [email, setEmail] = useState(null);
    const [completed, setCompleted] = useState(false);
    const { register, handleSubmit, formState: { errors } } = useForm({ reValidateMode: 'onBlur' });

    function formSubmit(): any {
        onSubmit(email);
        setCompleted(true);
    }

    return (
        <>
            {!completed
                ?
                <>
                    <form onSubmit={handleSubmit(formSubmit)}>
                        <input
                            type="email"
                            name="email"
                            aria-label={'email'}
                            placeholder={isFrench ? 'entrez votre e-mail' : 'Your Email Address'}
                            className={errors.email ? 'error' : ''}
                            value={email || ''}
                            {...register('email', {
                                onChange: (e: any) => setEmail((e.target as HTMLInputElement).value),
                                required: isFrench
                                    ? 'Ce champ est obligatoireadresse'
                                    : 'This field is required',
                                pattern: {
                                    value: emailRegex,
                                    message: isFrench
                                        ? 'Veuillez fournir une adresse email valide'
                                        : 'Please provide a valid email address',
                                }
                            })} />
                        <button className="button primary blue" type="submit" >{isFrench ? 'S’ABBONER' : 'SUBMIT'}</button>
                        <>
                            {errors.email &&
                                <div className="error-message"><ErrorMessage errors={errors} name="email" /></div>
                            }
                        </>
                    </form>
                </>
                :
                <div className="confirmation-message">
                    <img className="ca-check" src={img(`canadian/check.svg`)} alt={''} />
                    <span>{isFrench ? 'Vous êtes sur la liste' : 'You’re on the list'}</span>
                </div>
            }
        </>
    );
}

function InternationalModal() {
    async function emailSubmit(email: string) {
        packAndTrack('Subscribed to International News', [{
            view: 'International Modal',
            section: 'Subscribe Form',
            email: email
        }]);
    }

    useEffect(() => {
        packAndTrack('International Modal Viewed', [{
            view: 'International Modal',
            section: 'International Modal',
        }]);
    }, [])

    return (
        <div className={`${style} international`}>
            <span className="modal-wrapper">
                <div className='ca'>
                    <img className="ca-icon" src={img(`canadian/int-icon.svg`)} alt={'international icon'} />
                    <h2>Shopping internationally?</h2>
                    <div className='wrapper'>
                        <div className='section'>
                            <p>Visit us at iHerb to see if we ship to you!</p>
                            <div className={`slider ${slickStyle}`}>
                                <div className="brand-wrapper">
                                    <div className="brand">
                                        <a href="https://www.iherb.com/c/cerebelly" onClick={() => packAndTrack('Shop Partner Clicked', [{
                                            view: 'International Modal',
                                            section: 'Partner Carousel',
                                            partner: 'iHerb'
                                        }])}>
                                            <img src={img(`location/iHerb.png`)} alt={'iHerb logo'} />
                                            <span>SHOP iHerb</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='section'>
                            <img src={img('canadian/ca-logo.png')} alt="cerebelly" className="mb-5 logo" />
                            <p>Drop your email here to be the first to learn about news around Cerebelly internationally!</p>
                            <SubscribeForm onSubmit={emailSubmit} isFrench={false} />
                        </div>
                    </div>
                    <p className="help">
                        Need help? Contact us at <a href="mailto:support@cerebelly.com">support@cerebelly.com</a>
                    </p>
                </div>
            </span>
        </div>
    );
}

function CanadaModal() {
    const [isFrench, setIsFrench] = useState(false);
    const isMobile = useIsMobile(600);

    const stores = {
        0: {
            title: 'sobeys',
            url: 'https://voila.ca/?utm_source=sobeyscorner&utm_medium=referral'
        },
        1: {
            title: 'amazon',
            /* eslint-disable */
            url: 'https://www.amazon.ca/stores/page/643CE71D-ADC6-4E55-9C0D-EC4660D86CB9?maas=maas_adg_8A6CF91BFF6DB4A5C419AA4AE2E9FCF6_afap_abs&ref_=aa_maas&tag=maas&ingress=0&visitId=f9a8b225-c3dd-49fe-82a2-fc6b3bde7051'
        },
        2: {
            title: 'thrifty-foods',
            url: 'https://www.thriftyfoods.com/find-a-store'
        },
        3: {
            title: 'farm-boy',
            url: 'https://www.farmboy.ca/stores/'
        },
        4: {
            title: 'longos',
            url: 'https://www.longos.com/store-finder'
        },
        5: {
            title: 'voila',
            /* eslint-disable */
            url: 'https://voila.ca/?gclid=e398caf932a2198a7a5caee59d9ee6f6&gclsrc=3p.ds&ds_rl=1287639&msclkid=e398caf932a2198a7a5caee59d9ee6f6&utm_source=bing&utm_medium=cpc&utm_campaign=CA_2020_Voila_Search_Microsoft_Phrase_Brand&utm_term=sobeys%20voila&utm_content=Voila%20By%20Sobeys'
        },
        6: {
            title: 'voila-by-sobeys',
            url: 'https://voila.ca/?utm_source=sobeyscorner&utm_medium=referral'
        }
    }

    async function emailSubmit(email: string) {
        await subscribe_canada(email);

        packAndTrack('Subscribed to Canadian News', [{
            view: 'Canadian Modal',
            section: 'Subscribe Form',
            email: email
        }]);
    }

    useEffect(() => {
        packAndTrack('Visit Cerebelly CA Page', [{
            view: 'Canadian Modal',
            section: 'Canadian Modal',
        }]);
    }, [])

    return (
        <div className={style}>
            <span className="modal-wrapper">
                <div className='ca'>
                    <Clickable onClick={() => setIsFrench(isFrench ? false : true)} className='toggle'>
                        <img
                            className="ca-toggle"
                            tabIndex={0}
                            role="button"
                            src={img(`canadian/toggle-${isFrench ? 'fr' : 'en'}${isMobile ? '-m' : ''}.svg`)}
                            alt={'toggle'} />
                    </Clickable>
                    <Clickable className="close-container" onClick={() => window.location.replace('https://www.cerebelly.com')}>
                        <img
                            src={closeButtonWhite}
                            tabIndex={0}
                            role="button"
                            alt={'close modal'} />
                    </Clickable>
                    <img className="ca-icon" src={img(`canadian/ca-icon.svg`)} alt={'canadian icon'} />
                    <h2>
                        {isFrench ? 'Magasinez Cerebelly au Canada!' : 'Shop Cerebelly in Canada!'}
                    </h2>
                    <div className='wrapper'>
                        <div className='section'>
                            <p>{isFrench
                                ? 'Nos produits délicieux et nutritifs sont maintenant disponibles chez nos partenaires'
                                : 'Our delicious and nutritious products are now available through our partners'
                            }</p>
                            <div className={`slider ${slickStyle}`}>
                                <Slider {...settings}>
                                    {
                                        Object.values(stores).map((store: any, key: number) => {
                                            return (
                                                <div className="brand-wrapper" key={key}>
                                                    <div className="brand">
                                                        <a href={store.url} onClick={() => packAndTrack('Shop Partner Clicked', [{
                                                            view: 'Canadian Modal',
                                                            section: 'Partner Carousel',
                                                            partner: store.title.replace(/-/g, ' ')
                                                        }])}>
                                                            <img src={img(`canadian/${store.title}.jpg`)} alt={store.title} />
                                                            <span>{isFrench ? 'ACHETEZ' : 'SHOP'} {store.title.replace(/-/g, ' ')}</span>
                                                        </a>
                                                    </div>
                                                </div>)
                                        })
                                    }
                                </Slider>
                            </div>
                        </div>
                        <div className='section'>
                            <img src={img(isFrench ? 'canadian/fr-logo.png' : 'canadian/ca-logo.png')} alt="cerebelly" className="mb-5 logo" />
                            <p>{isFrench
                                ? 'Inscrivez-vous à notre newsletter et soyez le premier à connaître les nouvelles de Cerebelly au Canada'
                                : 'Drop your email here to be the first to learn about news around Cerebelly in Canada!'
                            }</p>
                            <SubscribeForm onSubmit={emailSubmit} isFrench={isFrench} />
                        </div>
                    </div>
                    <a className="action" href="https://cerebelly.com">
                        {isFrench ? 'Emmenez-moi sur le site américain' : 'Take me to the US site'}
                    </a>
                    <p className="help">
                        {isFrench ? 'Besoin d`aide? Contactez-nous ' : 'Need help? Contact us at '}
                        <a href="mailto:support@cerebelly.com">support@cerebelly.com</a>
                    </p>
                </div>
            </span>
        </div>
    );
}

export function useInternationalModal() {
    const [showModal, hideModal] = useModal({
        windowType: 'LARGE',
        alignType: 'CENTER',
        heightType: 'TALL',
        blurBackground: true,
        scroll: false,
        content: { component: () => <InternationalModal /> },
        onClose: () => {
            window.location.href = 'https://cerebelly.com/';
        },
        className: css({
            '.main': {
                borderRadius: 10,

                '.content': {
                    padding: '0 !important',
                    maxHeight: '100% !important',
                    background: `#8AA6EC`,
                    borderRadius: 10,
                }
            }
        })
    });

    return [showModal, hideModal]
}

export function CanadaPage() {
    const isMobile = useIsMobile(600);

    return (
        <div className={`${pageStyle} ${isMobile ? 'mobile' : ''}`}>
            <CanadaModal />
        </div>
    );
}