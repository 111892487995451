import React from 'react';
import { LinkWithReferrer } from '@components/common/helpers/link';
import { useHistoryWithReferrer } from '@utils/history';
import { useQuizModal } from '@components/common/presentational/modal/common';

export default function SurveyLink(
    { className, children, onClick }
    : { className?: string, children?: any, onClick?: Function }
) {
    const history = useHistoryWithReferrer();
    const [showQuiz] = useQuizModal();

    const action = () => {
        if (onClick) {
            onClick();
        }

        if (history.location.pathname.includes('/shop/#quiz')) {
            showQuiz();
        }
    }

    return (
        <LinkWithReferrer onClick={action} className={className} to={'/shop/#quiz'}>
            {children}
        </LinkWithReferrer>
    );
}