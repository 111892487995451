export default class BaseEntity {
    static fromJson(object: any, json: { [key: string]: any }, relations?: { [key: string]: any }, defaults?: { [key: string]: any }): Object {
        for (let key in json) {
            (object as any)[key] = !!relations && relations[key] && relations[key]?.fromJson
                                        ? (Array.isArray(json[key])
                                            ? relations[key] = json[key].map((item: any) => relations[key].fromJson(item))
                                            : relations[key].fromJson(json[key]))
                                        : (!json[key] && defaults && defaults[key]
                                            ? defaults[key]
                                            : json[key]);
        }

        return object;
    }
}