import { ICartBox, IProduct } from "@v2/interfaces";
import { useActiveBox } from "@app/store/cart.selectors";

(window as any).cbHooks = {
    useActiveBox: useActiveBox,
}
function round(num: number) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function isBoxSubscribable(box: ICartBox): boolean {
    return !box.counts.collaborations || box.counts.collaborations === 0;
}

export function calculateBundleTotal(bundle: IProduct, defaultPrices?: boolean) {
    let total = 0;

    for (const key in bundle.products) {
        const price = defaultPrices
                        ? bundle.products[key].price
                        : bundle.products[key].priceSubscribed;

        total += round(bundle.products[key].size * price);
    }

    return total;
}

export function isCategoryNotFull(product: IProduct, counts: any, productSize?: number) {
    const size  = productSize ? productSize : 0;
    const pouchLike: any = [
        'bonebrothpouches',
        'pouches',
        'smoothies'
    ];

    if (pouchLike.includes(product.category)) {
        if (((counts.pouches || 0) + (counts.smoothies || 0) + (counts.bonebrothpouches || 0) + size) < product.categoryMaxItems) {
            return true;
        }
    } else {
        if (((counts[product.category] || 0) + size) < product.categoryMaxItems) {
            return true;
        }
    }

    return false;
}