import { useSelector } from 'react-redux';
import { RootState } from '@v2/store';
import { IGlobals, emptyGlobals } from './rtk.entities.store';

export function useEntity(key: string) {
    return useSelector((state: RootState) => state.entities.entities[key]);
}

export function useProfileGlobals(): IGlobals {
    return useSelector((state: RootState) => state.entities.entities?.globals || emptyGlobals);
}