import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import parse from 'html-react-parser';

import HomePageService, { IPromoMessage } from '@v2/services/homepage.service';
import { hasPromoBar } from '@components/common/presentational/header/header';

import styles from '@components/common/presentational/promo/promo-bar.styles';
import { useIsLogged } from '@app/store/rtk.user.selectors';
import { setEntities } from '@v2/store/rtk.entities.store';
import { useDispatch } from 'react-redux';
import { useEntity } from '@v2/store/rtk.entities.selectors';

function Promo({ mainDocElement }: { mainDocElement: any }) {
    const [lineIndex, setLineIndex] = useState(0);
    const promo = useEntity('promo') as IPromoMessage;

    useEffect(() => {
        const timer = setInterval(() => {
            setLineIndex(prevIndex => prevIndex + 1 === promo.text.length ? 0 : prevIndex + 1);
        }, 5000);

        return () => clearInterval(timer);
    }, [promo]);

    return (
        <>
            {promo.text.map((message, index) =>
                <div
                    key={index}
                    id="promo_bar"
                    style={{ display: lineIndex === index ? 'flex' : 'none', zIndex: 2, position: 'relative' }}
                    className={`animate__animated animate__slideInDown ${styles.promoBar}`}>
                    <p>{parse(message)}</p>
                </div>)}
        </>
    );
}

export default function PromoBar() {
    const dispatch = useDispatch();
    const isLogged = useIsLogged();
    const location = useLocation();

    const [loaded, setLoaded] = useState(false);
    const promo = useEntity('promo') as IPromoMessage;
    const showBar = hasPromoBar(isLogged, location.pathname, promo) && loaded;

    const mainDocElement = document.getElementsByClassName('e-main-container')[0];

    useEffect(() => {
        mainDocElement.classList[isLogged ? 'remove' : 'add']('with-promo');
    }, [isLogged]);

    useEffect(() => {
        if (isLogged) {
            return;
        }

        HomePageService.getPromoBanner()
            .then(promo => {
                dispatch(setEntities({ 'promo': promo }));
                setLoaded(true);
            });
    }, [isLogged]);

    return (
        showBar
            ? <Promo mainDocElement={mainDocElement} />
            : <div id="promo_bar" className={`${styles.promoBar}`}></div>
    );
}
