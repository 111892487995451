import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalSettings } from '@v2/components/common/presentational/modal/interfaces';
import cloneDeep from 'lodash.clonedeep';

const getNewModalPosition = (modalState: any) => {
    const combineModals: any = [...modalState.general, ...modalState.shop];
    const positions = combineModals.map((state: any) => state.position);
    const position = combineModals.length ? Math.max(...positions) + 1 : 0;
    return position;
}

const popModal = (modals: any) => {
    return [...modals.slice(0, modals.length - 1)];
}

const pushModal = (modals: any, item: any) => {
    return [...modals, item]
}

const removeModalById = (modals: any, id: any) => {
    return [...modals].filter((item) => item.id !== id);
}

const getId = () => {
    return Math.random().toString(36).slice(2);
}

const getLastModal = (modals: any) => {
    return [...modals].pop();
}

interface ModalsState {
    general: IModalSettings[];
    shop: IModalSettings[];
}

const initialState: ModalsState = {
    general: [],
    shop: [],
}

const slice = createSlice({
    name: 'modals',
    initialState,
    reducers: {
        showGeneralModal: (state: ModalsState, action: PayloadAction<IModalSettings>): ModalsState => {
            const newState = cloneDeep(state);
            const position = getNewModalPosition(newState);
            const modalEntry = {
                ...action.payload,
                position,
            }
            return { ...newState, general: pushModal(newState.general, modalEntry)};
        },

        hideGeneralModal: (state: ModalsState, action: PayloadAction<string>): ModalsState => {
            const newState = cloneDeep(state);
            document.body.classList.remove('m-overflow-hidden');
            if (action.payload) {
                return {...newState, general: removeModalById(newState.general, action.payload)};
            }
            return { ...newState, general: popModal(newState.general) };
        },

        pushShopModal: (state: ModalsState, action: PayloadAction<IModalSettings>): ModalsState => {
            const newState = cloneDeep(state);
            const id = getId();
            const position = getNewModalPosition(newState);
            const modalEntry = {
                ...action.payload,
                position,
                id,
            }
            return { ...newState, shop: pushModal(newState.shop, modalEntry) };
        },

        popShopModal: (state: ModalsState): ModalsState => {
            const newState = cloneDeep(state);
            if (!newState.shop.length) {
                document.body.classList.remove('m-overflow-hidden');
            }
            return { ...newState, shop: popModal(newState.shop) };
        },

        updateShopModal: (state: ModalsState, action: PayloadAction<IModalSettings>): ModalsState => {
            const newState = cloneDeep(state);
            const id = getId();
            const defaultPosition = getNewModalPosition(newState);
            const position = getLastModal(newState.shop)?.position ?? defaultPosition;
            const modalEntry = {
                ...action.payload,
                position,
                id,
            }
            return { ...newState, shop: pushModal(popModal(newState.shop), modalEntry)};
        },

        closeShopModals:(state: ModalsState): ModalsState => {
            const newState = cloneDeep(state);
            document.body.classList.remove('m-overflow-hidden');
            return { ...newState, shop: []};
        },
    },
});

const modalsReducer = slice.reducer;

export const { showGeneralModal, hideGeneralModal, pushShopModal, popShopModal, updateShopModal, closeShopModals } = slice.actions;

export default modalsReducer;