import { configureStore } from '@reduxjs/toolkit';

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './store/rtk.user.store';
import subscriptionReducer from '@store/rtk.subscription.store';
import checkoutReducer from './store/rtk.checkout.store';
import entitiesReducer from './store/rtk.entities.store';
import formReducer from './store/rtk.form.store';
import forYouReducer from './store/rtk.for-you.store';
import shopReducer from './store/shop.store';
import modalsReducer from './store/rtk.modals.store';
import cartReducer from './store/rtk.cart.store';
import stampedReducer from './store/rtk.stamped.store';

const reducers = combineReducers({
    user: userReducer,
    checkout: checkoutReducer,
    entities: entitiesReducer,
    form: formReducer,
    subscription: subscriptionReducer,
    cart: cartReducer,
    modals: modalsReducer,
    forYou: forYouReducer,
    shop: shopReducer,
    stamped: stampedReducer
});

const persistConfig = {
    key: 'root-rtk',
    storage: storage,
    whitelist: ['user', 'cart', 'form', 'checkout', 'subscription', 'for-you', 'stamped'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
        // immutableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>