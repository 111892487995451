import { unzoom } from '@v2/utils/ui';
import { useEffect, useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';

// tons of stackoverflow code here

// @ts-ignore
function waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
    var startTimeInMs = Date.now();

    (function loopSearch() {
        if (document.querySelector(selector) != null) {
            callback();
            return;
        } else {
            setTimeout(function () {
                if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
                    return;
                }

                loopSearch();
            }, checkFrequencyInMs);
        }
    })();
}

function scrollToTargetAdjusted(hash: string){
    var element = document.getElementById(hash);
    var headerOffset = 70;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
    });
}

function scrollToHashId () {
    // get URL hash (minus the hash mark)
    const hash = window.location.hash.substring(1)

    // if there's a hash, scroll to that ID
    if (hash && hash.length) {
        waitForElementToDisplay("#" + hash, function() {
            scrollToTargetAdjusted(hash)
            // clean up the hash, so we don't scroll on every prop update
            removeHash()
        }, 1000, 20000);
    }
}

// borrowed from http://stackoverflow.com/questions/1397329/how-to-remove-the-hash-from-window-location-with-javascript-without-page-refresh/5298684#5298684
function removeHash () {
    const loc = window.location
    const hist = window.history

    // use modern browser history API
    if (hist && 'pushState' in hist) {
        hist.replaceState('', document.title, loc.pathname + loc.search)
    // fallback for older browsers
    } else {
        // prevent scrolling by storing the page's current scroll offset
        const scrollV = document.body.scrollTop
        const scrollH = document.body.scrollLeft

        loc.hash = ''

        // restore the scroll offset, should be flicker free
        document.body.scrollTop = scrollV
        document.body.scrollLeft = scrollH
    }
}

// v1 legacy
function setBodyClass(pathname: string) {
    const className = getPageClassName(pathname);

    if (className) {
        window.document.body.className = className;
    }
}

// v1 legacy
function getPageClassName(pathname: string) {
    let parts = pathname.split('/').filter(part => part !== '');
    const pagePath = parts.pop();

    return pagePath ? `m-${pagePath}` : null;
}

function ScrollToTop({ history, children }: { history: any, children: any }) {
    const groups = [
        ['/shop', '/product', '/bundle', '/survey', '/box-builder', '/order', '/for-you', '/subscription', '/rewards'],
    ];

    const [currentGroup, setCurrentGroup] = useState(getScrollGroup(history.location.pathname));

    function getScrollGroup(pathname: string) {
        for (let group of groups) {
            for (let [i, prefix] of group.entries()) {
                if (pathname.startsWith(prefix)) {
                    return i;
                }
            }
        }

        return null;
    }

    useEffect(() => {
        if (history.location.hash.startsWith('#')) {
            scrollToHashId();
        }

        const unlisten = history.listen(() => {
            const groupIndex = getScrollGroup(history.location.pathname)

            unzoom();

            if (groupIndex !== currentGroup) {
                window.scrollTo(0, 0);
            }

            setCurrentGroup(groupIndex);
            document.body.classList.remove('m-overflow-hidden-nav')
            setBodyClass(history.location.pathname);
        });

        return () => {
            unlisten(history.location);
        }
    }, []);

    return <Fragment>{children}</Fragment>;
}

// @ts-ignore
export default withRouter(ScrollToTop);