function hexToRGB(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    } : null;
}

function hexToRGBA(hex: string) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: parseInt(result[4], 16)
    } : null;
}

function cssHexWithAlphaToRGBA(hex: string, alpha: number) {
    const rgb = hexToRGB(hex);

    return 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ',' + alpha / 100 + ')';
}

function imgV2(route: string) {
    return `${process.env.BASE_THEME_PATH}/img/v2/${route}`;
}

function imgV1(route: string) {
    return `${process.env.BASE_THEME_PATH}/img/v1/${route}`;
}

export {
    cssHexWithAlphaToRGBA,
    imgV1,
    imgV2,
}
