import { useEffect } from "react";

export const IntercomInit = (): null => {
    useEffect(() => {
        if(process.env.ENV === 'production') {
            try {
                window.Intercom('boot', {
                    app_id: process.env.INTERCOM_APP_ID,
                 });
            } catch(e) {
                console.error(e);
            }
        }
        return () => {
            try {
                window.Intercom('shutdown');
            } catch(e) {
                console.error(e);
            }
        }
    }, []);

    return null;
}