import { css } from '@emotion/css'
import colors from '@styles/colors';

export default {

    dots: css`
        /**
         * ==============================================
         * Dot Pulse
         * ==============================================
         */
        .dot-pulse {
            position: relative;
            margin-left: 10px;
            top: 7px;
            left: -9999px;
            width: 6px;
            height: 6px;
            border-radius: 5px;
            background-color: ${colors.BLUE_LIGHT};
            color: ${colors.BLUE_LIGHT};
            box-shadow: 9999px 0 0 -5px ${colors.BLUE_LIGHT};
            animation: dotPulse 1.5s infinite linear;
            animation-delay: .25s;
        }

        .dot-pulse::before, .dot-pulse::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            width: 6px;
            height: 6px;
            border-radius: 5px;
            background-color: ${colors.BLUE_LIGHT};
            color: ${colors.BLUE_LIGHT};
        }

        .dot-pulse::before {
            box-shadow: 9984px 0 0 -5px ${colors.BLUE_LIGHT};
            animation: dotPulseBefore 1.5s infinite linear;
            animation-delay: 0s;
        }

        .dot-pulse::after {
            box-shadow: 10014px 0 0 -5px ${colors.BLUE_LIGHT};
            animation: dotPulseAfter 1.5s infinite linear;
            animation-delay: .5s;
        }

        @keyframes dotPulseBefore {
            0% {
                box-shadow: 9984px 0 0 -5px ${colors.BLUE_LIGHT};
            }
            30% {
                box-shadow: 9984px 0 0 2px ${colors.BLUE_LIGHT};
            }
            60%,
            100% {
                box-shadow: 9984px 0 0 -5px ${colors.BLUE_LIGHT};
            }
        }

        @keyframes dotPulse {
            0% {
                box-shadow: 9999px 0 0 -5px ${colors.BLUE_LIGHT};
            }
            30% {
                box-shadow: 9999px 0 0 2px ${colors.BLUE_LIGHT};
            }
            60%,
            100% {
                box-shadow: 9999px 0 0 -5px ${colors.BLUE_LIGHT};
            }
        }

        @keyframes dotPulseAfter {
            0% {
                box-shadow: 10014px 0 0 -5px ${colors.BLUE_LIGHT};
            }
            30% {
                box-shadow: 10014px 0 0 2px ${colors.BLUE_LIGHT};
            }
            60%,
            100% {
                    box-shadow: 10014px 0 0 -5px ${colors.BLUE_LIGHT};
            }
        }`
    ,

    fruitLoader: css({
        height: '100%',
        minHeight: '200px',
        width: '100%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        '&.overlay':{
            backgroundColor: colors.WHITE,
            position: 'absolute',
            minHeight: '100%',
            zIndex: 10,
            top: 0,
            left: 0,
        },
    }),

    fruitPageLoader: css({
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: colors.BLUE,
        zIndex: 5,

        '&.with-text':{
            backgroundColor: colors.WHITE,
            '.loader-text': {
                textAlign: 'center',
                margin: '12px 20px 0px',
                fontSize: 20,
                color: colors.NAVY,
            }
        },
        '&.overlay':{
            zIndex: 10,
            position: 'fixed',
            marginTop: '0 !important',
        },
        div: {
            alignSelf: 'center',
        }
    }),

    fruitAnimation: css({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '@keyframes zoomInOut': {
            '0%': {
                transform: 'scale(0)',
            },
            '50%': {
                transform: 'scale(1)',
            },
            '100%': {
                transform: 'scale(0)',
            },
        },

        img: {
            animation: 'zoomInOut 2s infinite',
        }
    }),

    pageLoader: css({
        height: '45vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }),

    overlayLoader: css({
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        // if you don't want it to cover the header play with this value
        zIndex: 1000,
        backgroundColor: 'rgba(255, 255, 255, 0.5)',
    }),

    componentLoader: css({
        height: '100%',
        width: '100%',
        margin: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: colors.WHITE,
        opacity: '0.5',

        div: {
            minHeight: 'auto',
        }
    }),
};