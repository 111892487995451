import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable'
import { imgV2 as img } from '@styles/utils';
import ThemeImage from '@utils/image';
import styles from '@v2/components/pages/home/home.styles';
import Clickable from '@v2/components/common/presentational/clickables/default';
import { useIsMobile } from '@components/common/helpers/screen-check';
import { LinkWithReferrer } from '@components/common/helpers/link';
import { packAndTrack } from '@v2/services/analytics.service';

// import playButton from '@img/homepage2/play_circle_filled_24px_outlined.svg';
// import videoPlayImage from '@img/homepage3/play.svg';

export default function About({ active }: { active: Number }) {
    // const video = document.createElement('video');
    // const canPlay = video.canPlayType && video.canPlayType('video/mp4').replace(/no/, '') ? true : false;
    const [playing, playVideo] = useState(null);
    const mobile = useIsMobile(600);
    const [index, setIndex] = useState(1);

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setIndex(index < 2 ? 3 : index - 1),
        onSwipedRight: () => setIndex(index < 3 ? index + 1 : 1),
        delta: 10,
        // preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0,
    });

    /*
    const videos: any = {
        parenting: `https://cdn.jwplayer.com/videos/ZXLY4BIa-Cx61c2kK.mp4`,
        science: 'https://content.jwplatform.com/videos/1ifAEWgc-Cx61c2kK.mp4'
    }
    */

    const eventAction = (name: string) => {
        packAndTrack(
            name,
            [{ 'view': 'Homepage', 'section': 'About Us' }]);
    }

    useEffect(() => {
        const slider = setTimeout(() => {
            setIndex(index < 3 ? index + 1 : 1);
        }, 8000);
        return () => {
            clearTimeout(slider);
        };
    }, [index]);

    useEffect(() => {
        if (!mobile && active !== 1) {
            playVideo(null);
        }
    }, [active]);

    useEffect(() => {
        if (!playing && (mobile || active !== 1)) {
            return;
        }

        const videoRef: any = document.getElementById('about-video');

        if (!videoRef) {
            return;
        }

        if (videoRef.requestFullscreen) {
            videoRef.requestFullscreen();
        } else if (videoRef.mozRequestFullScreen) {
            videoRef.mozRequestFullScreen();
        } else if (videoRef.webkitRequestFullscreen) {
            videoRef.webkitRequestFullscreen();
        } else if (videoRef.msRequestFullscreen) {
            videoRef.msRequestFullscreen();
        }
    }, [playing, active]);

    return (
        <div className={styles.about}>
            <div className="page-container">
                <div className="inner-container">
                    {/*
                    <ThemeImage
                        src={'homepage3/about-us/video-mobile.webp'}
                        alt={'Dr. Teresa Purzner'}
                        className="video-placeholder active" />
                    */}
                    <div className="homepage-video-wrapper">
                            <span
                                className={`overlay ${active >= 1 ? 'active' : ''}`}
                                style={{ background: `url(${img('homepage3/about-us/video.webp')}) center/cover no-repeat` }}>

                                </span>
                    </div>
                    {/*mobile
                        ?
                        <div className="homepage-video-wrapper">
                            {(canPlay && playing)
                                ?
                                <video
                                    id="about-video"
                                    autoPlay
                                    playsInline
                                    webkit-playsinline=""
                                    poster={img('homepage3/about-us/video-mobile.webp')}
                                    controls>
                                    <source
                                        type="video/mp4"
                                        src={videos[playing]}>
                                    </source>
                                </video>
                                :
                                <span
                                className={`overlay ${active >= 2 ? 'active' : ''}`}
                                style={{ background: `url(${img('homepage3/about-us/video.webp')}) center/cover no-repeat` }}>
                            </span>
                                <ThemeImage
                                    src={'homepage3/about-us/video-mobile.webp'}
                                    alt={'Dr. Teresa Purzner'}
                                    className="video-placeholder active" />
                            }
                            <Clickable onClick={() => {
                                playVideo('parenting');
                                eventAction('Play Parenting Video Clicked');
                            }}>
                                <img className="video-play"
                                    style={(playing || active > 1) ? { display: 'none' } : {}}
                                    src={videoPlayImage}
                                    alt="Play video" />
                            </Clickable>
                        </div>
                        :
                        <div className="homepage-video-wrapper">
                            {(canPlay && playing && active === 1)
                                ?
                                <video
                                    id="about-video"
                                    autoPlay
                                    playsInline
                                    webkit-playsinline=""
                                    poster={img('homepage3/about-us/video.webp')}
                                    controls>
                                    <source
                                        type="video/mp4"
                                        src={videos[playing]}>
                                    </source>
                                </video>
                                :
                                <span
                                    className={`overlay ${active >= 2 ? 'active' : ''}`}
                                    style={{ background: `url(${img('homepage3/about-us/video.webp')}) center/cover no-repeat` }}>
                                </span>
                            }
                            <p style={(playing || active > 1) ? { display: 'none' } : {}} className="pareinting-is">parenting is an art</p>
                            <p style={(playing || active > 1) ? { display: 'none' } : {}} className="cerebelly-is">cerebelly is science</p>
                            <Clickable onClick={() => {
                                playVideo('parenting');
                                eventAction('Play Parenting Video Clicked');
                            }}>
                                <img className="video-play"
                                    style={(playing || active > 1) ? { display: 'none' } : {}}
                                    src={videoPlayImage}
                                    alt="Play video" />
                            </Clickable>
                        </div>
                    */}

                    {mobile &&
                        <div className="slide-arrows">
                            <Clickable onClick={() => setIndex(index === 1 ? 3 : index - 1)} className={`arrow prev'}`}>
                                <ThemeImage src={'homepage3/about-us/arrow-prev.svg'} alt={''} className="Prev Slide" />
                            </Clickable>
                            <Clickable onClick={() => setIndex(index < 3 ? index + 1 : 1)} className={`arrow next'}`}>
                                <ThemeImage src={'homepage3/about-us/arrow-next.svg'} alt={''} className="Next Slide" />
                            </Clickable>
                        </div>
                    }
                    <div className="homepage-articles" {...swipeHandlers} style={!mobile && active < 2 ? { display: 'none' } : {}}>
                        <ThemeImage src={'homepage3/about-us/leaf-top.svg'}
                            alt={''}
                            className={`leaf-top ${(mobile || active < 4) ? 'd-none' : 'animate__animated animate__zoomIn animate__delay-1s'}`} />
                        <div
                            className={`article ${(active < 2 && !mobile) ? 'hidden' : 'show'}`}
                            style={((1 !== index && mobile)) ? { display: 'none' } : {}}>
                            <div>
                                <div className="heading">our story</div>
                                <div className="body">
                                    As a neurosurgeon, our founder knew that essential nutrients were critical for optimal brain development,
                                    yet the average baby puree only had two or four.
                                </div>
                            </div>
                            <div>
                                <LinkWithReferrer
                                    to="/about-cerebelly"
                                    className="button blue v2"
                                    onClick={() => eventAction('Watch Clicked')}>
                                    Watch
                                </LinkWithReferrer>
                            </div>
                        </div>
                        <div
                            className={`article ${(active < 3 && !mobile) ? 'hidden' : 'show'}`}
                            style={((2 !== index && mobile)) ? { display: 'none' } : {}}>
                            <div>
                                <div className="heading">our science</div>
                                <div className="body">
                                    A baby’s brain doubles in size by its first year, reaches nearly 80% of its adult size by the age of three,
                                    and is nearly fully grown by the age of five.
                                </div>
                            </div>
                            <div>
                                <LinkWithReferrer
                                    to="/science"
                                    className="button blue v2"
                                    onClick={() => eventAction('Explore Clicked')}>
                                    Explore
                                </LinkWithReferrer>
                            </div>
                        </div>
                        <div
                            className={`article ${(active < 4 && !mobile) ? 'hidden' : 'show'}`}
                            style={((3 !== index && mobile)) ? { display: 'none' } : {}}>
                            <div>
                                <div className="heading">our standards</div>
                                <div className="body">
                                    With science and transparency at our core,
                                    we're redefining the baby food industry and are proud to be the first shelf-stable baby food brand to receive the Clean Label ®️ Purity Award.
                                </div>
                            </div>
                            <div>
                                <LinkWithReferrer
                                    to="/parenting-is-an-art/?p=9480"
                                    className="button blue v2"
                                    onClick={() => eventAction('Learn Clicked')}>
                                    Learn
                                </LinkWithReferrer>
                            </div>
                        </div>
                        <ThemeImage src={'homepage3/about-us/leaf-bottom.svg'}
                            alt={''}
                            className={`leaf-bottom ${(mobile || active < 4) ? 'd-none' : 'animate__animated animate__zoomIn animate__delay-1s'}`} />
                    </div>
                </div>
            </div>
        </div>
    );
}
