import { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable'

import Clickable from '@v2/components/common/presentational/clickables/default';
import { LinkWithReferrer } from '@components/common/helpers/link';
import { useIsMobile } from '@components/common/helpers/screen-check';
import { packAndTrack } from '@v2/services/analytics.service';

import styles from '@v2/components/pages/home/home.styles';
import ThemeImage from '@v2/utils/image';

export default function AgeBundles() {
    const [index, setIndex] = useState(0);
    const [animate, setAnimate] = useState(index);
    const mobile = useIsMobile(900);
    const config = {
        delta: 10,
        // preventDefaultTouchmoveEvent: false,
        trackTouch: true,
        trackMouse: false,
        rotationAngle: 0,
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setIndex(index > 0 ? index - 1 : 3),
        onSwipedRight: () => setIndex(index < 3 ? index + 1 : 0),
        ...config,
    });

    const bundles: any = {
        0: {
            age: '5 - 7',
            text: `Strong start. Filling those first bites after breast or formula feeding with the ideal nutrients for seeing, sensing and cognition.`,
            img: `homepage3/product-bundles/5-7_550x420.png`,
            class: `one`,
            url: `/shop/?bundle=5-7-months-bundle`
        },
        1: {
            age: '10 - 11',
            text: `Memories & movement. A nutritional focus on foods that fuel coordination of movement, learning & memory.`,
            img: `homepage3/product-bundles/10-11_550x420.png`,
            class: `two`,
            url: `/shop/?bundle=10-11-months-bundle`
        },
        2: {
            age: '18 - 20',
            text: `Decisions, decisions. Some extra nutrition for your little one's planning & decision-making centers.`,
            img: `homepage3/product-bundles/18-20_550x420.png`,
            class: `three`,
            url: `/shop/?bundle=18-20-months-bundle`
        },
        3: {
            age: '25+',
            text: `Talk the Talk. Optimal nutrient combinations to support development of your child's language processing region.`,
            img: `homepage3/product-bundles/25+_550x420-1.png`,
            class: `four`,
            url: `/shop/?bundle=25-months`
        },
    }

    const eventAction = (name: string) => {
        packAndTrack(
            name,
            [{ 'view': 'Homepage', 'section': 'Product Bundles' }]);
    }

    useEffect(() => {
        setAnimate(index);

        const slider = setTimeout(() => {
            setIndex(index < 3 ? index + 1 : 0);
        }, 6000);
        return () => {
            clearTimeout(slider);
        };
    }, [index]);

    return (
        <div className={`${styles.container} ${styles.ageBundles}`}>
            <div className="page-container">
                <div className="inner-container">
                    <h3>PERSONALIZED NUTRITION made easy</h3>
                    <h2>choose from expertly curated bundles</h2>
                    {!mobile
                    ?
                    <div className="product-bundles">
                    {Object.values(bundles).map((bundle: any, key: number) =>
                        <div key={key} className={`bundle ${bundle.class}`}>
                            <div className="bundle-information">
                                <div className="bundle-header">
                                    <span className="period">{bundle.age}</span>
                                    <span className="months">months old</span>
                                </div>
                                <div className="bundle-description">
                                    {bundle.text}
                                </div>
                                <div className="bundle-button">
                                    <LinkWithReferrer to={bundle.url} onClick={() => eventAction('Shop Now Clicked')} className="button red v2">shop now</LinkWithReferrer>
                                </div>
                            </div>
                            <div className="bundle-image">
                                <ThemeImage src={bundle.img} className="" alt={'Bundle'} />
                            </div>
                        </div>
                    )}
                    </div>
                    :
                    <div className="product-bundles">
                        <div className={`bundle ${bundles[index].class} ${index === animate && 'animate__animated animate__zoomIn'}`} {...swipeHandlers}>
                            <div className="bundle-information">
                                <div className="bundle-header">
                                    <span className="period">{bundles[index].age}</span>
                                    <span className="months">months old</span>
                                </div>
                                <div className="bundle-description">
                                    {bundles[index].text}
                                </div>
                                <div className="bundle-button">
                                    <LinkWithReferrer to={bundles[index].url} onClick={() => eventAction('Shop Now Clicked')} className="button red v2">shop now</LinkWithReferrer>
                                </div>
                            </div>
                            <div className="bundle-image">
                                <ThemeImage src={bundles[index].img} className="" alt={'Bundle'} />
                            </div>
                        </div>
                    </div>
                    }
                    <div className="slide-new-products-dots">
                        {Object.values(bundles).map((bundle: any, key: number) =>
                            <Clickable key={key} onClick={() => setIndex(key)} className={`dot ${index === key && 'active'}`}></Clickable>
                        )}
                    </div>
                    <LinkWithReferrer className="explore" to="/shop/#bundles" onClick={() => eventAction('View All Bundles Clicked')}>view all bundles</LinkWithReferrer>
                </div>
            </div>
        </div>
    );
}
