import { useState, useEffect } from 'react';

export const useIsMobile = (borderWidth?: number) => {
    const [width, setWidth] = useState<number>(window.innerWidth);

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return borderWidth
        ? width <= borderWidth
        : width <= 600;
}