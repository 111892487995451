import { css } from '@emotion/css';
import colors from '@v2/styles/colors';

const style = css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',

    h3: {
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 28,
        lineHeight: '133%',
        letterSpacing: '0.02em',
        marginBottom: 20,
    },

    '.text': {
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 15,
        lineHeight: '170%',
        letterSpacing: '0.01em',
    },

    '.button': {
        margin: '25px 0'
    },

    '.close-modal': {
        cursor: 'pointer',
        color: colors.BLUE,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '100%',
        letterSpacing: '0.01em',
        textDecoration: 'underline',
        display: 'block',
        textAlign: 'center',
    },
});

export default function BuyingForBusiness({ hide, onChange }: { hide: Function, onChange?: Function }) {
    return (
        <div className={style}>
            <h3>Buying for a Business?</h3>

            <div className="text">
                We notice you have quite a large order! Cerebelly products can also be found on <strong>faire.com</strong>, where
                you can purchase our products for your store at wholesale pricing. If you're having any issues checking out, please
                don't hesitate to reach out to <strong><a href='mailto:support@cerebelly.com'>support@cerebelly.com</a></strong>
            </div>

            <a href='https://www.faire.com/brand/b_4uvrmtkxqs' target='_blank' rel="noreferrer" className='button blue'>Visit faire.com</a>

            <span className="close-modal"
                onClick={() => {
                    hide();
                }}>
                Cancel
            </span>
        </div>
    );
}
