import Slider, { Settings } from 'react-slick';
import slickStyle from '@v1/styles/vendor/slick';
import styles from '@v2/components/pages/home/home.styles';
import SurveyLinkBuilder from '@components/common/presentational/buttons/builder';
import SurveyLink from '@components/common/presentational/buttons/survey';
import { useHistoryWithReferrer } from '@utils/history';
import ThemeImage from '@v2/utils/image';
import { packAndTrack } from '@v2/services/analytics.service';

export default function ChooseAdventure() {
    const history = useHistoryWithReferrer();

    var settings: Settings = {
        arrows: true,
        responsive: [
            {
                breakpoint: 9999,
                settings: 'unslick',
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    speed: 500,
                },
            },
        ],
    };

    return (
        <div className={`${styles.container} ${styles.ChooseAdventure}`}>
            <div className="page-container">
                <div className="inner-container">
                    <div className="wrapper">
                        <div className="header">or choose your own adventure</div>
                        <div className={`section-carousel ${slickStyle}`}>
                            <Slider {...settings}>
                                <div className="column">
                                    <div className='bundle-image'>
                                        <ThemeImage src={`homepage3/choose-adventure/quiz.png`} width={234} alt={''} />
                                    </div>
                                    <h4>PERSONALIZED NUTRITION FOR YOUR BABY</h4>
                                    <p>Precise, science-backed nutrition based on your child's age and milestones.</p>
                                    <SurveyLink>
                                        <button
                                            className="button blue v2"
                                            aria-label="Take the Quiz"
                                            onClick={() => {
                                                packAndTrack(
                                                    'Take the Quiz Clicked',
                                                    [{ 'view': 'Homepage', 'section': 'Choose Your Own Adventure' }])
                                            }}>
                                            Take the Quiz
                                        </button>
                                    </SurveyLink>
                                </div>
                                <div className="column bundle">
                                    <div className='bundle-image'>
                                        <ThemeImage src={`homepage3/choose-adventure/bundle.png`} width={448} alt={''} />
                                    </div>
                                    <h4>BUILD YOUR OWN BUNDLE</h4>
                                    <p>Pick and choose the right products for your family's unique food journey.</p>
                                    <SurveyLinkBuilder>
                                        <button
                                            className="button blue v2"
                                            aria-label="Build a Bundle"
                                            onClick={() => {
                                                packAndTrack(
                                                    'Build a Bundle Clicked',
                                                    [{ 'view': 'Homepage', 'section': 'Choose Your Own Adventure' }])
                                            }}>
                                            Build a Bundle
                                        </button>
                                    </SurveyLinkBuilder>
                                </div>
                                <div className="column">
                                    <div className='bundle-image'>
                                        <ThemeImage src={`homepage3/choose-adventure/shop-all.png`} width={305} alt={''} />
                                    </div>
                                    <h4>PUREES, SMART BARS, VARIETY PACKS, AND MORE</h4>
                                    <p>Pick from assortments of Cerebelly faves that prove superior nutrition and taste can go hand in hand.</p>
                                    <button
                                        aria-label="Shop all"
                                        className="button blue v2"
                                        onClick={() => {
                                            history.pushWithReferrer('/shop');
                                            packAndTrack(
                                                'Shop All Clicked',
                                                [{ 'view': 'Homepage', 'section': 'Choose Your Own Adventure' }])
                                        }}>
                                        Shop All
                                    </button>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}