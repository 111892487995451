import { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import useDraggableScroll from 'use-draggable-scroll';
import Select from 'react-select';

import HomePageService from '@v2/services/homepage.service';

import Clickable from '@v2/components/common/presentational/clickables/default';

import styles from '@v2/components/pages/home/home.styles';
import ThemeImage from '@v2/utils/image';
import { LinkWithReferrer } from '@v2/components/common/helpers/link';
import { useGTMItemDispatch } from '@v2/utils/gtm';
import { packAndTrack } from '@v2/services/analytics.service';
import Loader from '@v2/components/common/presentational/loader/loader';
import arrowLeft from '@img/shop/arrow-left.svg';
import logo from '@img/shop/logo-no-letters.png';
import { setEntities } from '@app/store/rtk.entities.store';
import { useEntity } from '@app/store/rtk.entities.selectors';

function StageInfo({ animate, stage }: { animate: boolean, stage: any }) {
    const gtmDispatch = useGTMItemDispatch();

    const eventAction = (name: string) => {
        packAndTrack(
            name,
            [{
                'view': 'Homepage',
                'section': 'Brain Developmental',
                'product': stage.product.title
            }]
        );

        gtmDispatch('view_promotion', stage.product, {
            creative_name: "we_recommend",
            creative_slot: "stage_" + stage.ageMeta,
            location_id: "HP_BRAIN",
        });
    }

    return (
        <div className={`brain-product-container brain-product-info ${animate && 'animated animate__animated'}`}
            {...{ animations: `animate__animated animate__fadeInUp-home animate__delay-04s'}` }}>
            <div className="brain-product-container-description">
                <h2 className="mb-2">{stage.focus}</h2>
                <div className="brain-description mb-0">{stage.description}</div>
            </div>
            {stage.product &&
                <LinkWithReferrer
                    style={{textDecoration: 'none', width: '100%'}}
                    to={`/product/${stage.product.category}/${stage.product.slug}`}
                    onClick={() => eventAction('Shop Now Clicked')}>
                    <div className={`brain-products ${animate && 'animated animate__animated animate__fadeInUp-home'} ${stage.product.category}`}>
                        <div className="image-wrapper">
                            <ThemeImage
                                src={stage.product.image} />
                        </div>
                        <div className="brain-product-info">
                            <div className="recommended ml-0">perfect for this stage:</div>
                            <div className="brain-product-title m-0">{stage.product.title}</div>
                        </div>
                    </div>
                </LinkWithReferrer>
            }
            <LinkWithReferrer
                to={`/survey?back=1`}
                onClick={() => eventAction('Get Personalized Bundle Clicked')}
                className="brain-product-container-cta-desktop button red v2 py-1 px-4">
                get your personalized bundle
            </LinkWithReferrer>
            <LinkWithReferrer
                to={`/survey?back=1`}
                onClick={() => eventAction('Get Personalized Bundle Clicked')}
                className="brain-product-container-cta-tablet button red v2 py-1 px-2">
                get personalized bundle
            </LinkWithReferrer>
        </div>
    );
}

export default function Growing({ isBuilder, title, description }: { isBuilder?: boolean, title?: string, description?: string }) {
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [index, setIndex] = useState(0);
    const [animate, setAnimate] = useState(index);
    const [rendered, setRendered] = useState([]);
    const [toggleStageDescription, setToggleStageDescription] = useState(false);
    const { onMouseDown } = useDraggableScroll(ref, { direction: 'horizontal' });
    const [options, setOptions] = useState([]) as any;

    const homepageData = useEntity('homepage');
    const stages: any = homepageData && homepageData.stages ? Object.values(homepageData.stages) : [];
    const dataLength = stages ? Object.keys(stages).length - 1 : 0;
    const currentShowIndex = index % stages.length;
    const offsetWidth = document.getElementById('brain_stages_wrapper')
        ? document.getElementById('brain_stages_wrapper').offsetWidth
        : 0;

    // const sliderOffset = 1340 - (offsetWidth / 2) + (index !== currentShowIndex ? currentShowIndex * 65 : 0);

    const eventSliderAction = (age: string) => {
        packAndTrack(
            `${age} Months Clicked`,
            [{ 'view': 'Homepage', 'section': 'Brain Developmental' }]);
    };

    useEffect(() => {
        setAnimate(index);

        if (stages && stages.length) {
            ref.current.scrollTo(((index % stages.length) * 65) - (offsetWidth / 2) + 1340, 0);
        }
    }, [index]);

    useEffect(() => {
        const selection: any = [];

        if (stages && stages.length) {
            for (let i = 0; i < 3; i++) {
                stages.forEach((stage: any) => selection.push(stage));
            }

            setIndex(stages.length + 1);
            setRendered(selection);
        }
    }, [stages.length]);


    useEffect(() => {
        if (homepageData || !isBuilder) {
            return;
        }

        HomePageService.getCached()
            .then(response => dispatch(setEntities({'homepage': response})))
            .catch(err => {
                HomePageService.get()
                    .then(response => dispatch(setEntities({'homepage': response})))
                    .catch(err => console.error('Header info failed', err));
            });
    }, []);

    useEffect(() => {
        if (stages) {
            let data = {} as any;

            Object.keys(stages).forEach(key => {
                data[key] = {
                    'label': stages[key].ageMeta + ' months',
                    'value': key
                }
            });

            setOptions(Object.values(data));
        }
    }, [stages.length]);

    const getCorrectStageIndex = (id: number) => {
        return stages.findIndex((item:any) => item.id === id);
    }
    return (
        <div className={`${styles.container} ${styles.growing}`} style={{ zIndex: 2 }}>
            <div className="page-container">
                <div className="inner-container">
                    <h3>did you know</h3>
                    <h2 className="dev-stage">
                        {title
                            ? title
                            : <>your baby’s brain is <br />nearly 80% fully grown by age 3</>
                        }
                    </h2>
                    {description
                        ? <p>{description}</p>
                        :
                        <>
                            <div className="subheader d-none d-sm-block">
                                Supporting the most important stages of their brain development in these early years has a lifelong impact.<br />
                                Cerebelly provides 16 key nutrients their growing brain needs, when they need it.
                            </div>
                            <div className="subheader d-sm-none">
                                Supporting the most important stages of their brain development in these early years has a lifelong impact.
                                Cerebelly provides 16 key nutrients their growing brain needs, when they need it.
                            </div>
                        </>
                    }
                    <div className="brain-section">
                        {dataLength > 0
                            ?
                            <>
                                <div className="brain-section-description mb-9 w-100 px-0">
                                    <div className="brain-section-description-title mb-1">your baby's brain at...</div>
                                    {options &&
                                        <Select
                                            options={options}
                                            /* @ts-ignore */
                                            value={options.find(obj => obj.value === index)}
                                            onChange={(e: any) => setIndex(e.value)}
                                            className={`w-100`}
                                            classNamePrefix="select" />
                                    }
                                </div>
                                <div
                                    className={`brain-wrapper ${animate === index && 'animated animate__animated'} ${toggleStageDescription ? 'brain-stage-toggle' : ''}`}
                                    {...{ animations: `animate__animated animate__zoomIn ${index === 0 ? 'animate__delay-07s' : 'animate__delay-03s'}` }}>
                                    <div className="brain-wrapper-title mb-0">your baby's brain at...</div>
                                    <div className="brain-ruller mb-3">
                                        <div className="brain-ruller-arrow-circle left"
                                            onClick={() => setIndex(index => 0 < index ? index - 1 : stages.length - 1)}>
                                            <span className="brain-ruller-arrow left"></span>
                                        </div>
                                        <div className="brain-stages-control" ref={ref} onMouseDown={onMouseDown} id={'stage-control'}>
                                            <div id="brain_stages_wrapper"
                                                className="brain-stages-wrapper w-100">
                                                {rendered.map((stage: any, currentIndex: number) => {
                                                    let classDefault = 'rest';
                                                    if (index - 1 === currentIndex || index + 1 === currentIndex) {
                                                        classDefault = 'adjacent'
                                                    } else if (index - 2 === currentIndex || index + 2 === currentIndex) {
                                                        classDefault = 'siblings';
                                                    }

                                                    return (
                                                        <Clickable
                                                            key={currentIndex}
                                                            className={`brain-stages ${currentIndex % stages.length === currentShowIndex ? 'active' : classDefault}`}
                                                            aria-label={`${rendered[currentIndex].ageMeta} months`}
                                                            onClick={() => {
                                                                setIndex(getCorrectStageIndex(stage.id));
                                                                eventSliderAction(stages[currentShowIndex].ageMeta);
                                                            }}>
                                                            <div className="brain-months-wrapper">
                                                                <div className="brain-months-number mb-0">{stage.ageMeta}</div>
                                                                <span className="brain-months-placeholder">{'months'}</span>
                                                            </div>
                                                        </Clickable>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="brain-ruller-arrow-circle right"
                                            onClick={() => setIndex(index => stages.length - 1 > index ? index + 1 : 0)}>
                                            <span className="brain-ruller-arrow right"></span>
                                        </div>
                                    </div>
                                    <div className={`brain-stage-wrapper ${toggleStageDescription ? 'd-none' : ''}`}>
                                        <ThemeImage
                                            src={stages[currentShowIndex]?.image}
                                            className={animate !== index ? 'd-none' : ''} />
                                        <h3 className="brain-stage-title">{stages[currentShowIndex]?.category}</h3>
                                        <h2 className="brain-stage-description">{stages[currentShowIndex].focus}</h2>
                                        <span className="brain-stage-more" onClick={() => setToggleStageDescription(!toggleStageDescription)}>learn more</span>
                                    </div>
                                    <div className={`brain-stage-wrapper ${toggleStageDescription ? '' : 'd-none'}`}>
                                        <img className="mb-3" src={logo} alt="Logo" />
                                        <h3 className="brain-stage-title">{stages[currentShowIndex]?.category}</h3>
                                        <div className="brain-stage-description">{stages[currentShowIndex].description}</div>
                                        <span className="brain-stage-more" onClick={() => setToggleStageDescription(!toggleStageDescription)}>
                                            <img src={arrowLeft} alt="Go Back" />
                                        </span>
                                    </div>
                                </div>
                                <StageInfo
                                    animate={animate === index}
                                    stage={stages[currentShowIndex]} />
                            </>
                            :
                            <Loader />
                        }
                    </div>
                </div>
                <div className="shop-all-wrapper">
                    <LinkWithReferrer to="/shop"
                    className="shop-all explore"
                    onClick={() => packAndTrack(
                        'Shop All Clicked',
                        [{ 'view': 'Homepage', 'section': 'Brain Developmental' }])
                    }>
                shop all
                </LinkWithReferrer>
                </div>
            </div>
            <div className="animated-container" {...{ animationtrigger: 0.8 }}>
                <ThemeImage src={'homepage3/ProdBundle_Ornament_Left1_flipped.png'}
                    alt={''}
                    className="carousel-ornament-left animated invisible"
                    animations="animate__animated animate__fadeInRight" />
            </div>
        </div>
    );
}