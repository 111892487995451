import colors from './colors';

import imagePlusCircle from '@img/shipping/plus-circle.svg';

export default {
    borderRadius: 25,
    padding: '10px 20px',
    border: '2px solid',
    borderCollapse: 'collapse',
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '16px',
    textAlign: 'center',
    letterSpacing: '0.2em',
    cursor: 'pointer',
    transition: '0.2s',
    textDecoration: 'none',

    '&[disabled]': {
        cursor: 'not-allowed',
    },

    '&a': {
        '&:before, &:after': {
            display: 'none'
        }
    },

    '&.hero': {
        fontSize: 20,
        letterSpacing: '0.2em',
        lineHeight: '20px',
        padding: '21px 23px',
        minWidth: 200,
    },

    '&.blue': {
        color: colors.WHITE,
        backgroundColor: colors.BLUE,
        borderColor: colors.BLUE,

        '&:hover, &.active': {
            color: colors.WHITE,
            backgroundColor: colors.NAVY,
            borderColor: colors.NAVY,
        },

        '&[disabled]': {
            backgroundColor: colors.GRAY,
            color: colors.BLACK_LIGHT_65,
        },

        '&:focus': {
            outlineWidth: 2,
            outlineStyle: 'inset',
            outlineColor: colors.NAVY,
        },
    },

    '&.red': {
        color: colors.WHITE,
        backgroundColor: colors.RED,
        borderColor: colors.RED,

        '&:hover, &.active': {
            color: colors.RED,
            backgroundColor: colors.PINK_SHOP_BUTTON,
            borderColor: colors.PINK_SHOP_BUTTON,
        },

        '&[disabled]': {
            backgroundColor: colors.GRAY,
            color: colors.BLACK_LIGHT_65,
        },

        '&:focus': {
            outlineWidth: 2,
            outlineStyle: 'inset',
            outlineColor: colors.RED_DARK,
        },
    },

    '&.pink': {
        color: colors.WHITE,
        backgroundColor: colors.PINK,
        borderColor: colors.PINK,

        '&:hover, &.active': {
            color: colors.WHITE,
            backgroundColor: colors.PINK_DARK,
            borderColor: colors.PINK_DARK,
        },

        '&[disabled]': {
            backgroundColor: colors.GRAY,
            color: colors.BLACK_LIGHT_65,
        },

        '&:focus': {
            outlineWidth: 2,
            outlineStyle: 'inset',
            outlineColor: colors.PINK_DARK,
        },
    },

    '&.navy': {
        color: colors.WHITE,
        backgroundColor: colors.NAVY,
        borderColor: colors.NAVY,

        '&:hover, &.active': {
            color: colors.WHITE,
            backgroundColor: colors.BLUE,
            borderColor: colors.BLUE,
        },

        '&[disabled]': {
            backgroundColor: colors.GRAY,
            color: colors.BLACK_LIGHT_65,
        },

        '&:focus': {
            outlineWidth: 2,
            outlineStyle: 'inset',
            outlineColor: colors.BLUE,
        },
    },

    '&.white': {
        color: colors.BLUE,
        backgroundColor: colors.WHITE,
        borderColor: colors.WHITE,

        '&:hover, &.active': {
            color: colors.WHITE,
            backgroundColor: colors.NAVY,
            borderColor: colors.NAVY,
        },

        '&[disabled]': {
            backgroundColor: colors.GRAY,
            color: colors.BLACK_LIGHT_65,
        },

        '&:focus': {
            outlineWidth: 2,
            outlineStyle: 'inset',
            outlineColor: colors.WHITE,
        },
    },

    '&.secondary': {
        color: colors.BLUE,
        backgroundColor: colors.TRANSPARENT,
        borderColor: colors.BLUE,

        '&:hover, &.active': {
            borderColor: colors.NAVY,
            color: colors.NAVY,
        },

        '&[disable]': {
            color: colors.BLACK_LIGHT_65,
            borderColor: colors.BLACK_LIGHT_20,
        },

        '&.blue': {
            color: colors.NAVY,
            borderColor: colors.NAVY,

            '&:hover, &.active': {
                color: colors.WHITE,
                backgroundColor: colors.NAVY,
            },

            '&:focus': {
                outlineWidth: 2,
                outlineStyle: 'inset',
                outlineColor: colors.BLUE,
            },
        },

        '&.red': {
            color: colors.RED,
            borderColor: colors.RED,

            '&:hover, &.active': {
                color: colors.WHITE,
                backgroundColor: colors.RED,
            },

            '&:focus': {
                outlineWidth: 2,
                outlineStyle: 'inset',
                outlineColor: colors.RED_DARK,
            },
        },

        '&.pink': {
            color: colors.PINK,
            borderColor: colors.PINK,

            '&:hover, &.active': {
                color: colors.WHITE,
                backgroundColor: colors.PINK,
            },

            '&:focus': {
                outlineWidth: 2,
                outlineStyle: 'inset',
                outlineColor: colors.PINK_DARK,
            },
        },
    },

    '&.link': {
        margin: 0,
        padding: 0,
        cursor: 'pointer',
        //textDecoration: 'underline',
        background: 'transparent',
        border: 0,
        borderRadius: 0,
        fontWeight: 600,
        fontSize: 16,
        outline: 'none',
        letterSpacing: '0.01em',
        marginRight: '-0.01em',

        '&.primary': {
            color: colors.NAVY,
            fontSize: 13,
            lineHeight: '20px',
            letterSpacing: '0.2em',
            position: 'relative',
            paddingBottom: '3px',

            '&:before': {
                content: '""',
                position: 'absolute',
                width: 0,
                height: '2px',
                bottom: 0,
                left: '-0.1em',
                backgroundColor: colors.RED,
                transition: 'width 0.3s ease-in-out 0.5s',
            },

            '&:hover::before': {
                width: '100%',
            },

            '&:after': {
                content: '""',
                position: 'absolute',
                width: '100%',
                height: '2px',
                bottom: 0,
                right: '0.1em',
                backgroundColor: colors.RED,
                transition: 'width 0.3s ease-in-out 0s',
            },

            '&:hover::after': {
                width: 0,
            },

            '&:focus': {
                outlineWidth: 2,
                outlineStyle: 'inset',
                outlineColor: colors.BLUE,
            },
        },

        '&.secondary': {
            color: colors.BLUE,
            fontWeight: 600,
            fontSize: '16px',
            lineHeight: '100%',
            letterSpacing: '0.01em',
            textDecoration: 'underline !important',
            textDecorationLine: 'underline',
            textTransform: 'none !important',

            '&:hover': {
                color: colors.NAVY,
            },

            '&:focus': {
                outlineWidth: 2,
                outlineStyle: 'inset',
                outlineColor: colors.BLUE,
            },
        },

        '&.padded': {
            padding: '10px 23px',
        },

        '&.padded-top': {
            padding: '10px 0px',
        },

        '&.icon': {
            textTransform: 'none',
            height: 24,
            fontSize: 15,
            lineHeigh: 15,
            fontWeight: 600,
            letterSpacing: '0.02em',
            color: colors.BLUE,
            position: 'relative',
            paddingLeft: 32,
            overflow: 'hidden',

            '&.icon-circle-plus': {
                backgroundImage: `url(${imagePlusCircle})`,
                backgroundRepeat: 'no-repeat',
            },

            '&:before': {
                content: '""',
                position: 'absolute',
                width: 0,
                height: '1px',
                bottom: 3,
                left: 30,
                backgroundColor: colors.BLUE,
                transition: 'width 0.3s ease-in-out 0s',
            },

            '&:hover::before': {
                width: '100%',
            },
        },
    },

    '&.dark': {
        '&.primary': {
            color: colors.NAVY,
            borderColor: colors.WHITE,
            backgroundColor: colors.WHITE,

            '&:hover, &.active': {
                color: colors.WHITE,
                backgroundColor: colors.NAVY,
                borderColor: colors.NAVY,
            },

            '&[disabled]': {
                color: colors.WHITE_65,
                backgroundColor: colors.BLACK_15,
                borderColor: colors.BLACK_15,
            },
        },

        '&.secondary': {
            color: colors.WHITE,
            borderColor: colors.WHITE,
            backgroundColor: colors.TRANSPARENT,

            '&:hover, &.active': {
                color: colors.NAVY,
                backgroundColor: colors.WHITE,
                borderColor: colors.WHITE,
            },

            '&[disabled]': {
                color: colors.WHITE_65,
                backgroundColor: colors.BLACK_15,
                borderColor: colors.BLACK_15,
            },
        },

        '&.link': {
            '&.primary': {
                color: colors.WHITE,
            },
        },
    },

    '&.width100': {
        minWidth: '100%',
    },

    '&.width75': {
        minWidth: '75%',
    },

    '&.width50': {
        minWidth: '50%',
    },

    '&:focus': {
        outline: 0,
    },
};