function genCbKey(key: string){
    return `cerebelly-${key}`;
}

function getByKey(key: string){
    if (key){
        const cbKey = genCbKey(key);
        return JSON.parse(localStorage.getItem(cbKey));
    }

    return null;
}

function setByKey(key: string, data: object){
    const jsonData = JSON.stringify(data);
    const cbKey = genCbKey(key);

    try {
        localStorage.setItem(cbKey, jsonData);
        return jsonData;
    } catch (ex) {
        console.error(ex);
        return null;
    }
}

function removeByKey(key: string){
    const cbKey = genCbKey(key);
    localStorage.removeItem(cbKey);
}

export {
    getByKey,
    setByKey,
    removeByKey,
}
