import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import SubscriptionEntity, { ISubscription } from '@v2/entities/subscription.entity';

interface SubscriptionState {
    data: ISubscription | null;
}

const initialState: SubscriptionState = {
    data: null,
}

const slice = createSlice({
    name: 'subscription',
    initialState,
    reducers: {
        resetSubscription: (state: SubscriptionState, action: PayloadAction<void>): void => {
            state.data = null;
        },

        setSubscription: (state: SubscriptionState, action: PayloadAction<ISubscription>): void => {
            state.data = action.payload instanceof SubscriptionEntity ? action.payload : SubscriptionEntity.fromJson(action.payload);
        },
    },
});

const subscriptionReducer = slice.reducer;

export const { resetSubscription, setSubscription } = slice.actions;

export default subscriptionReducer;