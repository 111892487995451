import React, { useState, useEffect, lazy } from 'react';
import { useHistoryWithReferrer } from '@v2/utils/history';

import { css } from '@emotion/css';

import { IModalSettings } from '@components/common/presentational/modal/interfaces';
import { mobileOnly } from '@styles/breakpoints';

import { useModal } from '@v2/hooks/modals';
import { TModalWindow } from './interfaces';
import { IUser, IChild, IProduct } from '@interfaces';
import { useUser } from '@app/store/rtk.user.selectors';
import { emptyCart, removeActiveBox } from '@app/store/rtk.cart.store';

import colors from '@styles/colors';
import ProfileService from '@v2/services/profile.service';
import { useModalsSelector } from '@app/store/rtk.modals.selectors';
import { useActiveBox } from '@app/store/cart.selectors';

const AgeBundleModal = lazy(() => import('../../products/age-bundle'));
const CartModal = lazy(() => import('@v2/components/pages/cart/modals/cart'));
const OutOfStockModal = lazy(() => import('../../products/modals/out-of-stock-modal'));
const LoginModal = lazy(() => import('../header/modals/login'));
const ConfirmationModal = lazy(() => import('./common/confirmation'));
const LogoutConfirmationModal = lazy(() => import('./common/logout-confirmation'));
const NotificationModal = lazy(() => import('./common/notification'));
const AutoUpdateOnModal = lazy(() => import('@v2/components/pages/subscription/modals/auto-update-on'));
const SMSConfirmationModal = lazy(() => import('./common/sms-confirmation'));
const QuizModal = lazy(() => import('@v2/components/pages/quiz/quiz.page'));
const BuilderModal = lazy(() => import('@v2/components/common/builder/body'));
const UpgradeSizeModal = lazy(() => import('../../builder/modals/upgrade-size'));
const HowToCancelModal = lazy(() => import('@v2/components/pages/cart/modals/how-to-cancel'));
const BenefitsModal = lazy(() => import('@v2/components/pages/cart/modals/benefits'));
const ProductModalWithPromise = lazy(() => import('../../products/product-promise'));
const ProductModal = lazy(() => import('../../products/product'));
const VarietyPackModal = lazy(() => import('../../products/variety-pack'));
const VideoModal = lazy(() => import('@v2/components/common/products/modals/video-modal'));
const WarningModal = lazy(() => import('./common/warning'));
const DiscontinuationModal = lazy(() => import('@v2/components/common/products/modals/discontinuation'));
const PricingChangesModal = lazy(() => import('./common/pricing-changes'));

export function useCartModal() {
    const modalsState = useModalsSelector();
    const hasActiveModal = Object.values(modalsState.filter((modal: any) => ['CART2']
                                 .includes(modal.windowType))).length > 0;

    const [showCartModal, hideCartModal] = useModal({
        windowType: 'CART2',
        alignType: 'RIGHT',
        content: {
            component: CartModal,
            props: {}
        },
        scroll: false,
        className: css({
            [mobileOnly]: {
                '.default-close': {
                    display: 'none',
                },

                '.default-close-container': {
                    '.text': {
                        display: 'block !important',
                        position: 'absolute',
                        fontSize: 13,
                        top: 30,
                        right: 40,
                        textDecoration: 'underline',

                        '&:hover': {
                            color: colors.BLUE,
                        }
                    },
                },
            }
        })
    })

    return [hasActiveModal ? () => { } : showCartModal, hideCartModal];
}

export function useAddCartModal(boxIndex?: number) {
    const modalsState = useModalsSelector();
    const hasActiveModal = Object.values(modalsState.filter((modal: any) => ['CART2']
                                 .includes(modal.windowType))).length > 0;

    const [showCartModal, hideCartModal] = useModal({
        windowType: 'CART2',
        alignType: 'RIGHT',
        content: {
            component: CartModal,
            props: {
                hide: () => hideCartModal(),
                boxIndex: boxIndex,
                added: true,
            }
        },
        scroll: false,
    });

    return [hasActiveModal ? () => { } : showCartModal, hideCartModal];
}

export function useOutOfStockModal(product: IProduct) {
    const [showOutOfStockModal, hideOutOfStockModal] = useModal({
        windowType: 'MEDIUM',
        alignType: 'CENTER',
        content: {
            component: OutOfStockModal,
            props: {
                product: product,
            }
        },
    })

    return [showOutOfStockModal, hideOutOfStockModal];
}

export function useLoginModal(saveCart?: boolean) {
    const user = useUser();
    const [showSMSConfirmationModal] = useSMSModal(user);
    const [isLogged, setIsLogged] = useState(false);
    const [showLoginModal, hideLoginModal] = useModal({
        windowType: 'SMALL',
        alignType: 'CENTER',
        content: {
            component: LoginModal,
            props: {
                hide: function () {
                    hideLoginModal();
                },
                smsModal: function () {
                    setIsLogged(true);
                },
                saveCart: saveCart
            }
        },
    });

    useEffect(() => {
        if (isLogged && user && user.notifications && user.notifications.sms === null) {
            showSMSConfirmationModal();
            ProfileService.updateSMSNotification(false);
        }
    }, [isLogged]);

    return [showLoginModal, hideLoginModal];
}


export function useConfirmationModal(
    { message, confirm, deny, title, size, closeable, alignLeft, className }
        : { message: string, confirm: Object, deny: Object, title?: string, size?: TModalWindow, closeable?: boolean, alignLeft?: boolean, className?: string }
) {
    const [showConfirmationModal, hideConfirmationModal] = useModal({
        windowType: size ? size : 'SMALL',
        alignType: 'CENTER',
        content: {
            component: ConfirmationModal,
            props: {
                title: title,
                message: message,
                confirm: confirm,
                deny: deny,
                alignLeft: alignLeft,
                className: className,
            }
        },
        closeButtonOutside: true,
        closeable: closeable
    });

    return [showConfirmationModal, hideConfirmationModal];
}

export function useLogout() {
    const history = useHistoryWithReferrer();
    const [
        showLogoutConfirmation,
        hideLogoutConfirmation,
    ] = useModal({
        windowType: 'SMALL',
        alignType: 'CENTER',
        content: {
            component: LogoutConfirmationModal,
            props: {
                title: 'Log Out',
                message: 'Are you sure you want to logout?',
                confirm: {
                    text: 'Log Out',
                    action: () => {
                        history.pushWithReferrer('/logout');
                        hideLogoutConfirmation();
                    },
                },
                deny: {
                    text: 'Cancel',
                    action: () => hideLogoutConfirmation(),
                },
            }
        },
        closeButtonOutside: true,
        closeable: true,
    });

    return (e: React.MouseEvent) => {
        e.preventDefault();
        showLogoutConfirmation();
    };
}

export function useNotificationModal(
    { message, readonly, size }
        : { message: string, readonly?: boolean, size?: TModalWindow }
) {
    const [showNotificationModal, hideNotificationModal] = useModal({
        windowType: size ? size : 'SMALL',
        alignType: 'CENTER',
        content: {
            component: NotificationModal,
            props: {
                message: message,
                readonly: readonly,
                hide: () => hideNotificationModal(),
            }
        },
        closeButtonOutside: true
    });

    return [showNotificationModal, hideNotificationModal];
}

export function useRecommendedPackModal({ box, confirm, notify }: { box: IChild, confirm: Function, notify?: boolean }) {
    const [showRecommendedPack, hideRecommendedPack] = useModal({
        windowType: 'MEDIUM',
        alignType: 'CENTER',
        heightType: 'TALL',
        scroll: false,
        classes: ['bg-beige', 'personalized-box'],
        content: {
            component: AutoUpdateOnModal,
            props: {
                box: box,
                confirm: () => confirm(),
                notify: notify ? true : false
            },
        },
    });

    return [showRecommendedPack, hideRecommendedPack];
}

function useSMSModal(user: IUser) {
    const [showSMSConfirmationModal, hideSMSConfirmationModal] = useModal({
        windowType: 'MEDIUM',
        alignType: 'CENTER',
        content: {
            component: SMSConfirmationModal,
            props: {
                user: user,
                hide: () => hideSMSConfirmationModal(),
            }
        },
        classes: [css({
            backgroundColor: colors.PINK_LIGHT
        })]
    });

    return [showSMSConfirmationModal, hideSMSConfirmationModal];
}

export function surveyModalSettings(): IModalSettings {
    return {
        windowType: 'SHOP',
        alignType: 'CENTER',
        heightType: 'FULL',
        scroll: false,
        closeable: false,
        content: {
            component: QuizModal,
            props: {
                reset: true,
            }
        }
    }
}

export function useQuizModal() {
    const history = useHistoryWithReferrer();

    return [() => history.pushWithReferrer('/survey')];
}

export function useBuildBundleModal() {
    const history = useHistoryWithReferrer();

    return [() => history.pushWithReferrer('/box-builder')];
}

export function useEditBundleModal() {
    return useBuilderModal(true, null, true);
}

export function useBuilderModalSettings(): ((isActive?: boolean, reset?: boolean, isEditing?: boolean) => IModalSettings) {
    const activeBox = useActiveBox();

    return (isActive?: boolean, reset?: boolean, isEditing?: boolean) => {
        return {
            windowType: 'SHOP',
            alignType: 'CENTER',
            heightType: 'FULL',
            scroll: false,
            content: {
                component: BuilderModal,
                props: {
                    isActive: isActive,
                    reset: reset,
                    isEditing: isEditing ? true : false,
                }
            },
            onClose: ({ dispatch, props }: { dispatch: any, props: any }) => {
                if (activeBox?.incomplete) {
                    dispatch(removeActiveBox());
                }

                if (props.isEditing) {
                    dispatch(emptyCart());
                }
            },
            closeable: false
        };
    }
}

function useBuilderModal(isActive?: boolean, reset?: boolean, isEditing?: boolean) {
    const builderModalSettings = useBuilderModalSettings();

    return useModal(builderModalSettings(isActive, reset, isEditing));
}

export function useUpgradeBoxModal(addToCart: Function, sizePicker: Function) {
    const [showUpgradeBoxModal] = useModal({
        windowType: 'SMALL',
        alignType: 'CENTER',
        content: {
            component: UpgradeSizeModal,
            props: {
                addToCart: () => addToCart(),
                sizePicker: () => sizePicker(),
            }
        },
    });

    return [showUpgradeBoxModal];
}

export function useSwitchToCustomModal(confirmAction: Function, childName?: string) {
    const [showSwitchToCustomModal, hideSwitchToCustomModal] = useModal({
        windowType: 'SMALL',
        alignType: 'CENTER',
        content: {
            component: ConfirmationModal,
            props: {
                title: 'Switch to Custom Bundle?',
                message: `By editing ${childName ? `${childName}'s` : 'the'} recommendation you are converting it to a custom bundle.
                      You can always go back to the recommendation.
                      Recommended products are marked with a star (&#9733;).`,
                confirm: {
                    text: 'CONFIRM',
                    action: async () => {
                        await confirmAction();
                    },
                },
                deny: {
                    text: 'Nevermind',
                    action: () => {
                        hideSwitchToCustomModal();
                    },
                },
            }
        },
        closeable: false
    });

    return [showSwitchToCustomModal, hideSwitchToCustomModal];
}

export function useHowToCancelModal() {
    const [showHowToCancelModal] = useModal({
        className: 'how-to-cancel-subscription',
        windowType: 'LARGE',
        alignType: 'BOTTOM',
        content: {
            component: HowToCancelModal,
            props: {}
        },
    });

    return [showHowToCancelModal];
}

export function useBenefitsModal() {
    const [showBenefitsModal] = useModal({
        windowType: 'LARGE',
        alignType: 'BOTTOM',
        content: {
            component: BenefitsModal,
            props: {}
        },
    });

    return [showBenefitsModal];
}

export function pdpModalPromiseSettings(promise: Promise<IProduct>, hideCallback?: (() => void), disabled?: boolean, playVideo?: boolean, isNewBox?: boolean): IModalSettings {
    return {
        windowType: 'PDETAILS',
        alignType: 'CENTER',
        onClose: hideCallback,
        content: {
            component: ProductModalWithPromise,
            props: {
                promise: promise,
                disabled: disabled,
                playVideo: playVideo,
                isNewBox: isNewBox,
            }
        },
    };
}

function pdpModalSettings(id: number, hideCallback?: (() => void), disabled?: boolean, playVideo?: boolean, isNewBox?: boolean): IModalSettings {
    return {
        windowType: 'PDETAILS',
        alignType: 'CENTER',
        onClose: hideCallback,
        content: {
            component: ProductModal,
            props: {
                id: id,
                disabled: disabled,
                playVideo: playVideo,
                isNewBox: isNewBox,
            }
        },
    };
}

export function usePDPModal(id: number, hideCallback?: (() => void), disabled?: boolean, playVideo?: boolean, isNewBox?: boolean) {
    const [showPDPModal, hidePDPModal] = useModal(pdpModalSettings(id, hideCallback, disabled, playVideo, isNewBox));

    return [showPDPModal, hidePDPModal];
}

export function pdpAgeBundleModalSettings(bundle: Promise<IProduct>, readonly?: boolean): IModalSettings {
    return {
        windowType: 'PDETAILS',
        alignType: 'CENTER',
        content: {
            component: AgeBundleModal,
            props: {
                bundle: bundle,
                readonly: readonly
            }
        },
    };
}

export function pdpVarietyPackModalSettings(bundle: Promise<IProduct>): IModalSettings {
    return {
        windowType: 'PDETAILS',
        alignType: 'CENTER',
        content: {
            component: VarietyPackModal,
            props: {
                bundle: bundle,
            }
        },
    };
}

export function useAgeBundlePDPModal(bundle: IProduct, readonly?: boolean) {
    return useModal(pdpAgeBundleModalSettings(Promise.resolve(bundle), readonly));
}

export function usePDPVideoModal(video: string, key?: string) {
    const [showModal] = useModal({
        windowType: 'VIDEO',
        alignType: 'CENTER',
        heightType: 'FULL',
        content: {
            component: VideoModal,
            props: {
                video: video,
                key: key,
            },
        },
    });

    return [showModal];
}

export function useWarningModal() {
    const [showModal, hideModal] = useModal({
        windowType: 'SMALL',
        alignType: 'CENTER',
        content: {
            component: WarningModal,
            props: {
                hide: () => hideModal(),
            }
        },
        closeButtonOutside: true
    });

    return [showModal, hideModal];
}

export function useDiscontinuationModal() {
    const [show, hide] = useModal({
        windowType: 'SMALL',
        alignType: 'CENTER',
        scroll: false,
        content: {
            component: DiscontinuationModal,
            props: {
                hide: () => hide(),
            },
        },
    });

return [show, hide];
}

export function usePricingChangesModal(subscribed: boolean) {
    const [showModal, hideModal] = useModal({
        windowType: 'MEDIUM',
        alignType: 'CENTER',
        content: {
            component: PricingChangesModal,
            props: {
                subscribed: subscribed,
            },
        },
        closeButtonOutside: false
    })

    return [showModal, hideModal];
}