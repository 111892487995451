export const BOX_CADENCE = {
    TWO_WEEKS: '2 weeks',
    FOUR_WEEKS: '4 weeks',
};

export const BOX_CADENCE_OPTIONS = [
    { value: BOX_CADENCE.TWO_WEEKS, label: 'Receive every 2 weeks' },
    { value: BOX_CADENCE.FOUR_WEEKS, label: 'Receive every 4 weeks' },
];

export const BOX_CADENCE_SHORT_OPTIONS = [
    { value: BOX_CADENCE.TWO_WEEKS, label: BOX_CADENCE.TWO_WEEKS },
    { value: BOX_CADENCE.FOUR_WEEKS, label: BOX_CADENCE.FOUR_WEEKS },
];

export const BOX_CADENCE_CART_OPTIONS = [
    { value: BOX_CADENCE.TWO_WEEKS, label: 'Every 2 weeks' },
    { value: BOX_CADENCE.FOUR_WEEKS, label: 'Every 4 weeks' },
    { value: 'none', label: 'One-time purchase' },
];