import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useHistoryWithReferrer } from '@utils/history';
import Clickable from '@v2/components/common/presentational/clickables/default';

import { LinkWithReferrer } from '@components/common/helpers/link';
import { useLoginModal, useLogout } from '@components/common/presentational/modal/common';
import { CartButton } from '@components/common/presentational/header/cart';
import MyAccountMenu from '@components/common/presentational/header/partial/my-account-menu';

import PromoBar from '@components/common/presentational/promo/promo-bar';
import OOSBar from '@components/common/presentational/oos/oos-bar';

import styles from './header.styles';
import stylesV2 from '@styles/wrapper';

import cerebellyBrainLogo from '@img/header/cerebelly-brain-logo.svg';
import iconLoginMobile from '@img/homepage2/login-mobile-icon.svg';

import { DropdownLearn, DropdownShop } from '@components/common/presentational/header/header-dropdowns';
import { ISubscription } from '@v2/entities/subscription.entity';
import { useIsLogged } from '@app/store/rtk.user.selectors';
import { routeInList } from '@v2/utils/routeInList';

function routeClass(pathname: string): string {
    // start with `:` if you want exact match
    const classes: { [key: string]: Array<string> } = {
        // 'b-header-default': ['/products'],
        'b-header-empty': ['/landing/why-cerebelly', '/checkout'],
        'b-header-plp': ['/box-builder', '/survey', '/products', '/product', '/bundle', '/shop'],
        //'b-header-pdp': ['/product'],
        'b-header-home': [':/', '/landing/home'],
    }

    let headerClass = null;
    for (const className in classes) {
        if (routeInList(classes[className], pathname)) {
            headerClass = className;

            // return the first occurance
            break;
        }
    }

    return headerClass;
}

export function hasPromoBar(isLogged: boolean, pathname: string, promo: any) {

    const hiddenRoutes = [
        '/parenting-is-an-art',
        '/cancellation',
        '/blog',
    ];

    return !isLogged && !routeInList(hiddenRoutes, pathname) && promo && promo.text && promo.text.length;
}

export function hasOOSBar(isLogged: boolean, pathname: string, subscription: ISubscription) {

    const hiddenRoutes = [
        '/parenting-is-an-art',
        '/cancellation',
        '/blog',
    ];

    return isLogged && !routeInList(hiddenRoutes, pathname) && pathname !== '/' && subscription?.oos;
}

function HeaderContent({ routeClassName }: { routeClassName: string }) {
    const isLogged = useIsLogged();
    const history = useHistoryWithReferrer();
    const logout = useLogout();

    const [showLoginModal] = useLoginModal();
    const [dropdown, setDropdown] = useState(null);

    const DROPDOWN_SHOP = 'shop';
    const DROPDOWN_LEARN = 'learn';

    if (localStorage.getItem('openLoginForm') === '1') {
        localStorage.setItem('openLoginForm', '0');
        showLoginModal();
    }

    function toggleDropdown(menu: string, mobile?: boolean) {
        setDropdown(menu === dropdown ? null : menu);
        if (mobile) {
            if (menu === dropdown) {
                document.body.classList.remove('m-overflow-hidden-nav');
            } else {
                document.body.classList.add('m-overflow-hidden-nav');
            }
        }
    }

    function myAccountClick() {
        toggleDropdown('user');
    }

    function closeDropdown() {
        setDropdown(null);
        document.body.classList.remove('m-overflow-hidden-nav');
    }

    return (
        <>
            <header id="cb-header"
                    className={`b-header fw-header
                                ${styles.container}
                                ${stylesV2.v2Global}
                                ${routeClassName}
                                ${dropdown !== null ? 'dropdown-opened' : 'dropdown-closed'}`}>
                <PromoBar />
                <div className="e-header-inner">
                    <nav className="e-nav">
                        <LinkWithReferrer
                            aria-label="go to home page"
                            className="e-logo-link desktop"
                            to="/"
                            tabIndex={-1}>
                            <img className="e-logo" src={cerebellyBrainLogo} alt={''} width={168} height={31} />
                        </LinkWithReferrer>
                        <div className="e-left">
                            <Clickable ariaLabel="hamburder menu" className="e-mobile-nav-open-close-wrap"
                                onClick={() => toggleDropdown(DROPDOWN_SHOP, true)}>
                                <div className="e-mobile-nav-open-btn">
                                    <div className="e-line-wrap">
                                        <div className="e-line"></div>
                                        <div className="e-line"></div>
                                        <div className="e-line"></div>
                                    </div>
                                </div>
                            </Clickable>
                            {/*
                            <span
                                className={`e-nav-link ${dropdown === DROPDOWN_SHOP ? 'active' : ''}`}
                                role="link"
                                tabIndex={0}
                                onKeyDown={(e: any) =>{
                                    if (e.key === "Enter" || e.code === "Space") {
                                        history.pushWithReferrer("/products");
                                        e.preventDefault();
                                    }
                                }}
                                onClick={e => {
                                    toggleDropdown(DROPDOWN_SHOP);
                                    setLoggedMenu(false);
                                }}>
                                Shop
                            </span>
                            */}
                            <LinkWithReferrer onClick={closeDropdown} className="e-nav-link" to="/shop" tabIndex={0}>
                                Shop
                            </LinkWithReferrer>
                            <span
                                className={`e-nav-link ${dropdown === DROPDOWN_LEARN ? 'active' : ''}`}
                                role="link"
                                tabIndex={0}
                                onKeyDown={(e: any) => {
                                    if (e.key === "Enter" || e.code === "Space") {
                                        history.pushWithReferrer("/about-cerebelly");
                                        e.preventDefault();
                                    }
                                }}
                                onClick={e => {
                                    toggleDropdown(DROPDOWN_LEARN);
                                }}>
                                Learn
                            </span>
                            <LinkWithReferrer onClick={closeDropdown} className="e-nav-link"to="/reviews" tabIndex={0}>
                                Reviews
                            </LinkWithReferrer>
                            <LinkWithReferrer onClick={closeDropdown} className="e-nav-link" to="/parenting-is-an-art" tabIndex={0}>
                                Blog
                            </LinkWithReferrer>
                            <LinkWithReferrer onClick={closeDropdown} className="e-nav-link" to="/gift" tabIndex={0}>
                                Gift
                            </LinkWithReferrer>
                            <LinkWithReferrer onClick={closeDropdown} className="e-nav-link" to="/rewards" tabIndex={0}>
                                Rewards
                            </LinkWithReferrer>
                        </div>
                        <LinkWithReferrer aria-label="home page" className="e-logo-link mobile" to="/" tabIndex={-1}>
                            <img className="e-logo" src={cerebellyBrainLogo} alt={''} width={150} height={32} />
                        </LinkWithReferrer>
                        <div className="e-right">
                            {
                                isLogged
                                    ?
                                    <div className="e-right-inner">
                                        <MyAccountMenu
                                            onClick={myAccountClick}
                                            dropdown={dropdown}
                                            isLogged={isLogged} />
                                    </div>
                                    :
                                    <div className="e-right-inner">
                                            <Clickable className="e-nav-link d-none d-sm-flex" onClick={showLoginModal}>Log in</Clickable>
                                            <Clickable className={`e-nav-link e-my-account d-flex d-sm-none`} onClick={showLoginModal}>
                                                <img src={iconLoginMobile} alt={'Login'} />
                                            </Clickable>
                                    </div>
                            }
                            <CartButton/>
                        </div>
                        <div className="e-account-links-dropdown">
                            {isLogged ? (
                                <LinkWithReferrer
                                    className="e-nav-link"
                                    to="/logout"
                                    tabIndex={0}
                                    onClick={logout}
                                >
                                    Log out
                                </LinkWithReferrer>
                            ) : (
                                <Clickable className="e-nav-link" onClick={showLoginModal}>Log in</Clickable>
                            )}
                        </div>
                    </nav>
                </div>
                <OOSBar/>
            </header>
            <div className={styles.dropdownContainer}>
                {
                    dropdown &&
                    <>
                        <div className="header-overlay"
                            onClick={closeDropdown}></div>
                        {
                            (dropdown === DROPDOWN_SHOP) &&
                            <DropdownShop
                                closeDropdown={closeDropdown}
                                login={() => {
                                    setDropdown(null);
                                    showLoginModal();
                                }} />
                        }
                        {
                            (dropdown === DROPDOWN_LEARN) &&
                            <DropdownLearn closeDropdown={closeDropdown} />
                        }
                    </>
                }
            </div>
        </>
    );
}

function HeaderBody() {
    const location = useLocation();
    // routes that use their own headers
    const hiddenRoutes = ['/cancellation', '/landing/nourishment-for-every-stage-of-growth'];

    return (
        <>
            {!routeInList(hiddenRoutes, location.pathname) &&
                <HeaderContent
                    routeClassName={routeClass(location.pathname)} />
            }
        </>
    );
}

export default function Header() {
    return (
        <HeaderBody />
    );
}
