import { css } from '@emotion/css'

export default css(`
    .slick-list {
      position: relative;
      display: block;
      overflow: hidden;
      margin: 0;
      padding: 0;
    }
    .slick-list:focus {
      outline: 0;
    }

    .slick-slider {
      position: relative;
      display: block;
      box-sizing: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-touch-callout: none;
      -khtml-user-select: none;
      -ms-touch-action: pan-y;
      touch-action: pan-y;
      -webkit-tap-highlight-color: transparent;
    }
    .slick-slider .slick-list {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
    .slick-slider .slick-track {
      -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
      -o-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    .slick-track {
      position: relative;
      display: block;
      top: 0;
      left: 0;
    }
    .slick-track:after {
      display: table;
      content: "";
      clear: both;
    }
    .slick-track:before {
      display: table;
      content: "";
    }

    .slick-loading .slick-slide {
      visibility: hidden;
    }
    .slick-loading .slick-track {
      visibility: hidden;
    }

    .slick-list.dragging {
      cursor: pointer;
      cursor: hand;
    }

    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;
    }
    .slick-slide img {
      display: block;
    }

    [dir=rtl] .slick-slide {
      float: right;
    }

    .slick-slide.slick-loading img {
      display: none;
    }

    .slick-slide.dragging img {
      pointer-events: none;
    }

    .slick-initialized .slick-slide {
      display: block;
    }

    .slick-vertical .slick-slide {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }

    .slick-arrow.slick-hidden {
      display: none;
    }

    .slick-dots {
      position: absolute;
      display: block;
      padding: 0;
      bottom: -25px;
      width: 100%;
      margin: 0;
      list-style: none;
      text-align: center;
    }
    .slick-dots li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;
    }
    .slick-dots li button {
      font-size: 0;
      line-height: 0;
      display: block;
      width: 20px;
      height: 20px;
      padding: 5px;
      cursor: pointer;
      color: transparent;
      border: 0;
      outline: 0;
      background: 0 0;
    }
    .slick-dots li button:before {
      font-family: slick;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 6px;
      line-height: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      content: "•";
      text-align: center;
      opacity: 0.25;
      color: #000;
    }
    .slick-dots li button:focus {
      outline: 0;
    }
    .slick-dots li button:focus:before {
      opacity: 1;
    }
    .slick-dots li button:hover {
      outline: 0;
    }
    .slick-dots li button:hover:before {
      opacity: 1;
    }
    .slick-dots li.slick-active button:before {
      opacity: 0.75;
      color: #000;
    }

    .slick-next {
      position: absolute;
      display: block;
      padding: 0;
      font-size: 0;
      line-height: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: 0;
      background: 0 0;
      right: -25px;
    }
    .slick-next:before {
      font-family: slick;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 20px;
      line-height: 0.5;
      opacity: 0.75;
      color: #fff;
      content: "→";
    }
    .slick-next:focus {
      color: transparent;
      outline: 0;
      background: 0 0;
    }
    .slick-next:focus:before {
      opacity: 1;
    }
    .slick-next:hover {
      color: transparent;
      outline: 0;
      background: 0 0;
    }
    .slick-next:hover:before {
      opacity: 1;
    }

    .slick-prev {
      position: absolute;
      display: block;
      padding: 0;
      font-size: 0;
      line-height: 0;
      top: 50%;
      width: 20px;
      height: 20px;
      -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
      transform: translate(0, -50%);
      cursor: pointer;
      color: transparent;
      border: none;
      outline: 0;
      background: 0 0;
      left: -25px;
    }
    .slick-prev:before {
      font-family: slick;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 20px;
      line-height: 0.5;
      opacity: 0.75;
      color: #fff;
      content: "←";
    }
    .slick-prev:focus {
      color: transparent;
      outline: 0;
      background: 0 0;
    }
    .slick-prev:focus:before {
      opacity: 1;
    }
    .slick-prev:hover {
      color: transparent;
      outline: 0;
      background: 0 0;
    }
    .slick-prev:hover:before {
      opacity: 1;
    }

    .slick-next.slick-disabled:before {
      opacity: 0.25;
    }

    .slick-prev.slick-disabled:before {
      opacity: 0.25;
    }

    [dir=rtl] .slick-prev {
      right: -25px;
      left: auto;
    }
    [dir=rtl] .slick-prev:before {
      content: "→";
    }
    [dir=rtl] .slick-next {
      right: auto;
      left: -25px;
    }
    [dir=rtl] .slick-next:before {
      content: "←";
    }

    .slick-dotted.slick-slider {
      margin-bottom: 30px;
    }`
);