import { css } from '@emotion/css'
import colors from '@styles/colors';
import { tabletLandscapeUp, desktopUp, tabletPortraitUp } from "@v2/styles/breakpoints";

export default {
    container: css({
        '&.b-header.fw-header': {
            boxShadow: '0px 3px 7px rgba(0, 0, 0, 0.15)',

            '.b-promo-bar': {
                '> p': {
                    fontSize: 12,
                    letterSpacing: '0.02rem',
                    [tabletLandscapeUp]: {
                        fontSize: 14,
                    }
                }
            },

            '.e-header-inner': {
                height: 50,
                backgroundColor: 'rgba(255, 255, 255)',
                paddingLeft: 0,
                paddingRight: 0,

                [tabletLandscapeUp]: {
                    height: 60,
                    paddingLeft: 30,
                    paddingRight: 30,

                    '.e-right': {
                        display: 'flex',
                    }
                },
            },

            '.e-nav': {
                maxWidth: '100%',

                '.e-right': {
                    paddingRight: 30,

                    [tabletLandscapeUp]: {
                        paddingRight: 0,
                    },

                    button: {
                        display: 'none',

                        [tabletLandscapeUp]: {
                            display: 'flex',
                        }
                    }
                },

                '.mobile-cart-box': {
                    display: 'flex',
                    position: 'relative',
                    cursor: 'pointer',

                    '&.active': {
                        '&:after': {
                            content: `''`,
                            position: 'absolute',
                            top: -2,
                            right: -2,
                            width: 8,
                            height: 8,
                            borderRadius: 5,
                            backgroundColor: colors.PINK,
                        }
                    }
                },

                '.e-nav-link': {
                    display: 'none',
                    position: 'relative',
                    [tabletLandscapeUp]: {
                        display: 'flex',
                    },
                    '&.active': {
                        textDecoration: 'underline',
                    },
                },
            },

            '&.dropdown-opened': {
                //boxShadow: 'none',

                '.e-header-inner': {
                    backgroundColor: `${colors.WHITE} !important`,
                },
            },
        }
    }),

    dropdownContainer: css({
        '.header-overlay': {
            position: 'fixed',
            height: '100vh',
            width: '100vw',
            top: 0,
            left: 0,
            zIndex: 5,
            backgroundColor: colors.BLACK_10,

            [tabletLandscapeUp]: {
                backgroundColor: colors.TRANSPARENT,
            }
        },

        '.mobile-cart-box': {
            display: 'none',
        },

        '.dropdown': {
            position: 'fixed',
            maxWidth: 327,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: colors.WHITE,
            boxShadow: '0px 2px 8px rgba(21, 32, 107, 0.1)',
            overflowY: 'auto',
            zIndex: 99,

            [tabletLandscapeUp]: {
                position: 'fixed',
                flexDirection: 'row',
                maxWidth: '100%',
                animationName: 'none',
                overflow: 'unset',
            },

            '&.shop': {
                '.left': {
                    //backgroundColor: 'rgba(235, 238, 246, 0.5)',

                    [tabletLandscapeUp]: {
                        backgroundColor: 'rgba(235, 238, 246, 0.5)',
                    },

                    a: {
                        backgroundColor: colors.RED,
                        display: 'block',
                        width: '100%',
                        borderRadius: 5,
                        color: colors.WHITE,
                        textTransform: 'uppercase',
                        textAlign: 'center',
                        padding: 14,
                        fontWeight: 500,
                        fontSize: 13,
                        lineHeight: '16px',
                        letterSpacing: '0.2em',
                    }
                },

            },

            '&.learn': {
                '.left': {
                    padding: '30px 10px 30px 40px',
                }
            },

            'a, span': {
                fontWeight: 600,
                width: '100%',
                fontSize: 18,
                lineHeight: '18px',
                letterSpacing: '0.02em',
                padding: '15px 0',
                textDecoration: 'none',
                color: colors.BLUE,
                position: 'relative',
                cursor: 'pointer',
                transition: 'color 0.2s easy',

                [tabletLandscapeUp]: {
                    fontSize: 15,
                    lineHeight: '15px',
                    marginBottom: 23,
                    padding: 0,
                    borderBottom: 'none',
                },
                '&:last-child': {
                    paddingTop: 15,
                    paddingBottom: 0,
                    borderBottom: 'none',
                    [tabletLandscapeUp]: {
                        paddingTop: 0,
                    }
                },
                '&:not(.active):hover': {
                    color: colors.NAVY,
                },
                '&:not(.auto-active):hover': {
                    color: colors.NAVY,
                },
                '&.active': {
                    color: colors.NAVY,
                    marginLeft: 33,
                    width: 'auto',
                    position: 'relative',
                    [tabletLandscapeUp]: {
                        marginLeft: 0,
                    },

                    '> span:first-child': {
                        [tabletLandscapeUp]: {
                            textDecoration: 'underline',
                            color: colors.BLUE,
                            '&:hover': {
                                color: colors.BLUE,
                            }
                        },
                    },
                    '&.has-sub:after': {
                        content: `''`,
                        position: 'absolute',
                        width: 8,
                        height: 8,
                        left: -17,
                        top: '50%',
                        borderTop: `2px solid ${colors.BLUE}`,
                        borderRight: `2px solid ${colors.BLUE}`,
                        transform: 'translateY(-4px) rotate(-135deg)',
                        [tabletLandscapeUp]: {
                            content: `''`,
                            position: 'absolute',
                            width: 8,
                            height: 8,
                            right: 0,
                            left: 'auto',
                            top: '50%',
                            borderTop: `2px solid ${colors.BLUE}`,
                            borderRight: `2px solid ${colors.BLUE}`,
                            transform: 'translateY(-4px) rotate(45deg)',
                        }
                    },
                },
                '&.auto-active': {
                    marginLeft: 0,
                    color: colors.BLUE,
                    '&.has-sub:after': {
                        content: `''`,
                        position: 'absolute',
                        width: 8,
                        height: 8,
                        right: 0,
                        left: 'auto',
                        top: '50%',
                        borderTop: `2px solid ${colors.BLUE}`,
                        borderRight: `2px solid ${colors.BLUE}`,
                        transform: 'translateY(-4px) rotate(45deg)',
                        [tabletLandscapeUp]: {
                            content: `''`,
                            position: 'absolute',
                            width: 8,
                            height: 8,
                            right: 0,
                            left: 'auto',
                            top: '50%',
                            borderTop: `2px solid ${colors.BLUE}`,
                            borderRight: `2px solid ${colors.BLUE}`,
                            transform: 'translateY(-4px) rotate(45deg)',
                        }
                    },
                },

                '&.has-sub':{
                    borderBottom: `1px solid ${colors.BLUE_EXTRA_LIGHT}`,
                    maxWidth: 130,

                    [tabletLandscapeUp]: {
                        borderBottom: 'none',
                    }
                },

                '&.has-sub:after': {
                    content: `''`,
                    position: 'absolute',
                    width: 8,
                    height: 8,
                    right: 0,
                    top: '50%',
                    borderTop: `2px solid ${colors.BLUE}`,
                    borderRight: `2px solid ${colors.BLUE}`,
                    transform: 'translateY(-4px) rotate(45deg)',
                },
                '.new-product-label': {
                    fontWeight: 600,
                    fontSize: 10,
                    lineHeight: '125%',
                    padding: '1px 4px',
                    backgroundColor: colors.MINT,
                    borderRadius: 2,
                    textTransform: 'capitalize',
                    position: 'relative',
                    top: -2,
                }
            },

            '.left': {
                padding: '28px 30px 30px 25px',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                flexShrink: 0,
                [tabletLandscapeUp]: {
                    padding: '30px 30px 30px 40px',
                    width: 204,
                }
            },

            '.right': {
                // padding: `24px 24px 50px 24px`,
                display: 'block',
                [tabletLandscapeUp]: {
                    flexGrow: 1,
                    flexWrap: 'nowrap',
                    display: 'flex',
                    padding: '30px 40px 30px 43px',
                    flexDirection: 'row',
                },

                '.dropdown-sub-menu': {
                    flexDirection: 'column',
                    flexWrap: 'nowrap',
                    backgroundColor: 'rgba(235, 238, 246, 0.5)',
                    maxWidth: '100%',
                    padding: '0 24px 40px 58px',
                    display: 'none',
                    [tabletLandscapeUp]: {
                        flexWrap: 'wrap',
                        display: 'flex',
                        backgroundColor: colors.TRANSPARENT,
                        width: 465,
                        margin: 0,
                        padding: 0,
                    },
                    a: {
                        lineHeight: '145%',
                        width: 'auto',
                        fontSize: 16,
                        padding: 0,
                        marginBottom: 25,
                        borderBottom: 0,
                        display: 'block',
                        [tabletLandscapeUp]: {
                            marginBottom: 8,
                            marginRight: 15,
                            fontSize: 15,
                        },
                        '&:last-child': {
                            marginBottom: 0,
                            // [tabletLandscapeUp]: {
                            //     marginBottom: 0,
                            // }
                        }
                    },
                },

                '.secondary-menu': {
                    padding: '0px 24px 120px 24px',

                    [tabletLandscapeUp]: {
                        padding: 0,
                        flexGrow: 1,
                    }
                },

                '.method-picker': {
                    display: 'block',

                    [tabletPortraitUp]: {
                        display: 'flex',
                        flexDirection: 'column',
                    },

                    [tabletLandscapeUp]: {
                        flexGrow: 1,
                    },

                    [desktopUp]: {
                        flexDirection: 'row',
                    },

                    '.method-title': {
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: 15,
                        lineHeight: '135%',
                        textAlign: 'left',
                        letterSpacing: '0.02em',
                        marginBottom: 5,
                        color: colors.NAVY,
                        [desktopUp]: {
                            textAlign: 'center',
                        }
                    },

                    '.method-image': {
                        textAlign: 'center',
                        maxWidth: 42,
                        padding: '0 15px 0 5px',
                        width: '100%',
                        '@media (min-width: 900px) and (max-width: 999px)': {
                            display: 'none',
                        },

                        [desktopUp]: {
                            maxWidth: 'none',
                        },

                        img: {
                            mixBlendMode: 'multiply',
                            maxWidth: '100%',
                            height: 'auto',
                            margin: '0 auto',
                        },

                        'img.desktop': {
                            display: 'none',
                            [desktopUp]: {
                                display: 'block',
                            }
                        },

                        'img.mobile': {
                            display: 'block',
                            [desktopUp]: {
                                display: 'none',
                            }
                        }
                    },

                    '.method-description': {
                        fontWeight: 600,
                        fontSize: 13,
                        lineHeight: '135%',
                        textAlign: 'left',
                        letterSpacing: '0.01em',
                        color: colors.BLUE,
                        maxWidth: 220,
                        width: '100%',
                        [desktopUp]: {
                            textAlign: 'center',
                        }
                    },

                    '.method':  {
                        backgroundColor: colors.BEIGE_MILESTONE,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        cursor: 'pointer',
                        borderRadius: 10,
                        marginBottom: 15,
                        maxWidth: 322,
                        width: '100%',
                        padding: '25px 5px 25px 23px',

                        '@media (min-width: 900px) and (max-width: 999px)': {
                            padding: '10px',
                        },

                        '.method-image': {
                            paddingLeft: 0,
                        },

                        '.method-title': {
                            fontWeight: 'bold',
                            fontSize: 13,
                            lineHeight: '16px',
                            letterSpacing: '0.2em',
                            textTransform: 'uppercase',
                        },

                        '.method-body': {
                            marginLeft: -4,
                        },

                        'svg': {
                            marginLeft: 11,
                            position: 'relative',
                            top: 1,
                        },

                        '&.method-quiz': {
                            backgroundColor: colors.BEIGE_MILESTONE,

                            [desktopUp]: {
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '10px 10px 15px 10px',
                                marginRight: 24,
                                marginBottom: 0,
                            },

                            '.method-title': {
                                color: colors.ORANGE,
                            },

                            '.method-image': {
                                img: {
                                    maxHeight: 133,
                                }
                            }
                        },

                        '&.method-box-builder': {
                            backgroundColor: colors.BLUE_EXTRA_LIGHT,

                            [desktopUp]: {
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                padding: '20px 10px 20px 10px',
                                marginBottom: 0,
                            },

                            '.method-title': {
                                color: colors.GREEN_BRIGHT,
                            },

                            '.method-image': {
                                marginBottom: 15,
                                img: {
                                    maxHeight: 88,
                                }
                            }
                        },

                        '&.method-gift': {
                            backgroundColor: colors.TEAL_EXTRA_LIGHT,

                            '.method-title': {
                                color: colors.BLUE,
                            },
                        },

                        '&.method-reward': {
                            backgroundColor: colors.PINK_EXTRA_LIGHT,

                            '.method-title': {
                                color: colors.RED,
                            },
                        }
                    }


                },

                '.posts-container': {
                    width: '100%',

                    '.posts': {
                        display: 'flex',
                        flexDirection: 'column',
                        [tabletLandscapeUp]: {
                            flexDirection: 'row',
                        }
                    },

                    '.post': {
                        marginRight: 23,
                        maxWidth: 322,
                        width: '100%',
                        '&:last-child': {
                            marginRight: 0,
                        },

                        'a:first-child': {
                            display: 'block',
                            borderRadius: 5,
                            marginBottom: 5,
                            overflow: 'hidden',
                            '&:hover': {
                                '> div': {
                                    transform: 'scale(1.1, 1.1)',
                                }
                            },

                            '> div': {
                                height: 188,
                                width: '100%',
                                transition: 'all .2s ease-in',
                            }
                        },

                        img: {
                            maxWidth: '100%',
                            width: '100%',
                            height: 'auto',
                            display: 'block',
                            transition: 'all .2s ease-in',
                        },

                        h4: {
                            marginTop: 10,
                            fontWeight: 600,
                            fontSize: 13,
                            lineHeight: '135%',
                        },

                    }
                },
                '.mobile-extra-login': {
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignContent: 'center',
                    alignItems: 'center',

                    [tabletLandscapeUp]: {
                        display: 'none',
                    },

                    span: {
                        color: colors.BLUE,
                        textDecoration: 'underline',
                        fontWeight: 'bold',
                        fontSize: 14,
                        justifyContent: 'center',

                        img: {
                            marginLeft: 5
                        }
                    }
                },
                '.mobile-extra-sub-menu': {
                    display: 'flex',
                    flexDirection: 'row',
                    marginTop: 40,
                    marginBottom: 5,
                    [tabletLandscapeUp]: {
                        display: 'none',
                    },

                    a: {
                        fontSize: 16,
                        fontWeight: 600,
                        lineHeight: '130%',
                        letterSpacing: '0.02em',
                        marginBottom: 20,
                        padding: 0,
                        borderBottom: 'none',
                        '&:last-child': {
                            marginBottom: 0,
                        }
                    },

                    '.left-col': {
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '0 0 50%',
                        width: '50%',
                        paddingRight: 10,
                    },

                    '.right-col': {
                        display: 'flex',
                        flexDirection: 'column',
                        flex: '0 0 50%',
                        width: '50%',
                        paddingRight: 10,

                        span: {
                            borderBottom: 0,
                            fontWeight: 600,
                            fontSize: 16,
                            lineHeight: '16px',
                            letterSpacing: '0.02em',
                            marginBottom: 20,
                            padding: 0,
                        },
                    },
                },

                'button.secondary': {
                    display: 'block',
                    width: '100%',
                    border: `2px solid ${colors.PINK}`,
                    borderRadius: 5,
                    fontSize: 13,
                    lineHeight: '16px',
                    paddingTop: 16,
                    paddingBottom: 13,
                    textTransform: 'uppercase',
                    letterSpacing: '0.2em',
                    backgroundColor: colors.TRANSPARENT,
                    color: colors.PINK,
                    transition: 'color 0.2s ease, background-color 0.2s ease',
                    '&:hover': {
                        color: colors.WHITE,
                        backgroundColor: colors.PINK,
                    },

                    [tabletLandscapeUp]: {
                        display: 'none',
                    }
                }
            },

            '&.has-active': {
                '.right': {
                    '.dropdown-sub-menu': {
                        display: 'block',
                        [tabletLandscapeUp]: {
                            animationName: 'none',
                            display: 'flex',
                        }
                    },
                },

                '.left > *:not(.active)': {
                    display: 'none',
                    [tabletLandscapeUp]: {
                        display: 'block',
                    }
                },
            },
        }
    }),
}
