import React, { useState, useEffect } from 'react';

import styles from '@v2/components/pages/home/home.styles';
import ThemeImage from '@v2/utils/image';
import { useIsMobile } from '@v2/components/common/helpers/screen-check';
import { imgV2 } from '@v2/styles/utils';
import { packAndTrack } from '@v2/services/analytics.service';

function BodyDesktop({active, setActive}: {active: number, setActive: any}) {

    return (
        <div className={styles.UsVSThemDesktop}>
            <h2 className="left-slide1 animate__animated animate__fadeInUp-home"
                style={active > 5 ? { display: 'none' } : {}}>
                give your<br/><span>little one</span>
            </h2>
            <h2 className="left-slide2 animate__animated animate__fadeInUp-home"
                style={active !== 6 ? { display: 'none' } : {}}>
                the cerebelly<br/>standard
            </h2>
            <div style={active !== 6 ? { display: 'none' } : {}}>
                <picture>
                    <img src={imgV2('homepage3/us-vs-them/desktop-left.svg')}
                        className="left-ilustration animate__animated animate__zoomIn" alt={''} />
                </picture>
            </div>
            <div style={active !== 6 ? { display: 'none' } : {}}>
                <picture>
                    <img src={imgV2('homepage3/us-vs-them/desktop-right.svg')}
                        className="right-ilustration animate__animated animate__zoomIn" alt={''} />
                </picture>
            </div>
            <ThemeImage
                src={'homepage3/us-vs-them/pouch-blank.png'}
                className={`pouch ${active === 7 ? 'abs right' : 'hide'}`}
                alt={'pouch'}
                width={291}
                height={600} />
            <ThemeImage
                src={'homepage3/us-vs-them/pouch-slide1.png'}
                className={`pouch animate__animated animate__fadeInUp-home ${active === 7 ? 'abs left' : ''}`}
                alt={'pouch'}
                width={291}
                height={600} />
            <h2 className="right-slide1 animate__animated animate__fadeInUp-home"
                style={active > 5 ? { display: 'none' } : {}}>
                a smarter start
            </h2>
            <div className="right-slide2 animate__animated animate__fadeInUp-home" style={active !== 6 ? { display: 'none' } : {}}>
                <p>
                    Our pouches are packed with organic, veggie-first whole ingredients, no added sugar,
                    and proudly honors its Clean Label Project ®️ Certification,
                    a third-party non-profit that tests for contaminants including heavy metals and toxins.
                </p>
                <a className="button red v2"
                    href="/shop/#bundles"
                    onFocus={() => setActive(6)}
                    onClick={() => packAndTrack(
                        'Shop All Clicked',
                        [{ 'view': 'Homepage', 'section': 'Us vs Them' }])
                }>
                    shop now
                </a>
            </div>
            <div className="slide table" style={active !== 7 ? { display: 'none' } : {}}>
                <Table setActive={setActive} />
            </div>
        </div>
    );
}

function CheckBlue() {
    return (<ThemeImage src={'homepage3/us-vs-them/check-navy.svg'} className="pouch" alt={''} />);
}

function CheckGreen() {
    return (<ThemeImage src={'homepage3/us-vs-them/check-green.svg'} className="pouch" alt={''} />);
}

function Table({ setActive }: { setActive?: any }) {
    const mobile = useIsMobile(900);
    const [index, setIndex] = useState(1);

    const compare: any = {
        0: {
            title: 'Cerebelly',
            logo: 'homepage3/us-vs-them/logos/cerebelly.png',
            customMade: <CheckGreen/>,
            nutrients: <CheckGreen/>,
            sugarFree: <CheckGreen/>,
            veggieFirst: <CheckGreen/>,
            cleanLabel: <CheckGreen/>,
            organic: <CheckGreen/>,
        },
        1: {
            title: 'Serenity',
            logo: 'homepage3/us-vs-them/logos/serenity.png',
            customMade: <></>,
            nutrients: <></>,
            sugarFree: <CheckBlue/>,
            veggieFirst: <></>,
            cleanLabel: <CheckBlue/>,
            organic: <></>,
        },
        2: {
            title: 'Once Upon A Farm',
            logo: 'homepage3/us-vs-them/logos/OUAF.png',
            customMade: <></>,
            nutrients: <></>,
            sugarFree: <CheckBlue/>,
            veggieFirst: <></>,
            cleanLabel: <CheckBlue/>,
            organic: <CheckBlue/>,
        },
        3: {
            title: 'Earths Best',
            logo: 'homepage3/us-vs-them/logos/EB.png',
            customMade: <></>,
            nutrients: <></>,
            sugarFree: <></>,
            veggieFirst: <></>,
            cleanLabel: <></>,
            organic: <CheckBlue/>,
        },
        4: {
            title: 'Happy Baby',
            logo: 'homepage3/us-vs-them/logos/HB.png',
            customMade: <></>,
            nutrients: <></>,
            sugarFree: <></>,
            veggieFirst: <></>,
            cleanLabel: <></>,
            organic: <CheckBlue/>,
        },
        5: {
            title: 'Plum',
            logo: 'homepage3/us-vs-them/logos/PO.png',
            customMade: <></>,
            nutrients: <></>,
            sugarFree: <></>,
            veggieFirst: <></>,
            cleanLabel: <></>,
            organic: <CheckBlue/>,
        },
        6: {
            title: 'Sprout',
            logo: 'homepage3/us-vs-them/logos/SP.png',
            customMade: <></>,
            nutrients: <></>,
            sugarFree: <CheckBlue/>,
            veggieFirst: <></>,
            cleanLabel: <></>,
            organic: <CheckBlue/>,
        }
    }

    useEffect(() => {
        const slider = setTimeout(() => {
            setIndex(index < (Object.keys(compare).length - 1) ? index + 1 : 1);
        }, 2000);
        return () => {
            clearTimeout(slider);
        };
    }, [index]);

    return (
        <div className={styles.UsVSThemTable}>
            <div className="content">
                <div className="table-wrapper">
                    <div className="table">
                        <div className='column first'>
                            <div className='t-header'></div>
                            <div className='tr white'>Created by a Practicing Neurosurgeon, Mom of Three
                        </div>
                            <div className='tr gray'>16 Key Brain-Supporting Nutrients</div>
                            <div className='tr white'>Veggie-First ALWAYS</div>
                            <div className='tr gray'>No Added Sugar EVER</div>
                            <div className='tr white'>Clean Label Project® Certified</div>
                            <div className='tr gray'>ALL Certified Organic</div>
                        </div>
                    {Object.values(mobile ? {0: compare[0], 1: compare[index]} : compare).map((row: any, index: any) =>
                        <div key={index} className='column'>
                            <div className='t-header'><ThemeImage src={row.logo} className="pouch" alt={row.title} /></div>
                            <div className='tr white'>{row.customMade}</div>
                            <div className='tr gray'>{row.nutrients}</div>
                            <div className='tr white'>{row.veggieFirst}</div>
                            <div className='tr gray'>{row.sugarFree}</div>
                            <div className='tr white'>{row.cleanLabel}</div>
                            <div className='tr gray'>{row.organic}</div>
                        </div>
                    )}
                </div>
                <div className='t-footer'>
                    <a onFocus={() => setActive?.(7)} className="button secondary link learn-more" href="/parenting-is-an-art/us-vs-them">READ MORE
                </a>
                </div>
                </div>
                <a
                    className="button red v2"
                    href="/shop"
                    onFocus={() => setActive?.(7)}
                    onClick={() => {
                        packAndTrack(
                            'Shop Now Clicked',
                            [{ 'view': 'Homepage', 'section': 'Us vs Them' }])
                    }}>
                    shop now
                </a>
            </div>
        </div>
    );
}

function BodyMobile({active}: {active: number, setActive: any}) {
    return (
        <div className={styles.UsVSThemMobile}>
            {/*
            <div className="slide info animate__animated animate__fadeInUp" style={{display: active === 6 ? 'block' : 'none'}}>
                <h4>the cerebelly standard</h4>
                <h2>give your little one a smarter start
            </h2>
                <p>
                    Our pouches are packed with organic, veggie-first whole ingredients, no added sugar,
                    and proudly honors its Clean Label Project ®️ Certification,
                    a third-party non-profit that tests for contaminants including heavy metals and toxins.
                </p>
                <a className="button red v2" href="/shop/#bundles">shop now
            </a>
            </div>
            <div className="pouch-animation animated-container" {...{ animationtrigger: 0.8 }}>
                <h2 className={`first animate__animated ${active === 5 ? 'animate__fadeInRight' : 'animate__fadeOutDown'}`} style={{display: active > 5 ? 'none' : 'block'}}>
                    give your<br/>little one
                </h2>
                <div className={`ilustrations animate__animated ${active > 3 ? 'slide-down' : ''}`} style={{display: 'flex'}}>
                    <span style={{display: active === 6 ? 'block' : 'none'}}>
                        <ThemeImage src={'homepage3/us-vs-them/mobile-left.svg'}
                            className="left-image animated invisible"
                            animations="animate__animated animate__zoomIn" alt={'left'}/>
                    </span>
                    <ThemeImage src={'homepage3/us-vs-them/pouch-mobile.png'}
                        className="pouch animated invisible"
                        animations="animate__animated animate__zoomIn" alt={'pouch'} />
                    <span style={{display: active === 6 ? 'block' : 'none'}}>
                        <ThemeImage src={'homepage3/us-vs-them/mobile-right.svg'}
                            className="right-image animated invisible"
                            animations="animate__animated animate__zoomIn" alt={'right'}/>
                    </span>
                </div>
                <h2 className={`last animate__animated ${active === 5 ? 'animate__fadeInRight' : 'animate__fadeOutDown'}`} style={{display: active > 5 ? 'none' : 'block'}}>
                    a smart
                er start
                </h2>
            </div>
            */}
            <div className="slide table" style={{/* display: active < 7 ? 'none' : 'block' */}}>
                <h4>the cerebelly standard</h4>
                <h2>give your little one a smarter start

                </h2>
                <p>
                    Our pouches are packed with organic, veggie-first whole ingredients, no added sugar,
                    and proudly honors its Clean Label Project ®️ Certification,
                    a third-party non-profit that tests for contaminants including heavy metals and toxins.
                </p>
                <Table />
            </div>
        </div>
    );
}

function UsVSThemNoMemo({ active, setActive }: { active: number, setActive: any }) {
    const mobile = useIsMobile(600);

    return (
        <>
        {!mobile
        ?
        <div className='desktop'>
            <BodyDesktop active={active} setActive={setActive}/>
        </div>
        :
        <div className='mobile'>
            <BodyMobile active={active} setActive={setActive}/>
        </div>
        }
        </>
    );
}

const UsVSThem = React.memo(UsVSThemNoMemo, function (prev, next) {
    return prev.active === next.active;
});

export default UsVSThem;