import React, { useState, useEffect, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import { ContentLoader } from '@components/common/presentational/loader/loader';
import { LinkWithReferrer } from '@components/common/helpers/link';
import Clickable from '@v2/components/common/presentational/clickables/default';
import { packAndTrack } from '@v2/services/analytics.service';

import ArrowRight from '@components/common/presentational/svg/arrow-right';
import SurveyLink from '@components/common/presentational/buttons/survey';
import BuilderLink from '@components/common/presentational/buttons/builder';
import BlogService, { BlogPost } from '@v2/services/blog.service';
import colors from '@v2/styles/colors';
import { imgV2 as img } from '@styles/utils';
import ThemeImage from '@v2/utils/image';
import { useIsLogged } from '@app/store/rtk.user.selectors';
import { useLogout } from "@components/common/presentational/modal/common";

export function DropdownShop({ login, closeDropdown }: { login: Function, closeDropdown: Function }) {
    const isLogged = useIsLogged();
    const logout = useLogout();

    return (
        <div
            id="dropdown-shop"
            className={`dropdown shop animate__animated animate__slideInLeft`}>
            <ContentLoader loading={false}>
                <div className={`left`}>
                    <LinkWithReferrer onClick={closeDropdown as MouseEventHandler} to="/products">Shop all</LinkWithReferrer>
                </div>
                <div className="right">
                    <div className="secondary-menu">
                        <div className="method-picker">
                            <SurveyLink className="method method-quiz"
                                onClick={() => {
                                    closeDropdown();
                                    packAndTrack('Take Survey Clicked', [{ 'view': 'Nav', 'section': 'Nav' }]);
                                }}>
                                <div className="method-image">
                                    {/*

                                    <img
                                        loading="lazy"
                                        className="desktop"
                                        src={img('box-builder/method-quiz.png')}
                                        alt={''}/>
                                    <img
                                        loading="lazy"
                                        className="mobile"
                                        src={img('homepage2/child-profile.svg')}
                                        alt={''}/>
                                    */}

                                    <ThemeImage src={'box-builder/method-quiz.png'} className="desktop" alt={''} />
                                    <ThemeImage src={'homepage2/child-profile.svg'} className="mobile" alt={''} />
                                </div>
                                <div className="method-body">
                                    <div className="method-title">
                                        Take the quiz

                                        <ArrowRight stroke={colors.ORANGE} />
                                    </div>
                                    <div className="method-description">
                                        Get a personalized bundle
                                    </div>
                                </div>
                            </SurveyLink>

                            <BuilderLink className="method method-box-builder"
                                onClick={() => {
                                    closeDropdown();
                                    packAndTrack('Build Box Clicked', [{ 'view': 'Nav', 'section': 'Nav' }]);
                                }}>
                                <div className="method-image">
                                    <img
                                        loading="lazy"
                                        className="desktop"
                                        src={img('box-builder/method-box.png')}
                                        alt={''} />
                                    <img
                                        loading="lazy"
                                        className="mobile"
                                        src={img('homepage3/method-box-mobile.svg')}
                                        alt={''} />
                                </div>
                                <div className="method-body">
                                    <div className="method-title">
                                        Build a bundle

                                        <ArrowRight stroke={colors.GREEN_BRIGHT} />
                                    </div>
                                    <div className="method-description">
                                        Pick your own flavors
                                    </div>
                                </div>
                            </BuilderLink>

                            <Link to="/gift" className="method method-gift"
                                onClick={() => {
                                    closeDropdown();
                                    packAndTrack('Build Box Clicked', [{ 'view': 'Nav', 'section': 'Nav' }]);
                                }}>
                                <div className="method-image">
                                    <img
                                        loading="lazy"
                                        className="mobile"
                                        src={img('dashboard/referral.svg')}
                                        alt={''} />
                                </div>
                                <div className="method-body">
                                    <div className="method-title">
                                        Order a gift card

                                        <ArrowRight stroke={colors.BLUE} />
                                    </div>
                                    <div className="method-description">
                                        Give the gift of Cerebelly
                                    </div>
                                </div>
                            </Link>

                            <Link to="/rewards" className="method method-reward"
                                onClick={() => {
                                    closeDropdown();
                                    packAndTrack('Smart Rewards Clicked', [{ 'view': 'Nav', 'section': 'Nav' }]);
                                }}>
                                <div className="method-image">
                                    <img
                                        loading="lazy"
                                        className="mobile"
                                        src={img('dashboard/smart-rewards.svg')}
                                        alt={''} />
                                </div>
                                <div className="method-body">
                                    <div className="method-title">
                                        Smart Rewards

                                        <ArrowRight stroke={colors.RED} />
                                    </div>
                                    <div className="method-description">
                                        Let's get rewarded!
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {!isLogged &&
                            <div className="mobile-extra-login">
                                <Clickable onClick={() => login()}>Have an account? Log in here <img src={img('header/login-arrow.svg')} alt=""/></Clickable>
                            </div>
                        }
                        <div className="mobile-extra-sub-menu">
                            <div className="left-col">
                                <LinkWithReferrer onClick={closeDropdown as MouseEventHandler} to="/gift">Gift</LinkWithReferrer>
                                <LinkWithReferrer onClick={closeDropdown as MouseEventHandler} to="/reviews">Reviews</LinkWithReferrer>
                                <LinkWithReferrer onClick={closeDropdown as MouseEventHandler} to="/parenting-is-an-art">Blog</LinkWithReferrer>
                                <LinkWithReferrer onClick={closeDropdown as MouseEventHandler} to="/heavy-metals">Safety & Standards</LinkWithReferrer>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://www.terracycle.com/en-US/brigades/cerebelly">
                                    Recycling Program
                                </a>
                            </div>
                            <div className="right-col">
                                {isLogged && (
                                    <span>
                                        <LinkWithReferrer
                                            to="/logout"
                                            onClick={logout}
                                        >
                                            Log out
                                        </LinkWithReferrer>
                                    </span>
                                )}
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://help.cerebelly.com">FAQ</a>
                                <LinkWithReferrer onClick={closeDropdown as MouseEventHandler} to="/about-cerebelly">Our Story</LinkWithReferrer>
                                <LinkWithReferrer onClick={closeDropdown as MouseEventHandler} to="/science">Science</LinkWithReferrer>
                                <LinkWithReferrer onClick={closeDropdown as MouseEventHandler} to="/rewards">Rewards</LinkWithReferrer>
                            </div>
                        </div>
                        {/* <CTAButton type={'secondary'} color={'red'} withMobile={false} /> */}
                    </div>
                </div>
            </ContentLoader>
        </div>
    );
}

function DropdownLearnBlogposts({ posts }: { posts: BlogPost[] }) {
    return (
        <div className="posts">
            {posts
                ? posts.map((post, key) =>
                    <div className="post" key={key}>
                        <LinkWithReferrer to={`/parenting-is-an-art/${post.path}`}>
                            <div style={{background: `url(${post.image}) center right / cover no-repeat`}}></div>
                        </LinkWithReferrer>
                        <LinkWithReferrer to={`/parenting-is-an-art/${post.path}`}>
                            <h4>{parse(post.title)}</h4>
                        </LinkWithReferrer>
                    </div>
                )
                : <div>No blogposts</div>}
        </div>
    );
}

export function DropdownLearn({ closeDropdown }: { closeDropdown: () => void }) {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts]: [BlogPost[], ((posts: BlogPost[]) => void)] = useState([]);

    useEffect(() => {
        BlogService.randomArticles(3)
            .then(setPosts)
            .finally(() => setLoading(false));
    }, []);

    return (
        <div className="dropdown learn" id="dropdown-learn">
            <div className="left">
                <LinkWithReferrer onClick={closeDropdown} to="/about-cerebelly">Our Story</LinkWithReferrer>
                <LinkWithReferrer onClick={closeDropdown} to="/science">Science</LinkWithReferrer>
                <LinkWithReferrer onClick={closeDropdown} to="/heavy-metals">Safety & Standards</LinkWithReferrer>
                <a target="_blank" rel="noreferrer" href="https://www.terracycle.com/en-US/brigades/cerebelly">Recycling Program</a>
                <a target="_blank" rel="noreferrer" href="https://help.cerebelly.com">FAQ</a>
            </div>
            <div className="right">
                <div className="posts-container">
                    <ContentLoader loading={loading}>
                        <DropdownLearnBlogposts posts={posts} />
                    </ContentLoader>
                </div>
            </div>
        </div>
    );
}
