import { referrerObject } from '@utils/history';

import React from 'react';
import * as H from 'history';
import { Link, LinkProps } from 'react-router-dom';

export function LinkWithReferrer<S = H.LocationState>(
    props: React.PropsWithoutRef<LinkProps<S>> & React.RefAttributes<HTMLAnchorElement>,
): ReturnType<Link<S>>;

export function LinkWithReferrer<S = H.LocationState>(
    props: React.PropsWithoutRef<LinkProps<S>> & React.RefAttributes<HTMLAnchorElement>
) {
    const refObj = referrerObject();
    // TODO: perhaps find the proper type of toObj one day...
    // I'm a bit tired of the types game...
    let toObj: any = typeof props.to === 'string' ? { pathname: props.to } : Object.assign({}, props.to);
    toObj.state = Object.assign({}, toObj.state, refObj);
    return (
        <Link {...props} to={toObj} />
    );
}
