import { LinkWithReferrer } from '@components/common/helpers/link';
import { eventAction } from '@v2/components/common/presentational/clickables/default';
import { css } from '@emotion/css'
import colors from '@styles/colors';
import { tabletLandscapeUp, desktopUp, mobileOnly } from "@v2/styles/breakpoints";
import iconLoginMobile from '@img/homepage2/login-mobile-icon.svg';
import iconSubscription from '@img/dashboard/subscription.svg';
import iconOrder from '@img/dashboard/order.svg';
import iconShipping from '@img/dashboard/shipping.svg';
import iconSettings from '@img/dashboard/settings.svg';
import iconLogout from '@img/dashboard/logout.svg';
import iconReferral from '@img/dashboard/referral.svg';
import iconRewards from '@img/dashboard/rewards.svg';
import iconJourney from '@img/for-you/journey.svg';
import { useLogout } from "@components/common/presentational/modal/common";

const style = css({
    display: 'flex !important',
    position: 'relative',
    letterSpacing: '0.01em',
    WebkitBoxAlign: 'center',
    MsFlexAlign: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    span: {
        display: 'none',
        [tabletLandscapeUp]: {
            display: 'flex',
        }
    },
    '> img': {
        display: 'block',
        [tabletLandscapeUp]: {
            display: 'none',
        }
    },
    '.e-nav-link-submenu': {
        display: 'none'
    },
    '.e-nav-link-submenu.active': {
        position: 'absolute',
        left: 'auto',
        right: -20,
        top: 50,
        backgroundColor: colors.WHITE,
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        justifyContent: 'flex-start',
        textAlign: 'left',
        borderBottomRightRadius: 5,
        borderBottomLeftRadius: 5,
        boxShadow: '0px 7px 7px rgba(0,0,0,0.10)',

        [desktopUp]: {
            top: 60,
            right: 'auto',
            left: -54,
        },
        [tabletLandscapeUp]: {
            top: 60,
        },
        a: {
            textDecoration: 'none',
            whiteSpace: 'pre',
            marginBottom: 20,
            scrollMarginBottom: 20,
            fontSize: 15,
            display: 'flex',
            alignItems: 'center',
            color: colors.NAVY,

            [mobileOnly]: {
                fontSize: 16,
            },
            '&:hover': {
                color: colors.NAVY,
            },
            '&:last-child': {
                marginBottom: 0,
            },
            img: {
                marginRight: 10,
            }
        },
    },
})

export default function MyAccountMenu({ onClick, onHover, isLogged, dropdown, iconButton }:
    { onClick?: Function, onHover?: Function, isLogged: boolean, dropdown: string, iconButton?: boolean }) {
    const DROPDOWN_USER = 'user';
    const logout = useLogout();

    return (
        isLogged &&
        <span className={`e-nav-link e-my-account ${style}`}
            role="button"
            tabIndex={0}
            onKeyDown={e => onClick ? eventAction(e, onClick) : {}}
            onClick={e => onClick ? eventAction(e, onClick) : {}}
            onMouseEnter={() => (onHover && dropdown !== DROPDOWN_USER) ? onHover() : {}}
            onMouseLeave={() => (onHover && dropdown === DROPDOWN_USER) ? onHover() : {}}>
            <span style={iconButton ? { display: 'none' } : {}}>
                My account
            </span>
            <img
                style={iconButton ? { display: 'block' } : {}}
                src={iconLoginMobile}
                alt={'Login'} />
            <div className={`e-nav-link-submenu ${dropdown === DROPDOWN_USER ? 'active' : ''}`}>
                <LinkWithReferrer to="/for-you">
                    <img src={iconJourney} alt={'For You'} /> For You
                </LinkWithReferrer>
                <LinkWithReferrer to="/order-history">
                    <img src={iconOrder} alt={'Orders'} /> Order History
                </LinkWithReferrer>
                <LinkWithReferrer to="/subscription">
                    <img src={iconSubscription} alt={'My Subscription'} /> My Subscription
                </LinkWithReferrer>
                <LinkWithReferrer to="/rewards">
                    <img src={iconRewards} alt={'Smart Rewards'} /> Smart Rewards
                </LinkWithReferrer>
                <LinkWithReferrer to="/referral">
                    <img src={iconReferral} alt={'Refer a Friend'} /> Refer a Friend
                </LinkWithReferrer>
                <LinkWithReferrer to="/shipping-billing">
                    <img src={iconShipping} alt={'Shipping and billing'} /> Shipping &amp; Billing
                </LinkWithReferrer>
                <LinkWithReferrer to="/account-settings">
                    <img src={iconSettings} alt={'Settings'} /> Account Settings
                </LinkWithReferrer>
                <LinkWithReferrer to="/logout" onClick={logout}>
                    <img src={iconLogout} alt={'Log out'} /> Log out
                </LinkWithReferrer>
            </div>
        </span>
    )
}
