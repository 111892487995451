import BaseEntity from "./base.entity";

export interface IChildRecord {
    id: number;
    userId: number;
    subscriptionId: number;
    stageId: number;
    name: string;
    birthday: string;
    gender: string;
    milestones: number[];
    properties: any;
    recommendations: boolean;

    age: number;
    isVegan: boolean;
}

export default class ChildEntity extends BaseEntity implements IChildRecord {
    id: number;
    userId: number;
    subscriptionId: number;
    stageId: number;
    name: string;
    birthday: string;
    gender: string;
    milestones: number[];
    properties: any;
    recommendations: boolean;

    age: number;
    isVegan: boolean;

    static fromJson(json: { [key: string]: any }): IChildRecord {
        return BaseEntity.fromJson(new this(), json) as ChildEntity;
    }
}